@charset "UTF-8";
/*@if $RTL { 
  $align-def: right;
  $align-opp: left;
  $dir-def: rtl;      //$default-direction
  $dir-opp: ltr;      //$opposite-direction
}*/
/*$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  xxlarge: 1440px,
  xxxlarge: 1600px
);*/
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #D93E3E;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffd400;
  --bs-green: #66B849;
  --bs-teal: #20c997;
  --bs-cyan: #63f0eb;
  --bs-black: #000;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #66B849;
  --bs-info: #63f0eb;
  --bs-warning: #ffd400;
  --bs-danger: #D93E3E;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 102, 184, 73;
  --bs-info-rgb: 99, 240, 235;
  --bs-warning-rgb: 255, 212, 0;
  --bs-danger-rgb: 217, 62, 62;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #294a1d;
  --bs-info-text-emphasis: #28605e;
  --bs-warning-text-emphasis: #665500;
  --bs-danger-text-emphasis: #571919;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #e0f1db;
  --bs-info-bg-subtle: #e0fcfb;
  --bs-warning-bg-subtle: #fff6cc;
  --bs-danger-bg-subtle: #f7d8d8;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #c2e3b6;
  --bs-info-border-subtle: #c1f9f7;
  --bs-warning-border-subtle: #ffee99;
  --bs-danger-border-subtle: #f0b2b2;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto, Rubik, Tajawal, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #555555;
  --bs-body-color-rgb: 85, 85, 85;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(85, 85, 85, 0.75);
  --bs-secondary-color-rgb: 85, 85, 85;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(85, 85, 85, 0.5);
  --bs-tertiary-color-rgb: 85, 85, 85;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: none;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-link-hover-decoration: none;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff6cc;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 1.8rem 2rem 0 rgba(0, 0, 0, 0.25);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #66B849;
  --bs-form-valid-border-color: #66B849;
  --bs-form-invalid-color: #D93E3E;
  --bs-form-invalid-border-color: #D93E3E;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #a3d492;
  --bs-info-text-emphasis: #a1f6f3;
  --bs-warning-text-emphasis: #ffe566;
  --bs-danger-text-emphasis: #e88b8b;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #14250f;
  --bs-info-bg-subtle: #14302f;
  --bs-warning-bg-subtle: #332a00;
  --bs-danger-bg-subtle: #2b0c0c;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #3d6e2c;
  --bs-info-border-subtle: #3b908d;
  --bs-warning-border-subtle: #997f00;
  --bs-danger-border-subtle: #822525;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #a3d492;
  --bs-form-valid-border-color: #a3d492;
  --bs-form-invalid-color: #e88b8b;
  --bs-form-invalid-border-color: #e88b8b;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1px) {
  .container-sm, .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 1280px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1400px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 1px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 1024px;
  --bs-breakpoint-xl: 1280px;
  --bs-breakpoint-xxl: 1400px;
  --bs-breakpoint-xxxl: 1600px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 1px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1600px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #e0f1db;
  --bs-table-border-color: #cad9c5;
  --bs-table-striped-bg: #d5e5d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cad9c5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfdfcb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #e0fcfb;
  --bs-table-border-color: #cae3e2;
  --bs-table-striped-bg: #d5efee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cae3e2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfe9e8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff6cc;
  --bs-table-border-color: #e6ddb8;
  --bs-table-striped-bg: #f2eac2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6ddb8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece4bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f7d8d8;
  --bs-table-border-color: #dec2c2;
  --bs-table-striped-bg: #ebcdcd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dec2c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4c8c8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 0.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1279.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2366B849' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2366B849' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23D93E3E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D93E3E' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23D93E3E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D93E3E' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #66B849;
  --bs-btn-border-color: #66B849;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7dc364;
  --bs-btn-hover-border-color: #75bf5b;
  --bs-btn-focus-shadow-rgb: 87, 156, 62;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #85c66d;
  --bs-btn-active-border-color: #75bf5b;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #66B849;
  --bs-btn-disabled-border-color: #66B849;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #63f0eb;
  --bs-btn-border-color: #63f0eb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7af2ee;
  --bs-btn-hover-border-color: #73f2ed;
  --bs-btn-focus-shadow-rgb: 84, 204, 200;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #82f3ef;
  --bs-btn-active-border-color: #73f2ed;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #63f0eb;
  --bs-btn-disabled-border-color: #63f0eb;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffd400;
  --bs-btn-border-color: #ffd400;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffda26;
  --bs-btn-hover-border-color: #ffd81a;
  --bs-btn-focus-shadow-rgb: 217, 180, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffdd33;
  --bs-btn-active-border-color: #ffd81a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffd400;
  --bs-btn-disabled-border-color: #ffd400;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #D93E3E;
  --bs-btn-border-color: #D93E3E;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #df5b5b;
  --bs-btn-hover-border-color: #dd5151;
  --bs-btn-focus-shadow-rgb: 184, 53, 53;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e16565;
  --bs-btn-active-border-color: #dd5151;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D93E3E;
  --bs-btn-disabled-border-color: #D93E3E;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #66B849;
  --bs-btn-border-color: #66B849;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #66B849;
  --bs-btn-hover-border-color: #66B849;
  --bs-btn-focus-shadow-rgb: 102, 184, 73;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #66B849;
  --bs-btn-active-border-color: #66B849;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #66B849;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #66B849;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #63f0eb;
  --bs-btn-border-color: #63f0eb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #63f0eb;
  --bs-btn-hover-border-color: #63f0eb;
  --bs-btn-focus-shadow-rgb: 99, 240, 235;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #63f0eb;
  --bs-btn-active-border-color: #63f0eb;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #63f0eb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #63f0eb;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffd400;
  --bs-btn-border-color: #ffd400;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffd400;
  --bs-btn-hover-border-color: #ffd400;
  --bs-btn-focus-shadow-rgb: 255, 212, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffd400;
  --bs-btn-active-border-color: #ffd400;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #ffd400;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffd400;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #D93E3E;
  --bs-btn-border-color: #D93E3E;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D93E3E;
  --bs-btn-hover-border-color: #D93E3E;
  --bs-btn-focus-shadow-rgb: 217, 62, 62;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D93E3E;
  --bs-btn-active-border-color: #D93E3E;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #D93E3E;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D93E3E;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.75rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 1.8rem 2rem 0 rgba(0, 0, 0, 0.25);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 1px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #ffffff;
  --bs-dropdown-bg: #0c2780;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: 0 1.8rem 2rem 0 rgba(0, 0, 0, 0.25);
  --bs-dropdown-link-color: #ffffff;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: #11afff;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #63f0eb;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.5rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(0 * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(0 * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: #0c2780;
  --bs-navbar-hover-color: #11afff;
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: #11afff;
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #11afff;
  --bs-navbar-brand-hover-color: #11afff;
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2885, 85, 85, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.5rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 1px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 1px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23555555'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 1px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1280px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 1px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 1.8rem 2rem 0 rgba(0, 0, 0, 0.25);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1280px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 0.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1023.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1279.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header,
  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: Roboto, Rubik, Tajawal, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 1.8rem 2rem 0 rgba(0, 0, 0, 0.25);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: Roboto, Rubik, Tajawal, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.offcanvas, .offcanvas-xxxl, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: #0c2780;
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 0.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 0.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 0.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 1px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1023.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1023.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1279.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1279.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1280px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-xxxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxxl {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxxl.showing, .offcanvas-xxxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxxl.showing, .offcanvas-xxxl.hiding, .offcanvas-xxxl.show {
    visibility: visible;
  }
}
@media (min-width: 1600px) {
  .offcanvas-xxxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(102, 184, 73, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(99, 240, 235, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 212, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(217, 62, 62, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(133, 198, 109, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(133, 198, 109, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(130, 243, 239, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(130, 243, 239, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 221, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 221, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(225, 101, 101, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(225, 101, 101, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 1px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1280px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 1.8rem 2rem 0 rgba(0, 0, 0, 0.25) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 1px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxxl-start {
    float: left !important;
  }
  .float-xxxl-end {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
  .object-fit-xxxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxxl-none {
    object-fit: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-grid {
    display: grid !important;
  }
  .d-xxxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxxl-none {
    display: none !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
  .order-xxxl-first {
    order: -1 !important;
  }
  .order-xxxl-0 {
    order: 0 !important;
  }
  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }
  .order-xxxl-last {
    order: 6 !important;
  }
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxxl-auto {
    margin-top: auto !important;
  }
  .me-xxxl-0 {
    margin-right: 0 !important;
  }
  .me-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxxl-0 {
    gap: 0 !important;
  }
  .gap-xxxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxxl-3 {
    gap: 1rem !important;
  }
  .gap-xxxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxxl-start {
    text-align: left !important;
  }
  .text-xxxl-end {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.modal {
  --bs-body-bg:transparent;
  --bs-modal-box-shadow:0;
  --bs-modal-border-width:0;
  --bs-modal-header-border-width:0;
  --bs-modal-padding:1rem 2rem 2rem;
  --bs-modal-header-padding:2rem 2rem 0;
}
@media (min-width: 768px) {
  .modal {
    --bs-modal-padding:1rem 2rem 4rem;
    --bs-modal-header-padding:2rem;
  }
}
.modal-backdrop {
  background-color: rgba(12, 39, 128, 0.8);
}
.modal .popup-content:before {
  background-image: url("../Images/newhome/svg/modal-bg-frame-mobile-left.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  z-index: -1;
  content: "";
}
@media (min-width: 37.5rem) {
  .modal .popup-content:before {
    background-image: url("../Images/newhome/svg/modal-bg-frame-left.svg");
  }
}
.modal .popup-body {
  min-height: 24rem;
}
.modal .popup-body svg {
  margin: 0 auto;
  display: block;
}
.modal .plus-bg.first {
  display: none;
}
@media (min-width: 1024px) {
  .modal .plus-bg.first {
    display: block;
    left: 2rem;
    top: 2rem;
    width: 4.5rem;
    height: 4.5rem;
  }
}
.modal .plus-bg.second {
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .modal .plus-bg.second {
    position: absolute;
    top: auto;
    right: 2rem;
    bottom: 4rem;
    margin-bottom: 0;
  }
}
.modal .image-wrapper {
  text-align: center;
}
.modal .main-column {
  max-height: 15rem;
  overflow-y: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .modal .main-column {
    max-height: none;
    overflow-y: initial;
  }
}
.modal .main-column p {
  font-size: 1.125rem;
  font-weight: 400;
}
.modal .main-column p:only-child {
  margin-bottom: 0;
}
.modal .main-column p a {
  color: #0c2780;
}
.modal .cta-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
}
.modal .cta-wrap .button {
  font-size: 1.125rem;
  margin: 0.5rem;
  transition: none;
}
@media (min-width: 768px) {
  .modal .cta-wrap .button {
    font-size: 1.25rem;
  }
}
.modal.na-country-modal img {
  display: block;
  margin: 0 auto;
}
.modal.na-country-modal .btn.na-link {
  text-decoration: underline;
  flex: 0 0 100%;
}
.modal.na-country-modal .btn.na-link:hover {
  text-decoration: none;
}
.modal.na-country-modal .cta-wrap {
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

.sticky-cookie-wrapper {
  background: #ffffff;
  box-shadow: 0 -0.5rem 2rem 0 rgba(12, 39, 128, 0.1);
  max-height: 20rem;
  position: relative;
  overflow-y: hidden;
  transition: max-height 0.75s ease-out;
  z-index: 2;
}
.sticky-cookie-wrapper.cookie-out {
  max-height: 0;
}
.sticky-cookie-wrapper .image-wrapper {
  text-align: center;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .image-wrapper {
    margin-bottom: 0;
    width: 3rem;
    flex: 0 1 auto;
  }
}
.sticky-cookie-wrapper .image-wrapper img {
  display: inline-block;
  margin: 0 auto;
  max-width: 3rem;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .cookie-banner-wrapper, .sticky-cookie-wrapper .cookie-banner-content {
    display: flex;
  }
}
.sticky-cookie-wrapper .cookie-banner-wrapper {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .cookie-banner-content {
    flex: 1 1 auto;
    align-items: center;
  }
}
.sticky-cookie-wrapper p {
  color: #3e3e3e;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1.5rem;
  font-size: 0.8125rem;
  line-height: 1.3;
  font-weight: 400;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper p {
    font-size: 0.9375rem;
  }
}
.sticky-cookie-wrapper p a {
  color: #ffffff;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper p a {
    white-space: nowrap;
  }
}
.sticky-cookie-wrapper p a:hover, .sticky-cookie-wrapper p a:focus {
  text-decoration: none;
}
.sticky-cookie-wrapper .actions-wrapper {
  display: flex;
  justify-content: center;
  flex: 0 1 auto;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .actions-wrapper {
    margin-left: auto;
  }
}
.sticky-cookie-wrapper .button {
  font-size: 1rem;
  transition: none;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .button {
    font-size: 1.125rem;
    margin-top: 0;
    height: 3rem;
    width: auto;
    min-width: 10rem;
  }
}
.sticky-cookie-wrapper .button.accept-cookies {
  margin-left: 1rem;
  border: 1px transparent solid;
}
.sticky-cookie-wrapper .button.accept-cookies:hover, .sticky-cookie-wrapper .button.accept-cookies:focus {
  background: #ffffff;
  color: #2e86fe;
  border-color: #2e86fe;
  border-width: 2px;
  cursor: pointer;
}
.sticky-cookie-wrapper .button.accept-cookies:only-child {
  margin-left: auto;
  margin-right: auto;
}
.sticky-cookie-wrapper.dark {
  background-color: #3e3e3e;
}
.sticky-cookie-wrapper.dark p {
  color: #ffffff;
}

@font-face {
  font-family: "icomain";
  src: url("../fonts/icomain.woff2?upyjei") format("woff2"), url("../fonts/icomain.ttf?upyjei") format("truetype"), url("../fonts/icomain.woff?upyjei") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-]:before, [class*=" icon-"]:before, [class^=icon-]:after, [class*=" icon-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomain" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chat-bubble:before {
  content: "\e96e";
}

.icon-mail-solid:before {
  content: "\e974";
}

.icon-whatsapp-solid:before {
  content: "\e975";
}

.icon-social-viber:before {
  content: "\e976";
}

.icon-social-line-full:before {
  content: "\e977";
}

.icon-social-imessage:before {
  content: "\e978";
}

.icon-arrow-horizontal:before {
  content: "\e96f";
}

.icon-watchlist:before {
  content: "\e970";
}

.icon-password:before {
  content: "\e971";
}

.icon-power:before {
  content: "\e972";
}

.icon-wifi:before {
  content: "\e973";
}

.icon-bubble-person:before {
  content: "\e96c";
}

.icon-telegram:before {
  content: "\e96a";
}

.icon-soicial-telegram:before {
  content: "\e96a";
}

.icon-flame:before {
  content: "\e969";
}

.icon-risers-fallers:before {
  content: "\e968";
}

.icon-equalizer:before {
  content: "\e959";
}

.icon-twitter:before {
  content: "\e967";
}

.icon-social-twitter:before {
  content: "\e967";
}

.icon-risk-management:before {
  content: "\e952";
}

.icon-initiation:before {
  content: "\e964";
}

.icon-trading-methods:before {
  content: "\e965";
}

.icon-limit-risk:before {
  content: "\e966";
}

.icon-deposit-adjustment:before {
  content: "\e951";
}

.icon-error-fill:before {
  content: "\e94f";
}

.icon-error:before {
  content: "\e950";
}

.icon-plus-fill:before {
  content: "\e95d";
}

.icon-arrow-next:before {
  content: "\e95e";
}

.icon-vision:before {
  content: "\e960";
}

.icon-step:before {
  content: "\e961";
}

.icon-star-circle:before {
  content: "\e962";
}

.icon-star:before {
  content: "\e963";
}

.icon-social-line:before {
  content: "\e95a";
}

.icon-social-instagram:before {
  content: "\e95b";
}

.icon-facebook:before {
  content: "\e95c";
}

.icon-social-facebook:before {
  content: "\e95c";
}

.icon-search1:before {
  content: "\e946";
}

.icon-plus:before {
  content: "\e947";
}

.icon-menu:before {
  content: "\e948";
}

.icon-mail:before {
  content: "\e949";
}

.icon-lock:before {
  content: "\e94a";
}

.icon-info-circle:before {
  content: "\e94b";
}

.icon-help:before {
  content: "\e94c";
}

.icon-check-circle:before {
  content: "\e94d";
}

.icon-faq-circle:before {
  content: "\e94e";
}

.icon-news:before {
  content: "\e953";
}

.icon-prepare:before {
  content: "\e954";
}

.icon-spread:before {
  content: "\e955";
}

.icon-knowledge:before {
  content: "\e956";
}

.icon-history:before {
  content: "\e957";
}

.icon-deposit-withdraw:before {
  content: "\e958";
}

.icon-deposit:before {
  content: "\e945";
}

.icon-withdraw:before {
  content: "\e944";
}

.icon-additional:before {
  content: "\e943";
}

.icon-bonus:before {
  content: "\e942";
}

.icon-download-pdf:before {
  content: "\e93b";
}

.icon-futures-interestrates:before {
  content: "\e91a";
}

.icon-futures-forex:before {
  content: "\e91b";
}

.icon-futures-crypto1:before {
  content: "\e93c";
}

.icon-options:before {
  content: "\e913";
}

.icon-stocks:before {
  content: "\e914";
}

.icon-commodities:before {
  content: "\e916";
}

.icon-forex:before {
  content: "\e917";
}

.icon-indices:before {
  content: "\e918";
}

.icon-etfs:before {
  content: "\e919";
}

.icon-webApp:before {
  content: "\e90d";
}

.icon-wallet-coin:before {
  content: "\e912";
}

.icon-cannabis:before {
  content: "\e90b";
}

.icon-Singapore:before {
  content: "\e908";
}

.icon-Devices:before {
  content: "\e909";
}

.icon-zero-percent:before {
  content: "\e906";
}

.icon-IslamicSquareFull:before {
  content: "\e901";
}

.icon-IslamicSquareSmall:before {
  content: "\e902";
}

.icon-IslamicSquareBig:before {
  content: "\e903";
}

.icon-IslamicMoon:before {
  content: "\e904";
}

.icon-bell-o:before {
  content: "\e905";
}

.icon-bitcoin:before {
  content: "\e900";
}

.icon-futures-interestrates1:before {
  content: "\e923";
}

.icon-futures-forex1:before {
  content: "\e921";
}

.icon-futures-metals:before {
  content: "\e91c";
}

.icon-futures-agriculture:before {
  content: "\e91d";
}

.icon-futures-crypto:before {
  content: "\e91e";
}

.icon-futures-energy:before {
  content: "\e91f";
}

.icon-futures-equityindex:before {
  content: "\e920";
}

.icon-futures-equityindices:before {
  content: "\e922";
}

.icon-futures-articles:before {
  content: "\e92d";
}

.icon-futures-video:before {
  content: "\e92c";
}

.icon-futures-strategies:before {
  content: "\e92f";
}

.icon-futures-plus:before {
  content: "\e924";
}

.icon-futures-benefits:before {
  content: "\e92b";
}

.icon-icon-IBs:before {
  content: "\e92e";
}

.icon-options1:before {
  content: "\e915";
}

.icon-stocks1:before {
  content: "\e925";
}

.icon-crypto:before {
  content: "\e926";
}

.icon-commodities1:before {
  content: "\e927";
}

.icon-forex1:before {
  content: "\e928";
}

.icon-indices1:before {
  content: "\e929";
}

.icon-etfs1:before {
  content: "\e92a";
}

.icon-webApp1:before {
  content: "\e93a";
}

.icon-lamp7-glow:before {
  content: "\e939";
}

.icon-wallet-coin-color .path1:before {
  content: "\e938";
  color: rgb(0, 77, 153);
}

.icon-wallet-coin-color .path2:before {
  content: "\e93d";
  margin-left: -0.59765625em;
  color: rgb(255, 179, 25);
}

.icon-wallet-coin-color .path3:before {
  content: "\e93e";
  margin-left: -0.59765625em;
  color: rgb(255, 179, 25);
}

.icon-wallet-coin-color .path4:before {
  content: "\e93f";
  margin-left: -0.59765625em;
  color: rgb(255, 179, 25);
}

.icon-wallet-coin-color .path5:before {
  content: "\e940";
  margin-left: -0.59765625em;
  color: rgb(255, 179, 25);
}

.icon-wallet-coin-color .path6:before {
  content: "\e941";
  margin-left: -0.59765625em;
  color: rgb(255, 179, 25);
}

.icon-wallet-coin1:before {
  content: "\e937";
}

.icon-cannabis1:before {
  content: "\e936";
}

.icon-Singapore1:before {
  content: "\e935";
}

.icon-Devices1:before {
  content: "\e933";
}

.icon-zero-percent1:before {
  content: "\e932";
}

.icon-IslamicSquareFull1:before {
  content: "\e931";
}

.icon-IslamicSquareSmall1:before {
  content: "\e930";
}

.icon-IslamicSquareBig1:before {
  content: "\e90c";
}

.icon-IslamicMoon1:before {
  content: "\e907";
}

.icon-youtube:before {
  content: "\e96b";
}

.icon-social-youtube:before {
  content: "\e96b";
}

.icon-check:before {
  content: "\e96d";
}

.icon-chevron-left:before {
  content: "\e90e";
}

.icon-chevron-down:before {
  content: "\e90f";
}

.icon-chevron-up:before {
  content: "\e910";
}

.icon-chevron-right:before {
  content: "\e911";
}

.icon-menu1:before {
  content: "\e000";
}

.icon-arrow-back:before {
  content: "\e95f";
}

.icon-moon-fill:before {
  content: "\e90a";
}

.icon-night:before {
  content: "\e90a";
}

.icon-search:before {
  content: "\e986";
}

.icon-sun:before {
  content: "\e9d4";
}

.icon-loop2:before {
  content: "\ea2e";
}

.icon-tab:before {
  content: "\ea45";
}

.icon-facebook2:before {
  content: "\ea91";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-whatsapp:before {
  content: "\ea93";
}

.icon-appleinc:before {
  content: "\eabe";
}

.icon-android:before {
  content: "\eac0";
}

.icon-windows8:before {
  content: "\eac2";
}

.icon-linkedin2:before {
  content: "\eaca";
}

.icon-social-linkedin:before {
  content: "\eaca";
}

.icon-chrome:before {
  content: "\eadb";
}

.icon-firefox:before {
  content: "\eadd";
}

.icon-edge:before {
  content: "\eae0";
}

.icon-safari:before {
  content: "\eadf";
}

.icon-file-pdf:before {
  content: "\eae1";
}

.icon-droplet:before {
  content: "\e934";
}

.icon-bullhorn:before {
  content: "\e979";
}

.icon-book:before {
  content: "\e990";
}

.icon-library2:before {
  content: "\e999";
}

.icon-graduation:before {
  content: "\e99a";
}

.icon-file-check:before {
  content: "\e9a9";
}

.icon-file-text22:before {
  content: "\e9ad";
}

.icon-file-presentation:before {
  content: "\e9bf";
}

.icon-file-stats:before {
  content: "\e9c1";
}

.icon-certificate:before {
  content: "\e9eb";
}

.icon-coins:before {
  content: "\ea0a";
}

.icon-coin-dollar:before {
  content: "\ea0b";
}

.icon-coin-euro:before {
  content: "\ea0c";
}

.icon-coin-pound:before {
  content: "\ea0d";
}

.icon-wallet:before {
  content: "\ea10";
}

.icon-credit-card:before {
  content: "\ea15";
}

.icon-phone2:before {
  content: "\ea1d";
}

.icon-phone3:before {
  content: "\ea26";
}

.icon-envelop3:before {
  content: "\ea32";
}

.icon-envelop5:before {
  content: "\ea34";
}

.icon-location:before {
  content: "\ea38";
}

.icon-location3:before {
  content: "\ea41";
}

.icon-clock:before {
  content: "\ea4d";
}

.icon-clock3:before {
  content: "\ea4f";
}

.icon-bell2:before {
  content: "\ea58";
}

.icon-calendar4:before {
  content: "\ea62";
}

.icon-display4:before {
  content: "\ea76";
}

.icon-mobile2:before {
  content: "\ea78";
}

.icon-bubble8:before {
  content: "\ead8";
}

.icon-bubble-dots3:before {
  content: "\ead9";
}

.icon-bubble-lines3:before {
  content: "\eada";
}

.icon-users:before {
  content: "\eaf8";
}

.icon-collaboration:before {
  content: "\eb10";
}

.icon-search2:before {
  content: "\eb33";
}

.icon-search3:before {
  content: "\eb36";
}

.icon-enlarge7:before {
  content: "\eb48";
}

.icon-lock2:before {
  content: "\eb51";
}

.icon-safe:before {
  content: "\eb57";
}

.icon-cog2:before {
  content: "\eb61";
}

.icon-cog3:before {
  content: "\eb62";
}

.icon-stats-dots:before {
  content: "\eb87";
}

.icon-stats-bars3:before {
  content: "\eb8b";
}

.icon-chart:before {
  content: "\eb8f";
}

.icon-stats-growth:before {
  content: "\eb90";
}

.icon-stats-growth2:before {
  content: "\eb92";
}

.icon-medal:before {
  content: "\eba0";
}

.icon-gift:before {
  content: "\ebac";
}

.icon-gift2:before {
  content: "\ebad";
}

.icon-lamp7:before {
  content: "\ebf9";
}

.icon-lamp8:before {
  content: "\ebfa";
}

.icon-briefcase:before {
  content: "\ec02";
}

.icon-shield:before {
  content: "\ec31";
}

.icon-shield-check:before {
  content: "\ec32";
}

.icon-clipboard2:before {
  content: "\ec51";
}

.icon-menu7:before {
  content: "\ec71";
}

.icon-earth2:before {
  content: "\ec96";
}

.icon-earth22:before {
  content: "\ec97";
}

.icon-link5:before {
  content: "\eca1";
}

.icon-star-empty:before {
  content: "\ece0";
}

.icon-star-full:before {
  content: "\ece2";
}

.icon-star-empty3:before {
  content: "\ece3";
}

.icon-star-full2:before {
  content: "\ece5";
}

.icon-thumbs-up2:before {
  content: "\ecf5";
}

.icon-pointer:before {
  content: "\ed30";
}

.icon-touch:before {
  content: "\ed35";
}

.icon-warning:before {
  content: "\ed4f";
}

.icon-warning2:before {
  content: "\ed50";
}

.icon-plus3:before {
  content: "\ed5d";
}

.icon-minus3:before {
  content: "\ed5e";
}

.icon-info:before {
  content: "\ed63";
}

.icon-cross2:before {
  content: "\ed6d";
}

.icon-cross3:before {
  content: "\ed6e";
}

.icon-checkmark:before {
  content: "\ed6f";
}

.icon-checkmark4:before {
  content: "\ed72";
}

.icon-enter3:before {
  content: "\ed7a";
}

.icon-play3:before {
  content: "\ed7e";
}

.icon-arrow-up3:before {
  content: "\edb9";
}

.icon-arrow-up4:before {
  content: "\edba";
}

.icon-arrow-right3:before {
  content: "\edbd";
}

.icon-arrow-right4:before {
  content: "\edbe";
}

.icon-arrow-down3:before {
  content: "\edc1";
}

.icon-arrow-down4:before {
  content: "\edc2";
}

.icon-arrow-left3:before {
  content: "\edc5";
}

.icon-arrow-left4:before {
  content: "\edc6";
}

.icon-circle-right2:before {
  content: "\ede8";
}

.icon-circle-left2:before {
  content: "\edea";
}

.icon-circle-right3:before {
  content: "\edee";
}

.icon-circle-left3:before {
  content: "\edf2";
}

.icon-filter3:before {
  content: "\ee8b";
}

.icon-filter4:before {
  content: "\ee8c";
}

.icon-page-break2:before {
  content: "\eea5";
}

*:focus-visible {
  box-shadow: 0 0 2px 2px #2e86fe !important;
  outline: none !important;
}

body {
  color: #555555;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  position: relative;
  line-height: 1.3;
}
@media (hover: hover) and (pointer: fine) {
  body::-webkit-scrollbar {
    background-color: transparent;
  }
  body::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 0.25rem 0;
    border-radius: 0 0 1.5rem 1.5rem;
  }
  body::-webkit-scrollbar-thumb {
    background: #0c2780;
    border: 2px solid #ffffff;
    border-radius: 1.5rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.full-height {
  height: 100vh;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive:before {
  content: "";
  padding-top: 56.25%;
  display: block;
}
.embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

main {
  overflow: hidden;
}

@supports not (will-change: auto) {
  .visually-hidden {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .visually-hidden:not(caption) {
    position: absolute !important;
  }
}

.general-section {
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .general-section {
    margin-bottom: 10rem;
  }
}
.general-padding {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 768px) {
  .general-padding {
    padding-top: 8.25rem;
    padding-bottom: 8.25rem;
  }
}
.general-padding-page {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .general-padding-page {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .container-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

p, .free-content {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  p, .free-content {
    font-size: 1.125rem;
  }
}
p + .button, .free-content + .button {
  margin-top: 0;
}

.non-linkable {
  pointer-events: none;
}

.title-section {
  color: #0c2780;
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .title-section {
    font-size: 2rem;
  }
}
.title-section.white {
  color: #ffffff;
}
.title-section.big {
  font-size: 2.125rem;
  line-height: 1.1;
  font-size: 3.25rem;
  line-height: 1;
}
.title-section.big strong {
  font-size: 3.375rem;
  color: #2e86fe;
}
.title-section.big strong {
  font-size: 4.75rem;
}
.title-section strong, .title-section span {
  display: block;
  margin-top: 0.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #11afff;
}
@media (min-width: 768px) {
  .title-section strong, .title-section span {
    font-size: 3rem;
  }
}
.title-section strong:first-letter, .title-section span:first-letter {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .page-data {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .page-data.side-border {
    position: relative;
    margin-left: 2rem;
    padding-left: 2.75rem;
  }
  .page-data.side-border:before {
    width: 0.125rem;
    height: 100%;
    background: linear-gradient(to bottom, rgba(12, 39, 128, 0) 0%, rgb(12, 39, 128) 10%, rgb(12, 39, 128) 90%, rgba(12, 39, 128, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
}
.page-title {
  font-size: 1.5rem;
  font-weight: 400;
  color: #0c2780;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .page-title {
    font-size: 2.5rem;
    font-weight: 300;
  }
}

.plus-bg {
  position: absolute;
  top: 0;
  z-index: -1;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0;
  transform: rotate(-12deg);
  backface-visibility: hidden;
}
.plus-bg:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  background: url("../images/futures/svg/plus-blue.svg") no-repeat;
  background-size: cover;
}
.plus-bg-xlarge {
  width: 100%;
  height: 100%;
  transform: rotate(-12deg) scaleX(1);
}
.plus-bg.white:before {
  background-image: url("../images/futures/svg/plus-white.svg");
}
.plus-bg.white-thick:before {
  background-image: url("../images/futures/svg/plus-ln-white-thick.svg");
}
.plus-bg.transparent:before {
  background-image: url("../images/futures/svg/plus-gr-blue-transparent.svg");
}
.plus-bg.blue-gr:before {
  background-image: url("../images/futures/svg/plus-blue-gr.svg");
}
.plus-bg.blue-turquoise-gr:before {
  background-image: url("../images/futures/svg/plus-blue-turquoise-gr.svg");
}
.plus-bg.turquoise-transparent-gr:before {
  background-image: url("../images/futures/svg/plus-turquoise-transparent-gr.svg");
}
.plus-bg.blue-white-gr:before {
  background-image: url("../images/futures/svg/plus-blue-white-gr.svg");
}
.plus-bg.turquoise-white-gr:before {
  background-image: url("../images/futures/svg/plus-turquoise-white-gr.svg");
}
.plus-bg.blue-fill:before {
  background-image: url("../images/futures/svg/plus-blue-fill-1.svg");
}
.plus-bg.white-fill:before {
  background-image: url("../images/futures/svg/plus-white-fill.svg");
}
.plus-bg.small-only {
  display: block;
}
@media (min-width: 768px) {
  .plus-bg.small-only {
    display: none;
  }
}
@media (min-width: 768px) {
  .plus-bg.not-tablet {
    display: none;
  }
}
@media (min-width: 1400px) {
  .plus-bg.not-tablet {
    display: block;
  }
}

.blue-card-parent {
  position: relative;
  color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .blue-card-parent {
    text-align: center;
  }
}
@media (min-width: 1280px) {
  .blue-card-parent {
    padding-top: 11.25rem;
  }
}
.blue-card-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 80%;
  min-height: 26.25rem;
  width: 100%;
  max-width: 100%;
  backface-visibility: hidden;
  transform: scaleX(1);
}
.blue-card-bg.full-height {
  height: 100%;
}
@media (max-width: 1023.98px) {
  .blue-card-bg {
    transform: skew(0deg, 4deg);
  }
}
@media (min-width: 1280px) {
  .blue-card-bg {
    perspective: 200vw;
    perspective-origin: 50% 80%;
  }
  .blue-card-bg.not-perspective {
    perspective: none;
  }
  .blue-card-bg.flip {
    transform: scaleX(-1);
    left: auto;
  }
}
.blue-card-bg:before, .blue-card-bg:after {
  content: "";
  position: absolute;
  z-index: 0;
  display: block;
  border-radius: 1.5rem;
}
@media (max-width: 1023.98px) {
  .blue-card-bg:before, .blue-card-bg:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (min-width: 1280px) {
  .blue-card-bg:before, .blue-card-bg:after {
    filter: blur(0.5px);
    transform-style: preserve-3d;
    transform: rotate3d(0, 1, 0, 350deg) rotateZ(7deg) translate(0, 7vh);
  }
}
.blue-card-bg:before {
  padding: 6px;
  border: 6px solid #0c2780;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(180deg, #07cee0 0%, #0c2780 20%);
}
@media (max-width: 1023.98px) {
  .blue-card-bg:before {
    border-right: none;
  }
}
@media (min-width: 1280px) {
  .blue-card-bg:before {
    background-image: linear-gradient(168deg, #07cee0 0%, #0c2780 87%);
  }
}
.blue-card-bg:after {
  top: 7px;
  bottom: 7px;
  left: 7px;
  right: 0;
  z-index: 1;
  border-radius: 1.125rem;
  background: radial-gradient(44% 158% at 37% 90%, #223f9f 0%, #0c2780 100%);
}
@media (max-width: 1023.98px) {
  .blue-card-bg:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (min-width: 1280px) {
  .blue-card-bg:after {
    right: 7px;
  }
}
.blue-card-bg.shadow-card:before {
  box-shadow: 50px 72px 120px rgba(13, 40, 130, 0.24);
}
@media (max-width: 1023.98px) {
  .blue-card-bg.not-perspective:before, .blue-card-bg.not-perspective:after {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
}
@media (min-width: 768px) {
  .blue-card-bg.not-perspective:before, .blue-card-bg.not-perspective:after {
    transform: rotate3d(0, 0, 0, 0) rotateZ(-4deg) translate(0, 0);
  }
}
.blue-card-bg.not-perspective:before {
  border-right: 6px solid #0c2780;
}
.blue-card-bg.not-perspective:after {
  right: 7px;
  border-radius: 1.125rem;
}
@media (min-width: 1280px) {
  .blue-card-bg.not-rotate {
    perspective-origin: 50% 0;
  }
}
@media (min-width: 1280px) {
  .blue-card-bg.not-rotate:before, .blue-card-bg.not-rotate:after {
    transform-style: preserve-3d;
    transform: rotate3d(0, 1, 0, 350deg) rotateZ(0) translate(0, 0);
  }
}
.blue-card-bg.turquoise:before {
  border-color: #2e86fe;
  background-image: linear-gradient(180deg, #0c2780 0%, #2e86fe 80%);
}
.blue-card-bg.turquoise:after {
  background: radial-gradient(44% 158% at 37% 90%, #11afff 0%, #2e86fe 100%);
}
.blue-card-bg.white:before {
  border-color: #ffffff;
  background-image: linear-gradient(180deg, #0c2780 0%, #0c2780 80%);
}
.blue-card-bg.white:after {
  background: #ffffff;
}
.blue-card-bg.white-bg:before {
  border: 6px solid #ffffff;
  background-image: linear-gradient(90deg, #ffffff 0%, #0c2780 20%);
  box-shadow: 0 1.375rem 4.9rem 0 rgba(0, 0, 0, 0.25);
}
.blue-card-bg.white-bg:after {
  background: #ffffff;
}
.blue-card-content {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
@media (min-width: 1280px) {
  .blue-card-content {
    padding-top: 3rem;
  }
}

.button {
  text-decoration: none;
  border: none;
  border-radius: 0.5rem;
  color: #2e86fe;
  padding: 0.5rem 1rem;
  margin: 1rem auto 0;
  width: 100%;
  max-width: 20rem;
  height: 3.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.1;
  text-align: center;
  letter-spacing: 0.5px;
  transition: all 0.3s linear;
}
@media (min-width: 768px) {
  .button {
    max-width: 100%;
    width: 20rem;
    height: 4rem;
    font-size: 1.25rem;
  }
}
.inner .button {
  width: auto;
  height: auto;
}
.button.free-size {
  width: auto;
  height: auto;
  margin: 0 auto;
}
.button.small-btn {
  height: 3.25rem;
  font-size: 1.125rem;
}
.button.blue-bg {
  background-color: #2e86fe;
  color: #ffffff;
}
.button.white-bg {
  background-color: #ffffff;
}
.button.white-bg.dark {
  color: #0c2780;
}
.button.white-bg:hover, .button.white-bg:focus {
  background: #2e86fe;
  color: #ffffff;
}
.button.blue-gradient {
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  background: linear-gradient(270deg, #2e86fe 0%, #11afff 100%);
}
.button.blue-gradient:hover, .button.blue-gradient:focus {
  background: linear-gradient(320deg, #11afff 0%, #63f0eb 100%);
  color: #0c2780;
}
.button.hollow {
  border-width: 2px;
  border-style: solid;
  border-color: #2e86fe;
}
.button.cta {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .button.cta.large {
    font-size: 1.25rem;
  }
}
.button.turquoise-background-overlay:hover, .button.turquoise-background-overlay:focus {
  background: linear-gradient(320deg, #0c2780 0%, #12235c 100%);
  color: #ffffff;
}
.button.transparent {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}
.button.transparent:hover, .button.transparent:focus {
  background-color: #2e86fe;
}
.button.table-btn {
  width: auto;
  margin-top: 0;
  height: auto;
  font-size: 0.75rem;
  background-color: #ffffff;
  color: #0c2780;
  border-radius: 1.5rem;
  line-height: 1;
}
.button.table-btn.hollow {
  border-width: 1px;
  border-color: #0c2780;
}
.button.table-btn:hover, .button.table-btn:focus {
  background: #0c2780;
  color: #ffffff;
}
@media (min-width: 768px) {
  .button.table-btn {
    font-size: 1.125rem;
    height: 2.5rem;
    min-width: 8rem;
  }
}
.button-icon .icon {
  margin-right: 0.75rem;
  font-size: 1.5rem;
}
.button.icon:after {
  content: "";
  background-image: svg-encode(url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M6.4665 12.4668L10.4665 8.46683C10.7332 8.20016 10.7332 7.80016 10.4665 7.5335L6.4665 3.5335C6.19984 3.26683 5.79984 3.26683 5.53317 3.5335C5.2665 3.80016 5.2665 4.20016 5.53317 4.46683L9.0665 8.00016L5.53317 11.5335C5.39984 11.6668 5.33317 11.8668 5.33317 12.0002C5.33317 12.1335 5.39984 12.3335 5.53317 12.4668C5.79984 12.7335 6.19984 12.7335 6.4665 12.4668Z' fill='#ffffff' /></svg>"));
  background-repeat: no-repeat;
  background-position: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  opacity: 1;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
@media (max-width: 767.98px) {
  .button-group {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}
.button-group .button {
  margin: 0.3125rem 0;
}
@media (min-width: 768px) {
  .button-group .button {
    margin: 0 0.3125rem;
  }
  .button-group .button:first-child {
    margin-left: 0;
  }
  .button-group .button:last-child {
    margin-right: 0;
  }
}

.cta-wrap {
  text-align: center;
}
@media (min-width: 768px) {
  .cta-wrap {
    width: 100%;
  }
}
.cta-wrap .cta {
  color: #ffffff;
  margin-bottom: 1.5rem;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  background: linear-gradient(270deg, #2e86fe 0%, #11afff 100%);
}
.cta-wrap .cta:hover, .cta-wrap .cta:focus {
  background: linear-gradient(320deg, #11afff 0%, #63f0eb 100%);
  color: #0c2780;
}

.btn-plus {
  --bs-btn-color: #000;
  --bs-btn-bg: #11afff;
  --bs-btn-border-color: #11afff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #35bbff;
  --bs-btn-hover-border-color: #29b7ff;
  --bs-btn-focus-shadow-rgb: 14, 149, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #41bfff;
  --bs-btn-active-border-color: #29b7ff;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #11afff;
  --bs-btn-disabled-border-color: #11afff;
  color: #ffffff;
  background-image: linear-gradient(270deg, #2e86fe 0%, #11afff 100%);
  transition: color 0.3s ease-in-out;
}
.btn-plus:hover, .btn-plus:active {
  background-image: linear-gradient(320deg, #11afff 0%, #63f0eb 100%);
}

.items-icon-group {
  justify-content: space-around;
}
@media (max-width: 767.98px) {
  .items-icon-group {
    text-align: center;
  }
}
.items-icon-group .item {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  color: #ffffff;
  height: 100%;
}
.items-icon-group .item-icon {
  display: flex;
  justify-content: center;
  margin: 0 auto 1.5rem;
}
@media (min-width: 768px) {
  .items-icon-group .item-icon {
    margin-bottom: 2.25rem;
  }
}
.items-icon-group .item-icon img, .items-icon-group .item-icon svg {
  max-width: 5rem;
  height: 5rem;
}
@media (min-width: 768px) {
  .items-icon-group .item-icon img, .items-icon-group .item-icon svg {
    max-width: 6.25rem;
    height: 6.25rem;
  }
}
.items-icon-group .item-content {
  padding-bottom: 1rem;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .items-icon-group .item-content {
    min-height: 10rem;
  }
}
.items-icon-group .item-title {
  font-size: 1.5rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .items-icon-group .item-title {
    font-size: 2rem;
    font-weight: 300;
  }
}
.items-icon-group .item-desc {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.items-icon-group .item-desc a {
  color: #ffffff;
  text-decoration: underline;
}
.items-icon-group .item-desc a:hover, .items-icon-group .item-desc a:focus {
  text-decoration: none;
}
@media (min-width: 768px) {
  .items-icon-group.side-icon .item {
    flex-direction: row;
    padding: 3rem;
  }
}
@media (min-width: 768px) {
  .items-icon-group.side-icon .item-icon {
    width: 6.25rem;
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
.items-icon-group.side-icon .item-icon img, .items-icon-group.side-icon .item-icon svg {
  max-width: 5rem;
  height: 5rem;
}
@media (min-width: 768px) {
  .items-icon-group.side-icon .item-icon img, .items-icon-group.side-icon .item-icon svg {
    max-width: 6.25rem;
    height: 6.25rem;
  }
}
.items-icon-group.side-icon .item-content {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .items-icon-group.side-icon .item-content {
    min-height: auto;
    padding-bottom: 0;
  }
}
.items-icon-group.dark .item {
  color: #555555;
}
.items-icon-group.dark .item-title {
  color: #0c2780;
}

.disclaimer-zone {
  text-align: center;
}
.disclaimer-zone .disclaimer {
  padding-top: 2rem;
  line-height: 1.3;
}
.disclaimer-zone .disclaimer-strong, .disclaimer-zone .disclaimer-footer {
  font-weight: 600;
  font-size: 1.125rem;
  color: #0c2780;
}
@media (min-width: 768px) {
  .disclaimer-zone .disclaimer-strong, .disclaimer-zone .disclaimer-footer {
    font-size: 1.25rem;
  }
}
.regulatory .disclaimer-zone .disclaimer-strong, .regulatory .disclaimer-zone .disclaimer-footer {
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  .regulatory .disclaimer-zone .disclaimer-strong, .regulatory .disclaimer-zone .disclaimer-footer {
    font-size: 1.5rem;
  }
}
.disclaimer-zone .disclaimer-strong a, .disclaimer-zone .disclaimer-footer a {
  color: #0c2780;
}
.disclaimer-zone .disclaimer-strong a, .disclaimer-zone .disclaimer-strong span, .disclaimer-zone .disclaimer-footer a, .disclaimer-zone .disclaimer-footer span {
  display: block;
}
@media (min-width: 768px) {
  .disclaimer-zone .disclaimer-strong a, .disclaimer-zone .disclaimer-strong span, .disclaimer-zone .disclaimer-footer a, .disclaimer-zone .disclaimer-footer span {
    display: inline-block;
  }
}
.disclaimer-zone .disclaimer-footer {
  padding-bottom: 3rem;
  font-weight: 400;
}
.disclaimer-zone .disclaimer a {
  text-decoration: underline;
}
.disclaimer-zone .disclaimer a:hover, .disclaimer-zone .disclaimer a:focus {
  text-decoration: none;
}

.bullet-plus {
  list-style: none;
  padding-left: 1.5rem;
}
.bullet-plus li {
  position: relative;
  margin-bottom: 0.5rem;
}
.bullet-plus li:before {
  content: "+";
  position: absolute;
  left: -1.25rem;
  color: #0c2780;
  font-weight: 500;
}

[v-cloak] {
  visibility: hidden;
}

.skeleton-box {
  --anim-opacity:.2;
  --anim-opacity-high:.5;
  display: inline-block;
  height: 3rem;
  width: 100%;
  animation: shimmer 2s infinite;
  mix-blend-mode: luminosity;
  border-radius: 0.25rem;
  background: rgba(73, 80, 87, 0.08) linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, var(--anim-opacity)) 7%, rgba(255, 255, 255, var(--anim-opacity-high)) 20%, rgba(255, 255, 255, 0) 33%) 50% 0/300% auto;
}
@keyframes shimmer {
  100% {
    background-position: -50% 0;
  }
}

.application-stores {
  margin-top: 4rem;
  position: relative;
}
.application-stores[data-active-client=ios] .link-play-store {
  display: none;
}
.application-stores[data-active-client=android] .link-app-store {
  display: none;
}
.application-stores .store-link {
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 auto;
  width: 10rem;
  display: block;
}
@media (min-width: 1024px) {
  .application-stores .store-link {
    width: 8.375rem;
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .application-stores .store-link {
    width: 12.9375rem;
  }
}
.application-stores .store-link:nth-child(n+2) {
  margin-top: 1rem;
}
.application-stores .link-play-store {
  height: 3rem;
  background-size: 115%;
}
@media (min-width: 1280px) {
  .application-stores .link-play-store {
    height: 4rem;
  }
}
.application-stores .link-app-store {
  height: 3.5rem;
  background-size: 100%;
}
@media (min-width: 1280px) {
  .application-stores .link-app-store {
    height: 4.5rem;
  }
}

.general-margin {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .general-margin {
    margin-top: 8.25rem;
    margin-bottom: 8.25rem;
  }
}

.blue-section {
  background: #0c2780;
  color: #ffffff;
}
.blue-section a:not(.btn) {
  color: #ffffff;
}
.blue-section .title-section {
  color: #ffffff;
}

.card-border {
  position: relative;
  z-index: 0;
  padding: 2rem 0.5rem;
  border-radius: 1rem;
}
.card-border:before {
  position: absolute;
  z-index: 0;
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  border-radius: 1rem;
  border: solid 0.125rem transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  content: "";
}
@media (min-width: 768px) {
  .card-border {
    padding: 2rem;
  }
}
.card-border.blue {
  color: #ffffff;
  background-color: #0c2780;
}
.card-border.blue:before {
  background-image: linear-gradient(#0c2780, #0c2780), linear-gradient(0deg, transparent, transparent, #EEB966, #EEB966);
}
.card-border.blue.side-border:before {
  background-image: linear-gradient(#0c2780, #0c2780), linear-gradient(90deg, transparent, transparent, #EEB966, #EEB966);
}
.card-border.white {
  background-color: #ffffff;
}
.card-border.white:before {
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(90deg, transparent, transparent, #EEB966, #EEB966);
}
.card-border > * {
  position: relative;
}

.title-section {
  font-size: 2rem;
  line-height: 1;
}
.lang-he .title-section, .lang-ar .title-section {
  line-height: 1.1;
}
.lang-en .title-section {
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .title-section {
    font-size: 3rem;
  }
}
@media (min-width: 1280px) {
  .title-section {
    font-size: 4rem;
  }
}
.title-section strong {
  background: linear-gradient(90deg, #EDB762 35%, #f4d4a1 55%, #EDB762 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}
@media (min-width: 768px) {
  .title-section strong {
    font-size: 3rem;
  }
}
@media (min-width: 1280px) {
  .title-section strong {
    font-size: 4rem;
  }
}

.plus-bg.gold:before {
  background-image: url("../images/pluses/plus-gold.svg");
}
.plus-bg.gold-fill:before {
  background-image: url("../images/pluses/plus-gold-fill.svg");
}

@media (max-width: 1023.98px) {
  .dropdown-toggle-search {
    position: static;
  }
}
.dropdown-toggle-search-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}
.dropdown-toggle-search-icon:before {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z' fill='#0c2780' /></svg>");
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
}
.show .dropdown-toggle-search-icon:before {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z' fill='#ffffff' /></svg>");
}

.dropdown-toggle-search .instrument-basic-info {
  display: flex;
  align-items: center;
}
.dropdown-toggle-search .instrument-basic-info figure {
  width: 1em;
  height: 1em;
  margin: 0 0.75rem 0 0;
  font-size: 1.875rem;
  border-radius: 0.5rem;
  overflow: hidden;
  flex: 0 0 auto;
}
.dropdown-toggle-search .instrument-basic-info figure img {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.dropdown-toggle-search .instrument-basic-info figure.show-fallback {
  display: flex;
  background: rgba(73, 80, 87, 0.08);
  justify-content: center;
  align-items: center;
}
.dropdown-toggle-search .instrument-basic-info figure.show-fallback:before {
  font-size: 0.5em;
}
.dropdown-toggle-search .instrument-basic-info figure.show-fallback img {
  display: none;
}
.dropdown-toggle-search .instrument-basic-info figure.show-icon img {
  opacity: 1;
}
.dropdown-toggle-search .instrument-basic-info .icon-with-border {
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.dropdown-toggle-search .instrument-basic-info figure.show-fallback {
  background: rgba(255, 255, 255, 0.75);
}
.dropdown-toggle-search .btn-link {
  background: center center/1.5rem url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z' fill='#0c2780' /></svg>") no-repeat;
  padding: 1.3125rem;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.5rem;
}
.dropdown-toggle-search .btn-link:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  outline: 0;
}
.dropdown-toggle-search .btn-link.show {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z' fill='#ffffff' /></svg>");
  background-color: #0c2780;
  border-radius: 0.5rem 0.5rem 0 0;
}
.dropdown-toggle-search .dropdown-toggle:after {
  display: none;
}
.dropdown-toggle-search .dropdown-menu {
  width: 22.5rem;
  background-color: #0c2780;
  box-shadow: 0 3.125rem 3.125rem rgba(8, 20, 60, 0.65);
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1.5rem;
}
.dropdown-toggle-search .dropdown-menu > div {
  width: 100%;
}
.dropdown-toggle-search .dropdown-menu.show {
  display: flex;
  margin-top: -0.125rem;
}
@media (max-width: 1023.98px) {
  .dropdown-toggle-search .dropdown-menu.show {
    margin-top: -0.625rem;
    width: 100%;
  }
}
.dropdown-toggle-search .input-group {
  position: relative;
}
.dropdown-toggle-search .input-group:focus-within .input-group-icon {
  display: none;
}
.dropdown-toggle-search .input-group input {
  border: none;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  width: 100%;
  height: 2.75rem;
  border-radius: 2rem !important;
  padding: 0.5rem 1rem;
}
.dropdown-toggle-search .input-group input:focus {
  background-color: #ffffff;
  outline: none;
}
.dropdown-toggle-search .input-group input:focus + .input-group-icon {
  display: none;
}
.dropdown-toggle-search .input-group input::placeholder {
  color: #adadad;
}
.dropdown-toggle-search .input-group input + .input-group-icon {
  background: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3e%3cpath d='M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z' fill='%23bbbbbb' /%3e%3c/svg%3e") no-repeat 50%;
  height: 1.125rem;
  width: 1.125rem;
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.dropdown-toggle-search .results {
  margin: 2rem 0 1rem;
  max-height: 19rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.dropdown-toggle-search .results::-webkit-scrollbar-track {
  background-color: transparent;
}
.dropdown-toggle-search .results::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
}
.dropdown-toggle-search .results::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(24, 74, 181, 0.5);
}
@-moz-document url-prefix() {
  .dropdown-toggle-search .results {
    scrollbar-color: rgba(24, 74, 181, 0.5);
    scrollbar-width: thin;
  }
}
.dropdown-toggle-search .results .results-list {
  list-style: none;
  margin: 0;
  padding: 0 0.5rem 0 0;
  width: 100%;
}
.dropdown-toggle-search .results .search-result, .dropdown-toggle-search .results .search-results-category, .dropdown-toggle-search .results .search-no-results-data {
  padding: 0.5rem;
}
.dropdown-toggle-search .results .search-results-category {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  margin-bottom: 0;
  width: 100%;
}
.dropdown-toggle-search .results .search-result {
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.dropdown-toggle-search .results .search-result a {
  color: #ffffff;
  -webkit-user-drag: none;
}
.dropdown-toggle-search .results .search-result a.link-title {
  flex: 0 1 100%;
  font-size: 1rem;
  margin-right: 0.5rem;
}
.dropdown-toggle-search .results .search-result a.link-trade {
  background-color: #ffffff;
  color: #0c2780;
  border-color: #ffffff;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.125rem 0.75rem;
  text-align: center;
  min-width: 4rem;
  flex: 0 0 auto;
}
@media (min-width: 1024px) {
  .dropdown-toggle-search .results .search-result a.link-trade {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }
  .dropdown-toggle-search .results .search-result a.link-trade[v-cloak] {
    background-color: #ffffff;
  }
  .dropdown-toggle-search .results .search-result a.link-trade:focus {
    background-color: #ffffff;
    color: #0c2780;
    border-color: #ffffff;
  }
}
@media (min-width: 1024px) and (hover: hover) {
  .dropdown-toggle-search .results .search-result a.link-trade:hover {
    background-color: #ffffff;
    color: #0c2780;
    border-color: #ffffff;
  }
}
.dropdown-toggle-search .results .search-no-results-data span {
  color: #ffffff;
}
.dropdown-toggle-search .results .search-no-results-data a {
  color: #69f2ec;
}
.dropdown-toggle-search .results .highlight-search {
  color: #11afff;
}
.dropdown-toggle-search .results [v-cloak], .dropdown-toggle-search .results .placeholder-container {
  display: none;
}
.dropdown-toggle-search .results [v-cloak] ~ .placeholder-container {
  display: block;
}
.dropdown-toggle-search .results [v-cloak] ~ .placeholder-container .placeholder {
  background-color: rgba(255, 255, 255, 0.5);
  height: 1.375rem;
  border-radius: 1rem;
}
.dropdown-toggle-search .results [v-cloak] ~ .placeholder-container .placeholder.bg-light-blue {
  background-color: rgba(17, 175, 255, 0.5);
}

.main-header .rw-toggle, .main-header.prominent-rw .rw-container, .main-header .rw-container.blocked {
  background-color: #1a1a1e;
  color: #ffffff;
}

:root {
  --offcanvas-height:var(--viewh, 100vh);
  --languages-column-size:3;
  --languages-column-width:1fr;
}

.main-header .rw-container {
  background-color: #f6f6f6;
  color: #5e5e5e;
  text-align: center;
}
@media (min-width: 1024px) {
  .main-header .rw-container {
    text-align: left;
  }
}
.main-header .rw-container .main-row {
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
}
@media (min-width: 1024px) {
  .main-header .rw-container .main-row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 1024px) {
  .regulator-cysec.lang-cs .main-header.prominent-rw .rw-container {
    background-color: #f6f6f6;
    color: #000;
  }
}
.main-header .rw-text {
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 1;
}
.main-header .rw-text p {
  grid-column: 1;
  grid-row: 1;
  font-size: 0.75rem;
  margin: 0;
  letter-spacing: 0;
  line-height: 1;
}
@media (min-width: 768px) {
  .main-header .rw-text p {
    font-size: 0.9375rem;
    line-height: initial;
    letter-spacing: initial;
  }
}
.regulator-cysec .main-header .rw-text p {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .regulator-cysec .main-header .rw-text p {
    font-size: 1.125rem;
  }
}
@media (min-width: 1024px) {
  .lang-cs.regulator-cysec .main-header .rw-text p {
    font-size: 1.375rem;
    font-weight: 300;
  }
}

.regulator-scb .main-header .rw-text p {
  font-weight: 600;
}
.main-header .rw-short {
  opacity: 1;
  transition: opacity 0.35s ease;
}
@media (min-width: 1024px) {
  .main-header .rw-short {
    display: none;
  }
}
.main-header .collapse.show ~ .rw-short, .main-header .collapsing ~ .rw-short {
  opacity: 0;
}
.main-header .collapse.show ~ .rw-toggle:before {
  content: "\ed5e";
}
.main-header .rw-toggle {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  right: 0;
  width: 3rem;
  padding: 0;
  border-radius: 0 0 1.5rem 1.5rem;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  z-index: 1;
  box-shadow: none;
}
@media (min-width: 1024px) {
  .main-header .rw-toggle {
    display: none;
  }
}
.main-header .rw-toggle:focus {
  outline: 0;
}
@media (pointer: coarse) {
  .main-header .rw-toggle:focus {
    -webkit-tap-highlight-color: transparent;
  }
}
.main-header .service-unavailable-banner {
  background-color: #1a1a1e;
  padding: 0.5rem 0;
  text-align: center;
  box-shadow: 0 1px 0 0 #1a1a1e;
}
@media (min-width: 1024px) {
  .main-header .service-unavailable-banner {
    text-align: left;
  }
}
.main-header .service-unavailable-banner p {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .main-header .service-unavailable-banner p {
    font-size: 0.9375rem;
  }
}
.main-header .service-unavailable-banner p span {
  display: inline-block;
}
.main-header .service-unavailable-banner p a {
  color: #ffffff;
  text-decoration: underline;
}

header.sticky-top {
  z-index: 1021;
}
header nav {
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
  /* empty options forces defaults. */
  /*! rtl:options:{} */
}
.is-sticky header nav {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 0 0 0.75rem 0.75rem;
}
header nav.navbar {
  padding-top: 0.75rem;
}
header nav .logo svg {
  max-width: 8rem;
  margin-bottom: 0.25rem;
}
@media (min-width: 1280px) {
  header nav .logo svg {
    max-width: 10rem;
    margin-bottom: 0.5rem;
  }
}
header nav .logo-color-1 {
  fill: #0c2780;
}
.blue-header header nav .logo-color-1 {
  fill: #ffffff;
}
header nav .logo-color-2 {
  fill: #0c2780;
  stroke: none;
}
.blue-header header nav .logo-color-2 {
  fill: #ffffff;
}
header nav .logo-desc {
  color: #0c2780;
  font-size: 0.8125rem;
  font-weight: 500;
}
header nav .logo {
  order: 1;
}
header nav .switcher {
  order: 1;
}
header nav .header-trade {
  order: 2;
}
header nav .dropdown-toggle-search {
  order: 2;
}
@media (min-width: 1024px) {
  header nav .dropdown-toggle-search {
    order: 5;
  }
}
header nav .navbar-toggler {
  order: 3;
}
header nav .main-header-offcanvas {
  order: 4;
}
header nav .start-trading-link-box {
  order: 6;
}
.islamic-account header nav {
  background-image: url(../Images/islamic-account-topbar-bg.png);
  background-repeat: no-repeat;
  background-position: center -5px;
}
@media (min-width: 1024px) {
  .islamic-account header nav {
    background-position: center top;
    background-size: cover;
  }
}
header .header-trade {
  margin-left: 0.5rem;
  font-size: clamp(0.75rem, 4vw, 1rem);
}
[class^=invest-] header .header-trade {
  display: none;
}

@media (min-width: 1024px) {
  header .dropdown * {
    transition: none !important;
  }
}
header .dropdown-header {
  --bs-dropdown-header-color:#a4afd5;
  font-weight: 500;
}
@media (max-width: 1023.98px) {
  header .dropdown-header {
    --bs-dropdown-header-padding-x:0;
    --bs-dropdown-header-padding-y:1.125rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
}
header .dropdown-toggle, header .dropdown-item, header .dropdown-toggle.nav-link {
  box-shadow: none;
}
@media (pointer: coarse) and (max-width: 1024px) {
  header .dropdown-toggle, header .dropdown-item, header .dropdown-toggle.nav-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    transition: none;
  }
  header .dropdown-toggle:hover, header .dropdown-item:hover, header .dropdown-toggle.nav-link:hover {
    --bs-dropdown-link-hover-bg:none;
    --bs-nav-link-hover-color:#11afff;
    --bs-dropdown-link-hover-color:#11afff;
    border-bottom-color: #11afff;
  }
  header .dropdown-toggle:active, header .dropdown-item:active, header .dropdown-toggle.nav-link:active {
    --bs-dropdown-link-active-bg:none;
  }
  header .dropdown-toggle:focus, header .dropdown-item:focus, header .dropdown-toggle.nav-link:focus {
    color: #11afff;
    border-bottom-color: #11afff;
  }
}
@media (max-width: 1023.98px) {
  header .dropdown-toggle.no-border-bottom, header .dropdown-item.no-border-bottom, header .dropdown-toggle.nav-link.no-border-bottom {
    border-bottom: none;
  }
}
@media (min-width: 1024px) {
  header .dropdown-toggle {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}
@media (max-width: 1023.98px) {
  header .dropdown-toggle:not(.btn) {
    position: relative;
    padding-right: 2.5rem;
  }
  header .dropdown-toggle:not(.btn)::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  header .dropdown-toggle:not(.btn):empty::after {
    margin-left: 0;
  }
  header .dropdown-toggle:not(.btn):after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.75rem;
    margin: auto 0;
    height: 0.25rem;
  }
}
@media (max-width: 1023.98px) {
  header .dropdown-toggle-languages:after {
    right: auto;
    left: 2rem;
  }
}
header .dropdown-toggle.show {
  --bs-nav-link-color:#ffffff;
  --bs-navbar-active-color:#ffffff;
  background-color: #0c2780;
}
header .dropdown-item {
  --bs-dropdown-item-padding-x:0;
  --bs-dropdown-item-padding-y:1.125rem;
  white-space: normal;
}
@media (min-width: 1024px) {
  header .dropdown-item {
    --bs-dropdown-item-padding-x:1rem;
    --bs-dropdown-item-padding-y:0.5rem;
  }
}
header .dropdown-divider {
  --bs-dropdown-divider-margin-y:0;
}
header .dropdown-menu, header .dropdown-menu[data-bs-popper] {
  margin-top: -0.125rem;
  border: none;
}
@media (min-width: 1024px) {
  header .dropdown-menu, header .dropdown-menu[data-bs-popper] {
    border-radius: 0 0.5rem 0.5rem 0.5rem;
  }
  header .dropdown-menu.dropdown-menu-end, header .dropdown-menu[data-bs-popper].dropdown-menu-end {
    border-radius: 0.5rem 0 0.5rem 0.5rem;
  }
}
@media (hover: hover) and (pointer: fine) and (min-width: 1024px) {
  header .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  header .nav-item.dropdown:hover > .dropdown-menu-languages, header .nav-item.dropdown:hover > .dropdown-menu-search {
    display: none;
  }
  header .nav-item.dropdown:hover > .dropdown-menu-languages.show {
    display: block;
  }
  header .nav-item.dropdown:hover > .dropdown-menu-many-languages.show {
    display: grid;
  }
  header .nav-item.dropdown:hover > .dropdown-menu-search.show {
    display: flex;
  }
  header .nav-item.dropdown:hover > .dropdown-toggle {
    --bs-nav-link-color:#ffffff;
    --bs-nav-link-hover-color:#ffffff;
    background-color: #0c2780;
  }
  header .nav-item.dropdown:hover > .dropdown-toggle-languages {
    border-radius: 0.5rem;
  }
  header .nav-item.dropdown:hover > .dropdown-toggle-languages.show {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  header .nav-item.dropdown:hover > .dropdown-toggle:focus {
    --bs-nav-link-hover-color:#ffffff;
  }
  header .nav-item.dropdown > .dropdown-toggle:active {
    --bs-nav-link-hover-color:#ffffff;
    --bs-nav-link-color:#ffffff;
    background-color: #0c2780;
    pointer-events: none;
  }
  header .nav-item.dropdown > .dropdown-toggle:focus {
    --bs-nav-link-hover-color:#0c2780;
  }
  header .nav-item.dropdown > .dropdown-toggle-languages:active {
    pointer-events: auto;
  }
}
header .navbar-toggler {
  position: relative;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.375rem;
  overflow: hidden;
}
header .navbar-toggler span {
  position: absolute;
  left: 0;
  background-color: #0c2780;
  display: block;
  height: 2px;
  width: 100%;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  opacity: 1;
}
header .navbar-toggler span:nth-child(1) {
  top: 3px;
}
header .navbar-toggler span:nth-child(2) {
  top: 9px;
}
header .navbar-toggler span:nth-child(3) {
  top: 15px;
}
header .navbar-toggler.show span:nth-child(1) {
  top: 9px;
  transform: rotate(135deg);
}
header .navbar-toggler.show span:nth-child(2) {
  left: 18px;
  opacity: 0;
}
header .navbar-toggler.show span:nth-child(3) {
  top: 9px;
  transform: rotate(-135deg);
}
@media (max-width: 1023.98px) {
  header .offcanvas.offcanvas-start, header .offcanvas.offcanvas-end {
    height: var(--offcanvas-height);
  }
}
@media (max-width: 767.98px) {
  header .offcanvas.offcanvas-start, header .offcanvas.offcanvas-end {
    --bs-offcanvas-width:100%;
  }
}
@media (max-width: 1023.98px) {
  header .offcanvas-body {
    --bs-offcanvas-padding-x:0;
    --bs-offcanvas-padding-y:0;
    display: flex;
    flex-direction: column;
    height: var(--offcanvas-height);
  }
}
header .offcanvas .navbar-toggler span {
  background-color: #ffffff;
}
header .offcanvas .navbar-nav {
  --bs-nav-link-color:#ffffff;
  --bs-nav-link-hover-color:#11afff;
  --bs-navbar-active-color:#11afff;
  --bs-nav-link-padding-x:0;
  --bs-nav-link-padding-y:.75rem;
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 1023.98px) {
  header .offcanvas .navbar-nav {
    order: 2;
  }
}
@media (min-width: 1024px) {
  header .offcanvas .navbar-nav {
    --bs-nav-link-padding-y:0.5rem;
    --bs-nav-link-padding-x:1rem;
    --bs-nav-link-color:#0c2780;
  }
}
@media (min-width: 1024px) {
  header .offcanvas .navbar-nav .nav-link {
    --bs-navbar-nav-link-padding-x:0.5rem;
    --bs-navbar-nav-link-padding-y:0.75rem;
    white-space: normal;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
header .offcanvas .navbar-nav li {
  margin-bottom: 0;
}
header .offcanvas .navbar-nav li:last-child .dropdown-item {
  border-bottom: 0;
}
@media (min-width: 1024px) {
  header .offcanvas.main-header-offcanvas {
    margin: 0 0.5rem;
  }
}
@media (max-width: 1023.98px) {
  header .offcanvas.main-header-offcanvas .back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
  }
  header .offcanvas.main-header-offcanvas .back a, header .offcanvas.main-header-offcanvas .back button {
    color: #ffffff;
    padding: 1.5rem 1rem;
  }
  header .offcanvas.main-header-offcanvas .back button {
    background: transparent;
    border: 0;
  }
}
header .offcanvas.main-header-offcanvas .btn-back {
  font-size: 1rem;
  text-transform: unset;
  position: relative;
  flex: 1 1 100%;
}
header .offcanvas.main-header-offcanvas .btn-back:before {
  content: "\e90e" /*!rtl: "\e911" */;
  position: absolute;
  left: -1rem;
  top: 0;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  margin: auto 0;
}
header .offcanvas.main-header-offcanvas .btn-dismiss {
  display: flex;
  justify-content: right;
}
header .offcanvas.main-header-offcanvas .btn-dismiss:only-child {
  display: inline-block;
  margin-left: auto;
}
@media (max-width: 1023.98px) {
  header .offcanvas .nav-item {
    padding-left: 2rem;
  }
}
@media (max-width: 1023.98px) {
  header .offcanvas .nav-link {
    --bs-nav-link-font-size:1.25rem;
    --bs-nav-link-padding-y:1.125rem;
  }
}
@media (max-width: 1023.98px) {
  header .offcanvas .dropdown {
    position: static;
  }
}
header .offcanvas .dropdown-menu {
  --bs-body-bg:#0c2780;
  --bs-dropdown-padding-y:0;
}
@media (max-width: 1023.98px) {
  header .offcanvas .dropdown-menu {
    --bs-dropdown-spacer:0;
    --bs-dropdown-border-radius:0;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    transform-origin: top center;
    transform: translateX(-120%);
  }
}
@media (min-width: 1024px) {
  header .offcanvas .dropdown-menu {
    --bs-dropdown-padding-y:.5rem;
    flex-direction: row;
    justify-content: center;
    min-width: 16rem;
  }
}
header .offcanvas .dropdown-menu-dark {
  --bs-dropdown-header-color:#11afff;
  --bs-dropdown-divider-bg:#11afff;
}
@media (max-width: 1023.98px) {
  header .offcanvas .dropdown-menu-dark {
    --bs-dropdown-box-shadow:0;
  }
}
@media (max-width: 1023.98px) {
  header .offcanvas .dropdown-menu.show {
    transform: translateX(0%);
  }
}
@media (min-width: 1024px) {
  header .offcanvas .dropdown-menu .nav-title, header .offcanvas .dropdown-menu .nav-item.back {
    display: none;
  }
}
header .offcanvas .dropdown-menu .nav-title {
  color: var(--bs-nav-link-hover-color);
}
header .offcanvas .dropdown-menu .nav-link {
  --bs-nav-link-color:#ffffff;
  --bs-navbar-nav-link-padding-x:2.5rem;
  padding-left: var(--bs-navbar-nav-link-padding-x);
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
}
@media (min-width: 1024px) {
  header .offcanvas .dropdown-menu .nav-link {
    --bs-navbar-nav-link-padding-x:1rem;
    --bs-nav-link-padding-y:0.75rem;
    text-align: center;
    display: block;
  }
}
.IPhone header .offcanvas .dropdown-menu .nav-link:focus-visible {
  box-shadow: none;
}
@media (max-width: 1023.98px) {
  header .offcanvas .dropdown-menu .nav-item-doc:not(:has(~ .nav-item-doc)) .dropdown-item {
    border-bottom: none;
  }
}
header .offcanvas .dropdown-menu-languages {
  padding-left: 2rem;
}
@media (min-width: 1024px) {
  header .offcanvas .dropdown-menu-languages {
    padding: 0.5rem;
  }
}
header .offcanvas .dropdown-menu-languages.show {
  display: block;
}
header .offcanvas .dropdown-menu-languages .back {
  padding-left: 0;
}
header .offcanvas .dropdown-menu-many-languages {
  --bs-dropdown-font-size:.875rem;
  grid-template-columns: repeat(var(--languages-column-size), var(--languages-column-width));
  grid-auto-rows: max-content;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
@media (max-width: 1023.98px) {
  header .offcanvas .dropdown-menu-many-languages {
    display: grid;
  }
}
@media (min-width: 768px) {
  header .offcanvas .dropdown-menu-many-languages {
    --languages-column-size:4;
  }
}
@media (min-width: 1024px) {
  header .offcanvas .dropdown-menu-many-languages {
    --bs-dropdown-font-size:1rem;
    --languages-column-width:6.875rem;
  }
}
header .offcanvas .dropdown-menu-many-languages.show {
  display: grid;
}
header .offcanvas .dropdown-menu-many-languages .back {
  grid-column: span var(--languages-column-size);
}
header .offcanvas .dropdown-menu-many-languages .dropdown-item {
  white-space: normal;
  --bs-dropdown-item-padding-x:0.25rem;
  --bs-dropdown-item-padding-y:0.5rem;
  border-bottom: 0;
}
@media (min-width: 1024px) {
  header .offcanvas .dropdown-menu-many-languages .dropdown-item {
    --bs-dropdown-item-padding-x:1rem;
    --bs-dropdown-item-padding-y:0.25rem;
  }
}
header a.nav-link:first-letter, header a.link-trade:first-letter {
  text-transform: uppercase;
}
header a.nav-link.dropdown-toggle-languages, header a.link-trade.dropdown-toggle-languages {
  text-transform: uppercase;
}
.lang-en header a.nav-link.main-header, .lang-en header a.link-trade.main-header {
  text-transform: uppercase;
}

.start-trading-link-box {
  flex: 0 1 auto;
}
@media (max-width: 1023.98px) {
  .start-trading-link-box {
    display: none;
  }
}
.start-trading-link-box .rw-btn-invest {
  font-size: 0.875rem;
  display: block;
  text-align: center;
  padding-top: 0.25em;
}

.main-header .rw-container.blocked, .main-header.prominent-rw .rw-container, .main-header .rw-toggle {
  background-color: #07183d;
}

header {
  backdrop-filter: blur(0.625rem);
}
header nav {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 0.75rem 0.75rem;
}
header nav.navbar {
  position: absolute;
  width: 100%;
}
.islamic-account header nav {
  background-image: none;
}
body:not(.is-sticky) header nav .logo-color-1, body:not(.is-sticky) header nav .logo-color-2 {
  fill: #ffffff;
}
@media (min-width: 1024px) {
  body:not(.is-sticky) header .offcanvas .navbar-nav {
    --bs-nav-link-color:#ffffff;
  }
}
body:not(.is-sticky) header .dropdown-toggle-search .btn-link {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z' fill='#ffffff' /></svg>");
}
body:not(.is-sticky) header .navbar-toggler span {
  background-color: #ffffff;
}

.main-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 60rem;
  height: calc(var(--viewh, 100vh) - var(--rwh, 52px) - 62px);
  background-color: #0c2780;
  box-shadow: 0 2.75rem 3.625rem rgba(24, 74, 181, 0.3);
  overflow: hidden;
  transition: height 0.3s ease;
}
@media (min-width: 768px) {
  .main-banner {
    height: auto;
  }
}
.main-banner:before, .main-banner:after {
  margin-bottom: -4.5rem;
  flex: 1 1 100%;
  z-index: 0;
  animation: fadeIn 0.3s;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .main-banner:before, .main-banner:after {
    position: absolute;
    top: 0;
    /*! rtl:ignore */right: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
}
.main-banner .plus-bg {
  z-index: 0;
}
.main-banner .plus-bg-small {
  width: 6rem;
  height: 6rem;
  top: -20%;
  /*! rtl:ignore */left: auto;
  /*! rtl:ignore */right: -2%;
  opacity: 0.5;
}
@media (min-width: 768px) {
  .main-banner .plus-bg-small {
    top: 80%;
    opacity: 1;
  }
}
@media (min-width: 1280px) {
  .main-banner .plus-bg-small {
    top: 20%;
    /*! rtl:ignore */left: -14%;
    /*! rtl:ignore */right: auto;
  }
}
.main-banner .plus-bg-medium {
  width: 10rem;
  height: 10rem;
  top: -100%;
  /*! rtl:ignore */left: -4%;
  opacity: 0.5;
}
@media (min-width: 1024px) {
  .main-banner .plus-bg-medium {
    top: -10%;
    /*! rtl:ignore */left: 0;
    opacity: 1;
  }
}
@media (min-width: 1400px) {
  .main-banner .plus-bg-large {
    width: 28rem;
    height: 28rem;
    top: 50%;
    /*! rtl:ignore */left: -10%;
  }
}
.main-banner .main-banner-wrap {
  /*! rtl:ignore */direction: ltr;
}
.lang-he .main-banner .hero-content, .lang-he .main-banner .marketing-content, .lang-ar .main-banner .hero-content, .lang-ar .main-banner .marketing-content {
  /*! rtl:ignore */direction: rtl;
}
.main-banner.hero-banner .plus-bg-small, .main-banner.marketing-banner .plus-bg-small {
  /*! rtl:ignore */left: auto;
  /*! rtl:ignore */right: -2%;
}
@media (min-width: 1280px) {
  .main-banner.hero-banner .plus-bg-small, .main-banner.marketing-banner .plus-bg-small {
    /*! rtl:ignore */left: -14%;
    /*! rtl:ignore */right: auto;
  }
}
.main-banner.hero-banner .plus-bg-medium, .main-banner.marketing-banner .plus-bg-medium {
  /*! rtl:ignore */left: -4%;
  /*! rtl:ignore */right: auto;
}
@media (min-width: 1024px) {
  .main-banner.hero-banner .plus-bg-medium, .main-banner.marketing-banner .plus-bg-medium {
    /*! rtl:ignore */left: 0;
  }
}
.main-banner.hero-banner .plus-bg-large, .main-banner.marketing-banner .plus-bg-large {
  /*! rtl:ignore */right: auto;
}
@media (min-width: 1400px) {
  .main-banner.hero-banner .plus-bg-large, .main-banner.marketing-banner .plus-bg-large {
    /*! rtl:ignore */left: -10%;
  }
}
.main-banner.hero-banner {
  --banner-bg-top-position: 0;
}
.home .main-banner.hero-banner {
  --banner-bg-top-position: -3rem;
}
.main-banner.hero-banner:before {
  width: 100%;
  right: 0;
  background-size: cover;
  /*! rtl:ignore */background-position: 60% 40%;
  background-image: url("../Images/newhome/img/hero-slider-cfd-mobile.webp");
}
.safari .main-banner.hero-banner:before, .edge .main-banner.hero-banner:before, .ff-old .main-banner.hero-banner:before {
  background-image: url("../Images/newhome/img/hero-slider-cfd-mobile.png");
}
@media (min-width: 768px) {
  .main-banner.hero-banner:before {
    background-image: url("../Images/newhome/img/hero-slider-cfd.webp");
    /*! rtl:ignore */background-position: right -50vw top 0;
    background-size: auto 100%;
  }
  .safari .main-banner.hero-banner:before, .edge .main-banner.hero-banner:before, .ff-old .main-banner.hero-banner:before {
    background-image: url("../Images/newhome/img/hero-slider-cfd.png");
  }
}
@media (min-width: 1024px) {
  .main-banner.hero-banner:before {
    /*! rtl:ignore */background-position: right -40vw top var(--banner-bg-top-position);
  }
}
@media (min-width: 1280px) {
  .main-banner.hero-banner:before {
    /*! rtl:ignore */background-position: right -20vw top var(--banner-bg-top-position);
  }
}
@media (min-width: 1400px) {
  .main-banner.hero-banner:before {
    /*! rtl:ignore */background-position: right -15vw top var(--banner-bg-top-position);
  }
}
@media (min-width: 1600px) {
  .main-banner.hero-banner:before {
    /*! rtl:ignore */background-position: right -8vw top var(--banner-bg-top-position);
    background-size: contain;
  }
}
.main-banner.marketing-banner:before {
  width: 100%;
  right: 0;
  margin-bottom: -50%;
  background-size: cover;
  /*! rtl:ignore */background-position: 35% 0;
  background-image: url("../Images/newhome/img/us-hero-bulls-banner-mobile.webp");
}
.safari .main-banner.marketing-banner:before, .edge .main-banner.marketing-banner:before, .ff-old .main-banner.marketing-banner:before {
  background-image: url("../Images/newhome/img/us-hero-bulls-banner-mobile.png");
}
@media (min-width: 768px) {
  .main-banner.marketing-banner:before {
    margin-bottom: 0;
    background-image: url("../Images/newhome/img/us-hero-bulls-banner.webp");
    background-size: 65% auto;
    /*! rtl:ignore */background-position: 135% 100%;
  }
  .safari .main-banner.marketing-banner:before, .edge .main-banner.marketing-banner:before, .ff-old .main-banner.marketing-banner:before {
    background-image: url("../Images/newhome/img/us-hero-bulls-banner.png");
  }
}
@media (min-width: 1024px) {
  .main-banner.marketing-banner:before {
    background-size: auto 130%;
    /*! rtl:ignore */background-position: right -15rem top -6.5rem;
  }
}
.main-banner.marketing-banner:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(140, 153, 195, 0) 0, rgba(12, 39, 128, 0.45) 35%, rgb(12, 39, 128) 60%);
}
@media (min-width: 768px) {
  .main-banner.marketing-banner:after {
    display: none;
  }
}
.main-banner.marketing-banner .banner-link-button {
  background-color: #c50316;
}
.main-banner.marketing-banner .banner-link-button:after {
  content: "\e910";
}
.main-banner.marketing-banner.extended-hours:before {
  background-size: auto 90%;
  background-image: url("../Images/newhome/img/extended_hours_banner_mobile.webp");
  /*! rtl:ignore */background-position: left 50% top 0;
}
.safari .main-banner.marketing-banner.extended-hours:before, .edge .main-banner.marketing-banner.extended-hours:before, .ff-old .main-banner.marketing-banner.extended-hours:before {
  background-image: url("../Images/newhome/img/extended_hours_banner_mobile.png");
}
@media (min-width: 768px) {
  .main-banner.marketing-banner.extended-hours:before {
    background-image: url("../Images/newhome/img/extended_hours_banner_desktop.webp");
    /*! rtl:ignore */background-position: left -8rem top 1rem;
  }
  .safari .main-banner.marketing-banner.extended-hours:before, .edge .main-banner.marketing-banner.extended-hours:before, .ff-old .main-banner.marketing-banner.extended-hours:before {
    background-image: url("../Images/newhome/img/extended_hours_banner_desktop.png");
  }
}
@media (min-width: 1600px) {
  .main-banner.marketing-banner.extended-hours:before {
    /*! rtl:ignore */background-position: left 6rem top 1rem;
  }
}
.main-banner.marketing-banner.extended-hours .main-banner-wrap {
  justify-content: flex-end;
}
.main-banner.marketing-banner.extended-hours .extended-hours-strip .banner-link-button {
  background-color: #0c2780;
  color: #ffffff;
}
.main-banner.marketing-banner.elections-banner:before {
  width: 100%;
  margin-bottom: -4.5rem;
  /*! rtl:ignore */left: 0;
  /*! rtl:ignore */right: auto;
  background-size: cover;
  background-image: url("../Images/hero_banners/hero-elections-mobile.webp");
  /*! rtl:ignore */background-position: center top;
}
.safari .main-banner.marketing-banner.elections-banner:before, .edge .main-banner.marketing-banner.elections-banner:before, .ff-old .main-banner.marketing-banner.elections-banner:before {
  background-image: url("../Images/hero_banners/hero-elections-mobile.jpg");
}
@media (min-width: 768px) {
  .main-banner.marketing-banner.elections-banner:before {
    width: 50%;
    background-image: url("../Images/hero_banners/hero-elections.webp");
    /*! rtl:ignore */background-position: right center;
  }
  .safari .main-banner.marketing-banner.elections-banner:before, .edge .main-banner.marketing-banner.elections-banner:before, .ff-old .main-banner.marketing-banner.elections-banner:before {
    background-image: url("../Images/hero_banners/hero-elections.jpg");
  }
}
@media (min-width: 1600px) {
  .main-banner.marketing-banner.elections-banner:before {
    width: 40%;
  }
}
.main-banner.marketing-banner.elections-banner:after {
  display: none;
  height: 120%;
  width: 105%;
  top: -10%;
  /*! rtl:ignore */transform: rotate(-8deg);
  background-image: url("../Images/pluses/star.svg"), url("../Images/pluses/star.svg"), url("../Images/pluses/star.svg");
  /*! rtl:ignore */background-position: right 2% top 15rem, right 40% bottom -6rem, right 60% top -8rem;
  background-size: 30rem;
  background-repeat: no-repeat;
}
@media (min-width: 1600px) {
  .main-banner.marketing-banner.elections-banner:after {
    display: block;
  }
}
.main-banner.marketing-banner.elections-banner .plus-bg {
  display: none;
}
.main-banner.marketing-banner.elections-banner .main-banner-wrap {
  justify-content: flex-end;
}
.main-banner.extended-hours .extended-hours-content, .main-banner.extended-hours .elections-banner-content, .main-banner.elections-banner .extended-hours-content, .main-banner.elections-banner .elections-banner-content {
  padding: 2rem 1rem;
  font-family: Poppins, Roboto, Rubik, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  font-size: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .main-banner.extended-hours .extended-hours-content, .main-banner.extended-hours .elections-banner-content, .main-banner.elections-banner .extended-hours-content, .main-banner.elections-banner .elections-banner-content {
    font-size: 1.125rem;
    margin: 1.5rem 0;
    text-align: start;
  }
}
.main-banner.extended-hours .extended-hours-content .marketing-banner-title strong, .main-banner.extended-hours .elections-banner-content .marketing-banner-title strong, .main-banner.elections-banner .extended-hours-content .marketing-banner-title strong, .main-banner.elections-banner .elections-banner-content .marketing-banner-title strong {
  display: block;
  font-size: 1.5rem;
  color: #11afff;
  text-transform: uppercase;
  line-height: 1;
}
@media (min-width: 768px) {
  .main-banner.extended-hours .extended-hours-content .marketing-banner-title strong, .main-banner.extended-hours .elections-banner-content .marketing-banner-title strong, .main-banner.elections-banner .extended-hours-content .marketing-banner-title strong, .main-banner.elections-banner .elections-banner-content .marketing-banner-title strong {
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .main-banner.extended-hours .extended-hours-content .marketing-banner-title strong, .main-banner.extended-hours .elections-banner-content .marketing-banner-title strong, .main-banner.elections-banner .extended-hours-content .marketing-banner-title strong, .main-banner.elections-banner .elections-banner-content .marketing-banner-title strong {
    font-size: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .main-banner.extended-hours .extended-hours-content .marketing-banner-title strong, .main-banner.extended-hours .elections-banner-content .marketing-banner-title strong, .main-banner.elections-banner .extended-hours-content .marketing-banner-title strong, .main-banner.elections-banner .elections-banner-content .marketing-banner-title strong {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .main-banner.extended-hours .extended-hours-content .marketing-banner-title strong, .main-banner.extended-hours .elections-banner-content .marketing-banner-title strong, .main-banner.elections-banner .extended-hours-content .marketing-banner-title strong, .main-banner.elections-banner .elections-banner-content .marketing-banner-title strong {
    font-size: 3.5rem;
  }
}
@media (min-width: 1600px) {
  .main-banner.extended-hours .extended-hours-content .marketing-banner-title strong, .main-banner.extended-hours .elections-banner-content .marketing-banner-title strong, .main-banner.elections-banner .extended-hours-content .marketing-banner-title strong, .main-banner.elections-banner .elections-banner-content .marketing-banner-title strong {
    font-size: 4.5rem;
  }
}
.main-banner.extended-hours .marketing-banner-buttons-box, .main-banner.elections-banner .marketing-banner-buttons-box {
  text-align: center;
}
@media (min-width: 768px) {
  .main-banner.extended-hours .marketing-banner-buttons-box, .main-banner.elections-banner .marketing-banner-buttons-box {
    text-align: start;
  }
}
.main-banner-wrap {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  max-width: 83.75rem;
}
@media (min-width: 768px) {
  .main-banner-wrap {
    min-height: 34rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .main-banner-wrap {
    min-height: 38rem;
  }
}
@media (min-width: 1400px) {
  .main-banner-wrap {
    min-height: 42rem;
  }
}
.main-banner-content {
  position: relative;
  z-index: 0;
  color: #ffffff;
  text-align: center;
  padding: 2rem 1rem;
}
.main-banner-content.marketing-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  bottom: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .main-banner-content.marketing-content {
    bottom: auto;
  }
}
@media (min-width: 768px) {
  .main-banner-content {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .main-banner-content {
    text-align: center;
  }
}
.main-banner-content .blue-card-bg {
  transform: skew(0deg, -2deg);
  min-height: 26rem;
  height: 100%;
}
@media (min-width: 768px) {
  .main-banner-content .blue-card-bg {
    display: none;
  }
}
.main-banner-title, .main-banner-slogan {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .main-banner-title, .main-banner-slogan {
    font-size: 3rem;
    line-height: 1;
  }
}
@media (min-width: 1024px) {
  .main-banner-title, .main-banner-slogan {
    font-size: 4rem;
  }
}
@media (min-width: 1280px) {
  .main-banner-title, .main-banner-slogan {
    font-size: 5.5rem;
  }
}
.main-banner-title strong, .main-banner-slogan strong {
  color: #11afff;
  font-weight: 600;
}
.main-banner-title strong span, .main-banner-slogan strong span {
  color: #11afff;
}
.main-banner-slogan {
  color: #11afff;
  font-weight: 600;
}
.main-banner-desc {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .main-banner-desc {
    font-size: 1.5rem;
    margin-top: 1.25rem;
  }
}
.main-banner-desc strong {
  display: block;
  margin-bottom: 0.5rem;
}
.main-banner-text {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.1;
}
@media (min-width: 768px) {
  .main-banner-text {
    font-size: 1.375rem;
    line-height: 1.3;
    padding-right: 20%;
  }
}
.main-banner-anim {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.25rem;
  height: 1.5rem;
  color: #ffffff;
  font-weight: 300;
}
@media (min-width: 768px) {
  .main-banner-anim {
    font-size: 2.25rem;
    margin-top: 1.25rem;
    height: 2rem;
  }
}
.main-banner-anim-title {
  font-weight: 300 !important;
}
.main-banner-anim-item {
  position: absolute;
  right: 0;
  left: 0;
  font-weight: 500;
  line-height: 1;
  opacity: 0;
  white-space: nowrap;
}
.main-banner-no-ad {
  font-size: 1rem;
  padding: 0 0 0.25rem;
  font-weight: 300;
}
@media (min-width: 768px) {
  .main-banner-no-ad {
    font-size: 1.5rem;
    padding: 0.25rem 0;
  }
}
@media (min-width: 1024px) {
  .main-banner-no-ad {
    font-size: 2rem;
  }
}
.main-banner-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.main-banner-buttons .button {
  margin: 0.3125rem;
  height: 2.75rem;
  font-size: 1rem;
  flex: 1 1 40%;
  max-width: 20rem;
}
@media (orientation: portrait) {
  .main-banner-buttons .button {
    flex: 1 1 100%;
  }
}
.main-banner-buttons .button.hollow {
  border-color: transparent;
}
@media (min-width: 768px) {
  .main-banner-buttons {
    max-width: 20rem;
  }
  .main-banner-buttons .button {
    height: 3.25rem;
    margin-left: 0;
    flex: 1 1 auto;
  }
  .main-banner-buttons .button:last-child {
    margin-right: 0.3125rem;
  }
}
@media (min-width: 1024px) {
  .main-banner-buttons {
    max-width: 100%;
    margin-top: 2rem;
  }
  .main-banner-buttons .button {
    height: 4rem;
    font-size: 1.25rem;
    margin-left: 0.3125rem;
  }
}
@media (min-width: 1024px) and (orientation: portrait) {
  .main-banner-buttons .button {
    flex: 1 1 40%;
  }
}
.main-banner-buttons .cta-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.main-banner-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .main-banner-image {
    flex: 0 1 50%;
    width: 100%;
    position: relative;
    order: 0;
  }
  .main-banner-image img {
    position: absolute;
    bottom: -5rem;
    height: auto;
    z-index: -1;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
.main-banner .marketing-banner-logo {
  max-width: 9.125rem;
  margin: 0 auto 0.75rem;
}
@media (min-width: 768px) {
  .main-banner .marketing-banner-logo {
    max-width: 13.75rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .main-banner .marketing-banner-logo {
    max-width: 18.25rem;
    margin-left: auto;
  }
}
.main-banner .marketing-banner-title {
  font-size: 1.375rem;
  color: #ffffff;
  font-weight: 300;
}
@media (min-width: 768px) {
  .main-banner .marketing-banner-title {
    font-size: 1.75rem;
  }
}
@media (min-width: 1024px) {
  .main-banner .marketing-banner-title {
    font-size: 2.5rem;
  }
}
.main-banner .marketing-banner-bulls-name {
  margin: 0 auto 1rem;
  width: 96%;
  max-width: 20rem;
}
@media (min-width: 768px) {
  .main-banner .marketing-banner-bulls-name {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.875rem;
  }
}
.main-banner .marketing-banner-link-more {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  .main-banner .marketing-banner-link-more {
    margin-top: 2rem;
    font-size: 1.5rem;
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .main-banner .marketing-banner-link-more {
    justify-content: center;
  }
}
.main-banner .marketing-banner-link-more span {
  text-decoration: underline;
}
.main-banner .marketing-banner-link-more span:hover, .main-banner .marketing-banner-link-more span:focus {
  text-decoration: none;
}
.main-banner .marketing-banner-link-more i::after {
  content: "\e911";
  font-size: 0.75rem;
  padding: 0 0.5em;
}
[dir=rtl] .main-banner .marketing-banner-link-more i::after {
  content: "\e90e";
}

.lang-cn .main-banner .marketing-banner-data, .lang-zh .main-banner .marketing-banner-data, .lang-hu .main-banner .marketing-banner-data, .lang-lt .main-banner .marketing-banner-data {
  display: flex;
  flex-direction: column;
}
.lang-cn .main-banner .marketing-banner-title, .lang-zh .main-banner .marketing-banner-title, .lang-hu .main-banner .marketing-banner-title, .lang-lt .main-banner .marketing-banner-title {
  order: 3;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .lang-cn .main-banner .marketing-banner-title, .lang-zh .main-banner .marketing-banner-title, .lang-hu .main-banner .marketing-banner-title, .lang-lt .main-banner .marketing-banner-title {
    margin-bottom: 0;
  }
}
.lang-cn .main-banner .marketing-banner-bulls-name, .lang-zh .main-banner .marketing-banner-bulls-name, .lang-hu .main-banner .marketing-banner-bulls-name, .lang-lt .main-banner .marketing-banner-bulls-name {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.main-banner .banner-link {
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  background-color: #000;
}
@media (min-width: 768px) {
  .main-banner .banner-link {
    font-size: 1.25rem;
  }
}
.main-banner .banner-link.extended-hours-strip .banner-link-button {
  background-color: #11afff;
  color: #0c2780;
  font-size: 0.825rem;
  font-family: Poppins, Roboto, Rubik, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .main-banner .banner-link.extended-hours-strip .banner-link-button {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .main-banner .banner-link.extended-hours-strip .banner-link-button {
    font-size: 1.25rem;
  }
}
.main-banner .banner-link.extended-hours-strip .banner-link-button .hero-marketing-strip-slogan {
  padding: 0 0.5rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .main-banner .banner-link.extended-hours-strip .banner-link-button .hero-marketing-strip-slogan {
    padding: 1rem 0.5rem;
  }
}
.main-banner .banner-link.elections-banner-strip {
  padding: 0.75rem 6%;
  min-height: 4rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  font-size: 0.75rem;
  font-family: Poppins, Roboto, Rubik, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  background-color: #202446;
  background-image: url("../images/hero_banners/strip-main-bg.webp"), none;
  /*! rtl:ignore */background-position: right -13rem center;
  background-size: auto 4rem;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .main-banner .banner-link.elections-banner-strip {
    padding-right: 14%;
    padding-left: 14%;
    /*! rtl:ignore */background-position: right -12rem center, left 0 center;
    background-size: auto 100%;
  }
}
@media (min-width: 1024px) {
  .main-banner .banner-link.elections-banner-strip {
    padding-right: 10%;
    padding-left: 10%;
    /*! rtl:ignore */background-position: right -9rem center, left 0 center;
  }
}
@media (min-width: 1280px) {
  .main-banner .banner-link.elections-banner-strip {
    background-image: url("../images/hero_banners/strip-main-bg.webp"), url("../images/hero_banners/strip-side-bg.webp");
  }
}
@media (min-width: 1600px) {
  .main-banner .banner-link.elections-banner-strip {
    /*! rtl:ignore */background-position: right center, left center;
  }
}
.main-banner .banner-link.elections-banner-strip a {
  color: #ffffff;
  text-decoration: none;
}
.main-banner .banner-link.elections-banner-strip .banner-link-button {
  display: flex;
  padding: 0;
  background-color: transparent;
  color: #ffffff;
  box-shadow: none;
}
@media (max-width: 1023.98px) {
  .main-banner .banner-link.elections-banner-strip .banner-link-button {
    flex-direction: column;
  }
}
.main-banner .banner-link.elections-banner-strip .hero-marketing-strip-slogan {
  font-size: 0.75rem;
  padding: 0 0.5rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .main-banner .banner-link.elections-banner-strip .hero-marketing-strip-slogan {
    font-size: 1rem;
  }
}
@media (min-width: 1280px) {
  .main-banner .banner-link.elections-banner-strip .hero-marketing-strip-slogan {
    font-size: 1.25rem;
  }
}
.main-banner .banner-link-button {
  background: transparent;
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0 0;
  border-radius: 0;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: 0.3s ease;
}
@media (min-width: 768px) {
  .main-banner .banner-link-button {
    padding: 0.5rem 0;
  }
}
@media (min-width: 1024px) {
  .main-banner .banner-link-button {
    flex-direction: row;
    padding-bottom: 0.75rem;
  }
}
.main-banner .banner-link-button:after {
  content: "\e90f";
  font-family: "icomain";
  width: 1.25rem;
  height: 0.875rem;
  font-size: 0.875rem;
  line-height: 1;
}
.main-banner-bulls-link-button {
  flex-direction: column;
  padding-bottom: 0;
}
.main-banner-bulls-link-button:after {
  order: 1;
}
.main-banner-bulls-wrap {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  order: 0;
}
@media (min-width: 1024px) {
  .main-banner-bulls-wrap {
    order: 1;
  }
}
.main-banner-bulls-slogan {
  font-size: 0.625rem;
  order: 1;
  margin-top: 0.375rem;
}
@media (min-width: 768px) {
  .main-banner-bulls-slogan {
    font-size: 1.5rem;
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .main-banner-bulls-slogan {
    font-size: 1.75rem;
  }
}
.main-banner-bulls-name {
  height: 1.5rem;
  width: auto;
}
@media (min-width: 768px) {
  .main-banner-bulls-name {
    height: 2.25rem;
  }
}
.main-banner-bulls-logo {
  width: 1.875rem;
  height: auto;
  margin: 0 0.625rem;
}
@media (min-width: 768px) {
  .main-banner-bulls-logo {
    width: 3.25rem;
    margin: 0 1rem;
  }
}
@media (min-width: 1024px) {
  .main-banner-bulls-logo:last-child {
    display: none;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .main-banner .button-rw-invest {
    text-align: center;
    width: 100%;
  }
}
.main-banner .rw-btn-invest {
  text-align: center;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
.main-banner .close {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 1rem;
  top: 1.75rem;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.75;
}
@media (min-width: 768px) {
  .main-banner .close {
    width: 2rem;
    height: 2rem;
    right: 2rem;
  }
}
.main-banner .close:hover, .main-banner .close:focus {
  opacity: 1;
}
.main-banner .close:before, .main-banner .close:after {
  position: absolute;
  top: 0;
  left: 0.75rem;
  content: "";
  height: 1.5rem;
  width: 2px;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .main-banner .close:before, .main-banner .close:after {
    height: 2rem;
    left: 1rem;
  }
}
.main-banner .close:before {
  transform: rotate(45deg);
}
.main-banner .close:after {
  transform: rotate(-45deg);
}
.main-banner.hero-banner:before {
  content: "";
}
.main-banner.hero-banner .hero-content {
  display: block;
  animation: fadeIn 0.3s;
}
.main-banner.hero-banner .marketing-content {
  display: none;
}
.main-banner.hero-banner .close {
  display: none;
}
.main-banner.marketing-banner:before {
  content: "";
}
.main-banner.marketing-banner .marketing-content {
  display: flex;
  animation: fadeIn 0.3s;
}
.main-banner.marketing-banner .hero-content {
  display: none;
}
.main-banner.marketing-banner .sg-awareness {
  display: none;
}
.main-banner.inner-banner:before {
  animation: none;
  width: 100%;
  right: 0;
  background-size: cover;
  background-position: 60% 0;
  background-image: linear-gradient(to bottom, rgba(12, 39, 128, 0) 15%, #0c2780 85%);
  content: "";
}
@media (min-width: 768px) {
  .main-banner.inner-banner:before {
    width: 60%;
    background-position: 50% 0;
    background-image: radial-gradient(circle at center, rgba(12, 39, 128, 0.4) 0, #0c2780 80%, #0c2780 100%);
  }
}
.lang-he .main-banner.inner-banner .main-banner-wrap, .lang-ar .main-banner.inner-banner .main-banner-wrap {
  direction: ltr;
}
.main-banner.inner-banner .main-banner-wrap {
  justify-content: center;
}
.main-banner.inner-banner .main-banner-content {
  text-align: center;
}
@media (min-width: 1024px) {
  .main-banner.inner-banner .main-banner-title {
    font-size: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .main-banner.inner-banner .main-banner-title strong {
    font-size: 5.5rem;
  }
}
.main-banner.inner-banner .main-banner-desc {
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  .main-banner.inner-banner .main-banner-desc {
    font-size: 1.5rem;
  }
}
.main-banner.inner-banner .main-banner-desc .content {
  color: #ffffff;
  line-height: 1.3;
}
.main-banner.inner-banner.small-height {
  margin-block-end: 2.5rem;
}
.main-banner.inner-banner.small-height .plus-bg {
  z-index: 0;
}
.main-banner.inner-banner.small-height .plus-bg-medium {
  display: none;
  width: 5rem;
  height: 5rem;
  top: 3rem;
  left: 4%;
}
@media (min-width: 1400px) {
  .main-banner.inner-banner.small-height .plus-bg-medium {
    display: block;
  }
}
.main-banner.inner-banner.small-height .plus-bg-large {
  display: none;
  width: 10rem;
  height: 10rem;
  top: auto;
  bottom: -5.5rem;
  left: 4%;
}
@media (min-width: 768px) {
  .main-banner.inner-banner.small-height .plus-bg-large {
    display: block;
  }
}
@media (min-width: 1400px) {
  .main-banner.inner-banner.small-height .plus-bg-large {
    left: 15%;
  }
}
.main-banner.inner-banner.small-height .main-banner-wrap {
  min-height: auto;
}
@media (min-width: 768px) {
  .main-banner.inner-banner.small-height .main-banner-wrap {
    min-height: 15rem;
  }
}
.main-banner.inner-banner.small-height .main-banner-title {
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .main-banner.inner-banner.small-height .main-banner-title {
    font-size: 2.5rem;
  }
}
.main-banner.inner-banner.small-height .main-banner-title strong {
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .main-banner.inner-banner.small-height .main-banner-title strong {
    font-size: 3.875rem;
  }
}
.main-banner .marketing-strip {
  min-height: 4.375rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #0c2780;
  border-top: 0.375rem solid #dfb97b;
  z-index: 1;
}
.main-banner .marketing-strip-crypto {
  background-image: url("https://cdn.plus500.com/Media/website-marketing-banner/crypto-bg.webp");
  /*! rtl:ignore */background-position: right -27rem center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .main-banner .marketing-strip-crypto {
    /*! rtl:ignore */background-position: right -25rem center;
  }
}
@media (min-width: 1024px) {
  .main-banner .marketing-strip-crypto {
    background-image: url("https://cdn.plus500.com/Media/website-marketing-banner/crypto-bg-side.webp"), url("https://cdn.plus500.com/Media/website-marketing-banner/crypto-bg.webp");
    /*! rtl:ignore */background-position: left 0 center, right -25rem center;
  }
}
@media (min-width: 1400px) {
  .main-banner .marketing-strip-crypto {
    /*! rtl:ignore */background-position: left 0 center, right -20rem center;
  }
}
@media (min-width: 1600px) {
  .main-banner .marketing-strip-crypto {
    /*! rtl:ignore */background-position: left 0 center, right -15rem center;
  }
}
.main-banner .marketing-strip-slogan {
  padding: 0 5%;
  font-size: 1rem;
  font-family: Poppins, Roboto, Rubik, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  color: #ffffff;
}
@media (min-width: 768px) {
  .main-banner .marketing-strip-slogan {
    font-size: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .main-banner .marketing-strip-slogan {
    font-size: 1.875rem;
  }
}
.main-banner .marketing-strip-slogan a {
  display: block;
  color: #ffffff;
}
.main-banner .marketing-strip-slogan span {
  color: #11afff;
  font-weight: 800;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bonus-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .bonus-box {
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .bonus-box {
    justify-content: center;
  }
}
.bonus-box .link-bonus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;
  width: 100%;
  max-width: 20rem;
  height: 3.25rem;
  padding: 0.5rem 1rem;
  margin: 0.3125rem 0.3125rem 0;
  background-color: #0c2780;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.1;
  text-decoration: none;
  border: 1px solid #ffffff;
  border-radius: 0.5625rem;
  transition: all 0.3s linear;
}
.bonus-box .link-bonus:hover, .bonus-box .link-bonus:focus {
  background-color: #184ab5;
}
.bonus-box .link-bonus [class^=icon-] {
  flex: 0 0 1.5rem;
  margin-right: 0.75rem;
  font-size: 120%;
}
.bonus-box .link-bonus [class^=icon-] img, .bonus-box .link-bonus [class^=icon-] svg {
  width: 100%;
  height: auto;
}
.bonus-box .link-bonus span {
  line-height: 1;
}
@media (min-width: 768px) {
  .bonus-box .link-bonus {
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .bonus-box .link-bonus {
    height: 4rem;
    max-width: 40.625rem;
    font-size: 1.25rem;
    margin: 1rem 0.3125rem 0;
  }
}

.hero-slider {
  max-height: 42rem;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.hero-slider.slick-initialized {
  width: 100%;
  opacity: 1;
  max-height: none;
}
.hero-slider.slick-initialized + .hero-slider-navigation {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .hero-slider.slick-initialized {
    height: 100%;
  }
  .hero-slider.slick-initialized.slick-slider, .hero-slider.slick-initialized .slick-list, .hero-slider.slick-initialized .slick-track {
    height: 100%;
  }
  .hero-slider.slick-initialized .hero-slide {
    display: flex;
    flex-direction: column;
  }
  .hero-slider.slick-initialized .hero-content {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }
}
.hero-slider-section .plus-bg-small {
  transform: rotate(-12deg) !important;
}
.hero-slider-section.main-banner.hero-banner:before {
  display: none;
}
.hero-slider-section.hero-banner .marketing-banner-container {
  display: none;
}
.hero-slider-section.marketing-banner .hero-slider-wrap {
  display: none;
}
.hero-slider-wrap {
  position: relative;
}
@media (max-width: 767.98px) {
  .hero-slider-wrap {
    flex: 1 1 100%;
  }
}
.hero-slider-navigation {
  position: absolute;
  bottom: 0.375rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 2.25rem;
  width: 9rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 768px) {
  .hero-slider-navigation {
    bottom: 1rem;
  }
}
@media (min-width: 1024px) {
  .hero-slider-navigation {
    bottom: 1.5rem;
  }
}
.hero-slider-navigation button {
  background: none;
  border: none;
}
.hero-slider-navigation .slick-arrow {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.hero-slider-navigation .slick-arrow.slick-prev, .hero-slider-navigation .slick-arrow.slick-next {
  width: 2rem;
}
.hero-slider-navigation .slick-arrow.slick-prev:before, .hero-slider-navigation .slick-arrow.slick-next:before {
  font-size: 1rem;
  color: #ffffff;
  width: 2rem;
}
.hero-slider-navigation .slick-arrow.slick-prev {
  left: 0;
}
.hero-slider-navigation .slick-arrow.slick-next {
  right: 0;
}
.hero-slider-navigation .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  width: auto;
  left: 2rem;
  right: 2rem;
}
.hero-slider-navigation .slick-dots li button {
  background: transparent;
  border: 0 none;
  border-radius: 50%;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.hero-slider-navigation .slick-dots li button:before {
  border-radius: 50%;
  opacity: 1;
  z-index: 1;
  background-color: #ffffff;
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.25rem;
}
.hero-slider-navigation .slick-dots li button svg {
  opacity: 0;
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #11afff;
  stroke-width: 0.25rem;
  stroke-dasharray: 0 100;
  transform: rotate(-90deg);
}
.hero-slider-navigation .slick-dots li.slick-active button:before {
  background-color: #11afff;
}
.hero-slider-navigation .slick-dots li.slick-active button svg {
  opacity: 1;
  animation-name: slick-dots-anim;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.hero-slide.no-ad:before {
  margin-bottom: -4.5rem;
  flex: 1 1 100%;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
}
@media (min-width: 768px) {
  .hero-slide.no-ad:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    /*! rtl:ignore */background-position: right -45vw top 0;
    background-size: auto 100%;
  }
}
@media (min-width: 1024px) {
  .hero-slide.no-ad:before {
    /*! rtl:ignore */background-position: right -25vw top 0;
  }
}
@media (min-width: 1280px) {
  .hero-slide.no-ad:before {
    /*! rtl:ignore */background-position: right -20vw top 0;
  }
}
@media (min-width: 1400px) {
  .hero-slide.no-ad:before {
    /*! rtl:ignore */background-position: right -15vw top 0;
  }
}
@media (min-width: 1600px) {
  .hero-slide.no-ad:before {
    /*! rtl:ignore */background-position: right -2vw top 0;
    background-size: contain;
  }
}
.hero-slide.invest-slide:before {
  background-image: url("../Images/newhome/img/hero-slider-invest-mobile.webp");
}
.safari .hero-slide.invest-slide:before, .edge .hero-slide.invest-slide:before, .ff-old .hero-slide.invest-slide:before {
  background-image: url("../Images/newhome/img/hero-slider-invest-mobile.png");
}
@media (min-width: 768px) {
  .hero-slide.invest-slide:before {
    background-image: url("../Images/newhome/img/hero-slider-invest.webp");
  }
  .safari .hero-slide.invest-slide:before, .edge .hero-slide.invest-slide:before, .ff-old .hero-slide.invest-slide:before {
    background-image: url("../Images/newhome/img/hero-slider-invest.png");
  }
}
.hero-slide.cfd-slide:before {
  background-image: url("../Images/newhome/img/hero-slider-cfd-mobile.webp");
}
.safari .hero-slide.cfd-slide:before, .edge .hero-slide.cfd-slide:before, .ff-old .hero-slide.cfd-slide:before {
  background-image: url("../Images/newhome/img/hero-slider-cfd-mobile.png");
}
@media (min-width: 768px) {
  .hero-slide.cfd-slide:before {
    background-image: url("../Images/newhome/img/hero-slider-cfd.webp");
  }
  .safari .hero-slide.cfd-slide:before, .edge .hero-slide.cfd-slide:before, .ff-old .hero-slide.cfd-slide:before {
    background-image: url("../Images/newhome/img/hero-slider-cfd.png");
  }
}

@keyframes slick-dots-anim {
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: 100 100;
  }
}
.main-banner {
  height: calc(var(--viewh, 100vh) - var(--rwh, 52px));
  box-shadow: none;
}
@media (min-width: 1024px) {
  .main-banner {
    height: auto;
    max-height: none;
  }
}
.main-banner.hero-banner:before {
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0, rgba(12, 39, 128, 0.15) 40%, #0c2780 70%), url("../Images/uae/hero-banner.webp");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  animation: zoomOutHeroBg 5s linear;
}
.safari .main-banner.hero-banner:before, .edge .main-banner.hero-banner:before, .ff-old .main-banner.hero-banner:before {
  background-image: linear-gradient(to bottom, transparent 0, rgba(12, 39, 128, 0.15) 40%, #0c2780 70%), url("../Images/uae/hero-banner.jpg");
}
@media (min-width: 768px) {
  .main-banner.hero-banner:before {
    background-position: 0 0;
    background-size: 100% auto;
  }
}
@media (min-width: 1024px) {
  .main-banner.hero-banner:before {
    position: absolute;
    width: 50%;
    background-image: linear-gradient(/*! rtl:ignore */to right, #0c2780 0, rgba(12, 39, 128, 0.15) 40%), url("../Images/uae/hero-banner.webp");
    /*! rtl:ignore */background-position: right 0 top 0;
    background-size: auto 100%;
  }
  .safari .main-banner.hero-banner:before, .edge .main-banner.hero-banner:before, .ff-old .main-banner.hero-banner:before {
    background-image: linear-gradient(/*! rtl:ignore */to right, #0c2780 0, rgba(12, 39, 128, 0.15) 40%), url("../Images/uae/hero-banner.webp");
  }
}
@media (min-width: 1600px) {
  .main-banner.hero-banner:before {
    width: 60rem;
    background-size: cover;
  }
}
.main-banner.hero-banner .plus-bg {
  z-index: 0;
}
.main-banner.hero-banner .plus-bg-medium {
  display: none;
}
@media (min-width: 768px) {
  .main-banner.hero-banner .plus-bg-medium {
    display: block;
    width: 15.5rem;
    height: 15.5rem;
    top: 0;
    /*! rtl:ignore */left: 4%;
  }
}
@media (min-width: 1024px) {
  .main-banner.hero-banner .plus-bg-medium {
    width: 17.5rem;
    height: 17.5rem;
    top: -8%;
    /*! rtl:ignore */left: 49%;
  }
}
.main-banner.hero-banner .plus-bg-medium.gold-fill {
  opacity: 0.6;
}
@media (min-width: 768px) {
  .main-banner.hero-banner .plus-bg-medium.gold-fill {
    z-index: 2;
    top: 35%;
    /*! rtl:ignore */left: 92%;
  }
}
@media (min-width: 1024px) {
  .main-banner.hero-banner .plus-bg-medium.gold-fill {
    z-index: 0;
    top: 70%;
  }
}
.main-banner.hero-banner .plus-bg-large {
  display: none;
  height: 22rem;
  width: 22rem;
}
@media (min-width: 1400px) {
  .main-banner.hero-banner .plus-bg-large {
    display: block;
    top: 0;
    /*! rtl:ignore */left: -2%;
  }
}
.main-banner.hero-banner .plus-bg-large.gold-fill {
  opacity: 0.6;
}
@media (min-width: 768px) {
  .main-banner.hero-banner .plus-bg-large.gold-fill {
    display: block;
    width: 15.5rem;
    height: 15.5rem;
    /*! rtl:ignore */left: 0;
  }
}
@media (min-width: 1024px) {
  .main-banner.hero-banner .plus-bg-large.gold-fill {
    top: -5%;
    /*! rtl:ignore */left: 46%;
  }
}
@media (min-width: 1280px) {
  .main-banner.hero-banner .plus-bg-large.gold-fill {
    height: 22rem;
    width: 22rem;
    top: -18%;
  }
}
.main-banner.hero-banner .hero-content {
  padding-top: 4.125rem;
  animation: none;
}
@media (min-width: 768px) {
  .main-banner-wrap {
    min-height: auto;
  }
}
@media (min-width: 1024px) {
  .main-banner-wrap {
    min-height: 48rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .main-banner-wrap {
    min-height: 54rem;
  }
}
.main-banner-content {
  text-align: center;
  background: linear-gradient(to bottom, transparent 0, #0c2780 20%);
}
@media (min-width: 1024px) {
  .main-banner-content {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: left;
    background: transparent;
  }
}
.main-banner-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .main-banner-title {
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 100;
    line-height: 1;
  }
  .lang-he .main-banner-title, .lang-ar .main-banner-title {
    line-height: 1.1;
  }
}
@media (min-width: 1280px) {
  .main-banner-title {
    font-size: 4rem;
  }
}
.main-banner-title strong {
  background: linear-gradient(90deg, #EDB762 35%, #f4d4a1 55%, #EDB762 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}
.main-banner-text {
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  .main-banner-text strong {
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .main-banner-text {
    font-size: 1.125rem;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .main-banner-buttons {
    max-width: 100%;
    flex-wrap: nowrap;
  }
  .main-banner-buttons .button {
    margin-left: 0.3125rem;
  }
}
@media (min-width: 1024px) {
  .main-banner-buttons {
    justify-content: start;
  }
}
.main-banner .bonus-box {
  justify-content: center;
}
@media (min-width: 1024px) {
  .main-banner .bonus-box {
    justify-content: inherit;
  }
}
@media (min-width: 768px) {
  .main-banner .bonus-box .link-bonus {
    margin-inline: 0;
  }
}

.hero-banner-animation {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 28.125rem;
  top: 5rem;
  right: 0;
  left: 0;
  margin: auto;
  animation: opacityHeroAnimation 1s linear;
  background: url("../Images/uae/hero-en.png") no-repeat center top/100% auto;
}
.lang-he .hero-banner-animation, .lang-ar .hero-banner-animation {
  background-image: url("../Images/uae/hero-ar.png");
}
@media (min-width: 1024px) {
  .hero-banner-animation {
    height: auto;
    top: 0;
    bottom: 0;
    margin-top: -2.25rem;
    /*! rtl:ignore */right: 0;
    /*! rtl:ignore */left: auto;
    background-position: center;
  }
}
@media (min-width: 1400px) {
  .hero-banner-animation {
    max-width: 37.5rem;
    /*! rtl:ignore */right: calc(50% - 37.5rem - 8rem);
  }
}
@media (max-width: 1023.98px) {
  .hero-banner-animation dotlottie-player {
    height: auto;
  }
}

@keyframes zoomOutHeroBg {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes opacityHeroAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.countries {
  position: relative;
  z-index: 2;
  background-color: #0c2780;
  font-size: 1rem;
  padding: 0.875rem;
  border-top: solid 0.25rem #eaa944;
}
@media (min-width: 768px) {
  .countries {
    font-size: 1.5rem;
    padding: 1.5rem;
    border-width: 0.375rem;
  }
}
.countries-title {
  background: linear-gradient(90deg, #EDB762 35%, #f4d4a1 55%, #EDB762 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 1rem 0 0;
  margin-right: 1rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  border-right: 1px solid #EDB762;
}
@media (min-width: 768px) {
  .countries-title {
    padding: 0 2.5rem 0 1.5rem;
    margin-right: 2rem;
  }
}
.countries-wrapper {
  position: relative;
}
.countries-wrapper:after {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5rem;
  background: linear-gradient(to right, rgba(12, 39, 128, 0) 0, #0c2780 80%);
  content: "";
}
@media (min-width: 768px) {
  .countries-wrapper:after {
    width: 10rem;
  }
}
.countries-items {
  display: flex;
  gap: 2.5rem;
  padding-left: 2.5rem;
  color: #ffffff;
}
@media (min-width: 768px) {
  .countries-items {
    gap: 4.5rem;
    padding-left: 4.5rem;
  }
}

.marquee-wrapper {
  display: flex;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.marquee-animation {
  animation: marquee 30s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .marquee-animation {
    animation: none;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}
.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-prev, .slick-arrow.slick-next {
  font-size: 0;
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: transparent;
  border: none;
}
.slick-arrow.slick-prev:before, .slick-arrow.slick-next:before {
  color: #2e86fe;
  font-family: "icomain";
  font-size: 1.25rem;
  content: "\e90e";
}
[dir=rtl] .slick-arrow.slick-prev:before, [dir=rtl] .slick-arrow.slick-next:before {
  content: "\e911";
}
.slick-arrow.slick-next {
  right: -2rem;
}
.slick-arrow.slick-next:before {
  content: "\e911";
}
[dir=rtl] .slick-arrow.slick-next:before {
  content: "\e90e";
}
.slick-arrow.slick-prev {
  left: -2rem;
}
.slick-arrow.slick-disabled {
  cursor: not-allowed;
}
.slick-arrow.slick-disabled:before {
  color: #555555;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  left: 0;
  width: 100%;
  height: 2.25rem;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
  padding: 0;
  display: inline-block;
  cursor: pointer;
}
.slick-dots li button {
  background-color: #adadad;
  height: 0.875rem;
  width: 0.875rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  opacity: 0.25;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  background-color: #2e86fe;
}

.seo-carousel-section {
  overflow: hidden;
  position: relative;
}

.seo-slider {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .seo-slider {
    padding: 2rem 0;
  }
}
.seo-slider-wrapper {
  position: relative;
}
@media (min-width: 1024px) {
  .seo-slider-wrapper:before, .seo-slider-wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    pointer-events: none;
    height: 100%;
    width: 30%;
    z-index: 1;
  }
}
@media (min-width: 1024px) {
  .seo-slider-wrapper:before {
    right: 0;
    background-image: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 25%);
  }
}
@media (min-width: 1024px) {
  .seo-slider-wrapper:after {
    left: 0;
    background-image: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 25%);
  }
}
@media (min-width: 1024px) {
  .seo-slider > div {
    padding: 7rem 5%;
    margin: 0 auto;
  }
}
@media (min-width: 1400px) {
  .seo-slider > div {
    padding: 7rem 20%;
  }
}
.seo-slider > div + div {
  display: none;
}
@media (min-width: 1024px) {
  .seo-slider.slick-initialized .slick-list {
    margin: 0;
  }
}
.seo-slider.slick-initialized .slick-slide {
  display: flex;
}
.seo-slider .slick-track {
  display: flex;
}
.seo-slider .slick-slide {
  cursor: grab;
  height: auto;
}
.seo-slider .slick-slide:active {
  cursor: grabbing;
}
@media (min-width: 1024px) {
  .seo-slider .slick-slide {
    padding: 7rem 3rem;
  }
}
.seo-slider .slick-dotted {
  padding-bottom: 2.875rem;
}
.seo-slider .slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2.25rem;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.seo-slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.375rem;
  padding: 0;
  cursor: pointer;
}
.seo-slider .slick-dots li button {
  background-color: #9e9e9e;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 0.75rem;
  width: 0.75rem;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1024px) {
  .seo-slider .slick-dots li button {
    height: 1rem;
    width: 1rem;
  }
}
.seo-slider .slick-dots li button:hover, .seo-slider .slick-dots li button:focus {
  outline: none;
}
.seo-slider .slick-dots li button:hover:before, .seo-slider .slick-dots li button:focus:before {
  opacity: 1;
}
.seo-slider .slick-dots li button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
}
.seo-slider .slick-dots li.slick-active button:before {
  opacity: 0.75;
  background-color: #11afff;
}

.slide-wrapper {
  width: 100%;
}
@media (min-width: 1024px) {
  .slide-wrapper {
    border-radius: 1.75rem;
    box-shadow: 0 2.125rem 4.25rem 0 rgba(0, 0, 0, 0.13);
    overflow: hidden;
    position: relative;
  }
}
@media (min-width: 1024px) {
  .slide-wrapper:before {
    content: "";
    background: url(../Images/newhome/svg/border-gradient-top-right.svg) no-repeat 50%;
    background-size: 100% 100%;
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
  }
}
.slide-inner-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
}
@media (min-width: 1024px) {
  .slide-inner-wrapper {
    border-radius: 1.125rem;
    overflow: hidden;
  }
}
.slide-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  height: auto;
  order: 2;
  padding: 2rem 1rem;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media (min-width: 1024px) {
  .slide-content {
    flex: 0 1 60%;
    max-width: 60%;
    order: 1;
    padding: 3.5rem 3.25rem;
    text-align: left;
  }
}
.slide-content .title {
  color: #0c2780;
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1024px) {
  .slide-content .title {
    font-size: 2rem;
  }
  .lang-he .slide-content .title {
    font-size: 2rem;
  }
}
.slide-content .title strong {
  display: block;
  font-size: 1.875rem;
  font-weight: 800;
  color: #2e86fe;
}
.lang-ar .slide-content .title strong {
  font-weight: 600;
}
.lang-he .slide-content .title strong {
  font-weight: 500;
}
.lang-en .slide-content .title strong {
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .slide-content .title strong {
    font-size: 3rem;
  }
}
.slide-content p {
  font-size: 0.875rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .slide-content p {
    font-size: 1.125rem;
  }
}
.slide-content .cta-wrapper {
  margin-top: auto;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .slide-content .cta-wrapper:last-child {
    margin-bottom: 0;
  }
}
.slide-content .risk-warning-notice {
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .slide-content .risk-warning-notice {
    font-size: 0.875rem;
  }
}
.slide-image-placeholder {
  flex: 1 0 100%;
  order: 1;
  height: 15rem;
  padding: 0 1rem;
}
@media (min-width: 768px) {
  .slide-image-placeholder {
    height: 20rem;
  }
}
@media (min-width: 1024px) {
  .slide-image-placeholder {
    flex: 0 1 40%;
    max-width: 40%;
    order: 2;
    height: auto;
    padding: 0;
  }
}
.slide-image-placeholder .image-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
}
@media (min-width: 1024px) {
  .slide-image-placeholder .image-background {
    background-size: cover;
    border-radius: 0 1.125rem 1.125rem 0;
    width: 40%;
    position: absolute;
    right: 0;
  }
}
.slide-image-placeholder .image-background.lazyloaded {
  opacity: 1;
}

.sponsorships-section {
  text-align: center;
  max-width: 94%;
  margin: 0 auto 3rem;
}
@media (min-width: 1280px) {
  .sponsorships-section {
    max-width: 75rem;
    margin-bottom: 8rem;
  }
}
.sponsorships-section .sponsorships-main-row {
  justify-content: space-around;
  position: relative;
  flex-wrap: nowrap;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-main-row {
    margin-top: 5rem;
  }
}
.sponsorships-section .sponsorships-item {
  display: flex;
  justify-content: center;
  flex: 0 1 30%;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-item {
    flex: 0 1 25%;
  }
}
.sponsorships-section .sponsorships-item:hover, .sponsorships-section .sponsorships-item:focus-within {
  z-index: 5 !important;
}
.sponsorships-section .sponsorships-item-image {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  transform: scale(1.25);
  transition: all 0.3s ease-in-out;
  transform-origin: center center;
}
.sponsorships-section .sponsorships-item-image img {
  flex: 1 1 100%;
  width: 100%;
}
.sponsorships-section .sponsorships-item-logo {
  position: relative;
  z-index: 1;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
}
.sponsorships-section .sponsorships-item-logo-image {
  display: inline-flex;
  justify-content: center;
}
.sponsorships-section .sponsorships-item-logo img {
  margin: auto;
  max-width: 3rem;
  max-height: 3.5rem;
}
.sponsorships-section .sponsorships-item-logo img:first-child {
  display: block;
}
.sponsorships-section .sponsorships-item-logo img:nth-child(2) {
  display: none;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-item-logo img {
    max-width: 5.5rem;
    max-height: 7rem;
  }
  .sponsorships-section .sponsorships-item-logo img:first-child {
    display: none;
  }
  .sponsorships-section .sponsorships-item-logo img:nth-child(2) {
    display: block;
  }
}
.sponsorships-section .sponsorships-item-logo span {
  color: #0c2780;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  padding: 0;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-item-logo span {
    color: #adadad;
    font-size: 1.25rem;
    margin-top: 1rem;
    padding: 0 1rem;
  }
}
.sponsorships-section .sponsorships-item-link {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.sponsorships-section .sponsorships-item-link:focus {
  outline: none;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-item-link:hover .sponsorships-item {
    z-index: 5;
  }
  .sponsorships-section .sponsorships-item-link:hover .sponsorships-item-image {
    transform: scale(1.5);
  }
  .sponsorships-section .sponsorships-item-link:hover .sponsorships-item-logo img:first-child {
    display: block;
  }
  .sponsorships-section .sponsorships-item-link:hover .sponsorships-item-logo img:nth-child(2) {
    display: none;
  }
  .sponsorships-section .sponsorships-item-link:hover .sponsorships-item-logo span {
    color: #0c2780;
  }
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item {
  position: relative;
  z-index: 0;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-bulls {
  justify-content: center;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-bulls-item {
  position: relative;
  z-index: 0;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-bulls > a:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-bulls > a:before {
  display: none;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-bulls > a > .sponsorship-logo-back {
  display: inline-block;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-bulls > a > img {
  filter: none;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-logo-bulls {
  z-index: 1;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-logo-bulls span {
  direction: ltr;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=bulls] .sponsorships-item-logo-bulls span {
    font-size: 1.25rem;
  }
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item {
  position: relative;
  z-index: 0;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-legia {
  justify-content: center;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-legia-item {
  position: relative;
  z-index: 0;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-legia > a:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-legia > a:before {
  display: none;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-legia > a > .sponsorship-logo-back {
  display: inline-block;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-legia > a > img {
  filter: none;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-logo-legia {
  z-index: 1;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-logo-legia span {
  direction: ltr;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=legia] .sponsorships-item-logo-legia span {
    font-size: 1.25rem;
  }
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item {
  position: relative;
  z-index: 0;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-young-boys {
  justify-content: center;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-young-boys-item {
  position: relative;
  z-index: 0;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-young-boys > a:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-young-boys > a:before {
  display: none;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-young-boys > a > .sponsorship-logo-back {
  display: inline-block;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-young-boys > a > img {
  filter: none;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-logo-young-boys {
  z-index: 1;
}
.sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-logo-young-boys span {
  direction: ltr;
}
@media (min-width: 768px) {
  .sponsorships-section .sponsorships-wrapper[data-featured-sponsorship=young-boys] .sponsorships-item-logo-young-boys span {
    font-size: 1.25rem;
  }
}

.feed-table {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .feed-table {
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .feed-table {
    max-width: 31rem;
  }
}
.feed-table h2, .feed-table .h2 {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.375rem;
  font-weight: 600;
  color: #136DE8;
  text-align: left;
}
@media (min-width: 768px) {
  .feed-table h2, .feed-table .h2 {
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 1.5rem;
    padding: 0;
    line-height: 1.2;
  }
}
.feed-table .instrument-basic-info {
  display: flex;
  align-items: center;
}
.feed-table .instrument-basic-info figure {
  width: 1em;
  height: 1em;
  margin: 0 0.75rem 0 0;
  font-size: 1.875rem;
  border-radius: 0.5rem;
  overflow: hidden;
  flex: 0 0 auto;
}
.feed-table .instrument-basic-info figure img {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.feed-table .instrument-basic-info figure.show-fallback {
  display: flex;
  background: rgba(73, 80, 87, 0.08);
  justify-content: center;
  align-items: center;
}
.feed-table .instrument-basic-info figure.show-fallback:before {
  font-size: 0.5em;
}
.feed-table .instrument-basic-info figure.show-fallback img {
  display: none;
}
.feed-table .instrument-basic-info figure.show-icon img {
  opacity: 1;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs {
  max-width: calc(100% - 1.5rem);
  flex-direction: row;
  flex: 0 1 auto;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs:before {
  width: calc(100% + 1.5rem);
  height: 1.75rem;
  left: 0;
  right: 0;
  top: 100%;
  bottom: auto;
  border-radius: 0 1.5rem 0 0;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs.categories-4 button {
  margin-left: -2px;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs.categories-5 button {
  margin-left: -3px;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs.categories-6 button {
  margin-left: -4px;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs.categories-7 button {
  margin-left: -5px;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs.categories-8 button {
  margin-left: -6px;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs.categories-9 button {
  margin-left: -7px;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs button {
  border-radius: 0.75rem 0.75rem 0 0;
  margin: 0 0 -3px 0;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs button:first-child {
  margin-left: 0;
}
.feed-table.feeds-top-tabs .instruments-widget-tabs button img {
  margin-right: 0;
}
.feed-table .instruments-table {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: space-between;
  color: #555555;
}
.feed-table .instrument-item {
  margin-bottom: 1rem;
  height: 2.5rem;
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: 3rem 1.5fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  align-items: center;
  justify-items: start;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .feed-table .instrument-item {
    grid-template-columns: 3rem minmax(2rem, 2fr) 3fr 1fr;
    grid-template-rows: none;
    justify-items: center;
  }
}
@media (min-width: 1024px) {
  .feed-table .instrument-item {
    font-size: 0.9375rem;
  }
}
@media (min-width: 1280px) {
  .feed-table .instrument-item {
    font-size: 1rem;
  }
}
.feed-table .instrument-item a {
  font-weight: 500;
}
.feed-table .instrument-item .trade-button {
  --bs-btn-color:#0c2780;
  --bs-btn-border-color:#0c2780;
  --bs-btn-hover-bg:#0c2780;
  --bs-btn-hover-border-color:#0c2780;
  --bs-btn-active-bg:#0c2780;
  --bs-btn-active-border-color:#0c2780;
  --bs-btn-border-radius:3rem;
  --bs-btn-border-width:1px;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .feed-table .instrument-item .trade-button {
    padding: 0.5rem 1rem;
  }
}
@media (min-width: 1280px) {
  .feed-table .instrument-item .trade-button {
    font-size: 0.9375rem;
  }
}
.feed-table .instrument-item .trade-button:focus, .feed-table .instrument-item .trade-button:hover {
  text-decoration: none;
}
.feed-table .instrument-item .inst-name-wrapper {
  display: flex;
  justify-self: start;
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  .feed-table .instrument-item .inst-name-wrapper {
    max-width: 90%;
    grid-column: span 2;
  }
}
.feed-table .instrument-item .inst-name-wrapper a {
  color: #0c2780;
}
.feed-table .instrument-item .inst-name-wrapper a:focus, .feed-table .instrument-item .inst-name-wrapper a:hover {
  color: #0c2780;
  text-decoration: underline;
}
@media (max-width: 767.98px) {
  .feed-table .instrument-item .inst-name-wrapper a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media (min-width: 768px) {
  .feed-table .instrument-item .inst-name-wrapper {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
.feed-table .instrument-item .inst-name-wrapper sup {
  margin-left: 0.125rem;
  top: -0.125rem;
  font-size: 0.75rem;
}
.feed-table .instrument-item .inst-name-wrapper sup span::before {
  color: #004080;
}
.feed-table .instrument-item span.sell, .feed-table .instrument-item span.buy, .feed-table .instrument-item span.rate-change, .feed-table .instrument-item span.trade {
  text-align: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.feed-table .instrument-item span.red span, .feed-table .instrument-item span.inst-down span {
  color: #D93E3E;
}
.feed-table .instrument-item span.green span, .feed-table .instrument-item span.inst-up span {
  color: #3F8426;
}
.feed-table .instrument-item span.rate-change .highlight-threshold {
  border-radius: 0.25rem;
  color: #ffffff;
  display: inline-block;
  min-width: 3.75rem;
  padding: 0.125rem 0.5rem;
}
@media (min-width: 1024px) {
  .feed-table .instrument-item span.rate-change .highlight-threshold {
    min-width: 4.5rem;
  }
}
.feed-table .instrument-item span.rate-change.inst-up .highlight-threshold {
  background-color: #3F8426;
}
.feed-table .instrument-item span.rate-change.inst-down .highlight-threshold {
  background-color: #D93E3E;
}
.feed-table .instrument-item .sell-buy-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: center;
}
@media (max-width: 767.98px) {
  .feed-table .instrument-item .sell-buy-grid > span:first-child {
    text-align: left;
    padding-left: 0;
  }
}
.feed-table .instrument-item .instrument-basic-info {
  justify-self: start;
}
@media (max-width: 767.98px) {
  .feed-table .instrument-item .instrument-basic-info {
    grid-row: span 2;
  }
}
.feed-table .instrument-item .sell, .feed-table .instrument-item .buy, .feed-table .instrument-item .change {
  text-align: center;
}
.feed-table .instrument-item .trade {
  justify-self: end;
}
@media (max-width: 767.98px) {
  .feed-table .instrument-item .trade {
    grid-row: span 2;
  }
}
.feed-table.mobile-view .instrument-item {
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: 3rem 1.5fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  align-items: center;
  justify-items: start;
  padding-left: 0;
  padding-right: 0;
}
.feed-table.mobile-view .instrument-item .inst-name-wrapper {
  max-width: 90%;
  grid-column: span 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.feed-table.mobile-view .instrument-item .inst-name-wrapper a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.feed-table.mobile-view .instrument-item .instrument-basic-info {
  grid-row: span 2;
}
.feed-table.mobile-view .instrument-item .sell-buy-grid > span:first-child {
  text-align: left;
  padding-left: 0;
}
.feed-table.mobile-view .instrument-item .trade {
  grid-row: span 2;
}

.instruments-widget-tabs {
  position: relative;
  max-width: calc(100% - 1.5rem);
  display: flex;
  align-items: flex-end;
  flex: 0 1 auto;
}
@media (min-width: 768px) {
  .instruments-widget-tabs {
    flex-direction: column;
    flex: 0 1 15%;
    max-width: 15%;
  }
}
.instruments-widget-tabs:before {
  position: absolute;
  background-color: #ffffff;
  width: calc(100% + 1.5rem);
  height: 1.75rem;
  left: 0;
  right: 0;
  top: 100%;
  content: "";
  margin: auto;
  border-radius: 0 1.5rem 0 0;
  z-index: 16;
}
@media (min-width: 768px) {
  .instruments-widget-tabs:before {
    width: 1.375rem;
    height: 100%;
    top: 0;
    left: 98%;
    bottom: 0;
    border-radius: 0 0 0 1.5rem;
  }
}
@media (min-width: 1280px) {
  .instruments-widget-tabs:before {
    width: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .instruments-widget-tabs.categories-4 button {
    margin-left: -2px;
  }
  .instruments-widget-tabs.categories-5 button {
    margin-left: -3px;
  }
  .instruments-widget-tabs.categories-6 button {
    margin-left: -4px;
  }
  .instruments-widget-tabs.categories-7 button {
    margin-left: -5px;
  }
  .instruments-widget-tabs.categories-8 button {
    margin-left: -6px;
  }
  .instruments-widget-tabs.categories-9 button {
    margin-left: -7px;
  }
}
.instruments-widget-tabs button {
  position: relative;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 -3px 0;
  border-radius: 0.75rem 0.75rem 0 0;
  box-shadow: 0 0.875rem 3rem 0 rgba(24, 74, 181, 0.5);
  width: 3rem;
  height: 2.25rem;
  transition: background-color 0.5s ease-in-out;
}
@media (min-width: 480px) {
  .instruments-widget-tabs button {
    width: 3.25rem;
    height: 2.75rem;
  }
}
@media (min-width: 768px) {
  .instruments-widget-tabs button {
    height: 3rem;
    margin: -0.5rem 0 0 0;
    border-radius: 0.75rem 0 0 0.75rem;
    box-shadow: -0.875rem -0.5rem 3rem 0 rgba(24, 74, 181, 0.5);
  }
  .inner .instruments-widget-tabs button {
    box-shadow: 0 0 2rem 0 rgba(24, 74, 181, 0.25);
  }
}
.instruments-widget-tabs button:hover, .instruments-widget-tabs button:focus {
  cursor: pointer;
  background-color: #ffffff;
}
.instruments-widget-tabs button:first-child {
  z-index: 1;
  margin-left: 0;
}
@media (min-width: 768px) {
  .instruments-widget-tabs button:first-child {
    margin-top: 0;
  }
}
.instruments-widget-tabs button:nth-last-child(1) {
  z-index: 1;
}
.instruments-widget-tabs button:nth-last-child(2) {
  z-index: 2;
}
.instruments-widget-tabs button:nth-last-child(3) {
  z-index: 3;
}
.instruments-widget-tabs button:nth-last-child(4) {
  z-index: 4;
}
.instruments-widget-tabs button:nth-last-child(5) {
  z-index: 5;
}
.instruments-widget-tabs button:nth-last-child(6) {
  z-index: 6;
}
.instruments-widget-tabs button:nth-last-child(7) {
  z-index: 7;
}
.instruments-widget-tabs button:nth-last-child(8) {
  z-index: 8;
}
.instruments-widget-tabs button:nth-last-child(9) {
  z-index: 9;
}
.instruments-widget-tabs button.selected {
  background-color: #ffffff;
  box-shadow: 0 0 0.625rem 0.4375rem rgba(24, 74, 181, 0.15);
  z-index: 13;
  width: 3.125rem;
  height: 3rem;
}
@media (min-width: 480px) {
  .instruments-widget-tabs button.selected {
    width: 3.5rem;
    height: 3.5rem;
  }
}
@media (min-width: 768px) {
  .instruments-widget-tabs button.selected {
    width: 4.25rem;
    box-shadow: 0 0 0.625rem 0 rgba(24, 74, 181, 0.25);
  }
}
@media (min-width: 768px) {
  .instruments-widget-tabs button.selected img {
    margin-right: -0.5rem;
  }
}
.instruments-widget-tabs button img {
  width: 1.25rem;
  height: 1.25rem;
}
@media (min-width: 480px) {
  .instruments-widget-tabs button img {
    width: 1.375rem;
    height: 1.375rem;
  }
}
@media (min-width: 768px) {
  .instruments-widget-tabs button img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 1024px) {
  .instruments-widget-tabs button img {
    margin-right: 0.5rem;
  }
}
.instruments-widget-tabs button .tab-icon {
  color: #0c2780;
}
@media (min-width: 768px) {
  .instruments-widget-tabs button .tab-icon {
    font-size: 1.25rem;
  }
}
.instruments-widget-tabs button.selected .tab-icon {
  font-size: 1.25rem;
  color: #136DE8;
}
@media (min-width: 768px) {
  .instruments-widget-tabs button.selected .tab-icon {
    font-size: 1.5rem;
  }
}
.instruments-widget-items {
  flex: 1 0 100%;
  padding: 0.5rem 0.5rem 0;
  background-color: #ffffff;
  border-radius: 0 0 1.5rem 1.5rem;
  flex: 1 0 100%;
  box-shadow: 0 1.75rem 3.5rem rgba(24, 74, 181, 0.34);
  min-height: 31rem;
  z-index: 11;
}
@media (min-width: 768px) {
  .instruments-widget-items {
    border-radius: 0 1.5rem 1.5rem 1.5rem;
    padding: 1.5rem 1.5rem 0;
    min-height: 30rem;
  }
}

.feeds-widget-disclaimer {
  text-align: center;
  margin: 2rem 0;
  padding: 0 0.75rem;
}

.trustpilot-section {
  position: relative;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .trustpilot-section {
    margin-bottom: 5rem;
  }
}
.trustpilot-wrap {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}
.trustpilot-bg {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 14rem;
  padding-bottom: 0;
  /*! rtl:ignore */background-position: 35% 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.trustpilot-bg.lazyloaded {
  background-image: url("../Images/newhome/img/iPhone-transperent.png");
}
.futureshome .trustpilot-bg.lazyloaded {
  background-image: url("../../images/futures/img/iPhone-Trustpilot.png");
}
.futureshome.lang-es .trustpilot-bg.lazyloaded {
  background-image: url("../../images/futures/img/iPhone-Trustpilot-es.png");
}
.country-id .trustpilot-bg.lazyloaded {
  background-image: url("../images/newhome/img/iPhone-transparent-id.png"); /*! rtl:ignore */
}
@media (min-width: 768px) {
  .trustpilot-bg {
    padding-top: 26%;
    padding-bottom: 2rem;
    background-size: contain;
  }
}
.trustpilot-bg:after {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, white);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 0;
}
.trustpilot .plus-bg {
  z-index: 1;
}
.trustpilot .plus-bg-small {
  width: 5.625rem;
  height: 5.625rem;
  top: 12%;
  right: -2.5rem;
  opacity: 0.4;
}
@media (min-width: 768px) {
  .trustpilot .plus-bg-small {
    top: 55%;
    right: 25%;
  }
}
.trustpilot .plus-bg-large {
  display: none;
  width: 25rem;
  height: 25rem;
  top: -8%;
  left: 6%;
}
@media (min-width: 768px) {
  .trustpilot .plus-bg-large {
    display: block;
  }
}
.trustpilot-widget {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 17.25rem;
  margin: auto auto 0;
  /*! rtl:ignore */direction: ltr;
  z-index: 1;
}
@media (min-width: 768px) {
  .trustpilot-widget {
    width: 20rem;
  }
}
.trustpilot-logo {
  color: #000;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-around;
  line-height: 1;
}
@media (min-width: 768px) {
  .trustpilot-logo {
    flex-wrap: nowrap;
  }
}
.trustpilot-logo svg {
  height: 3.25rem;
  width: 3.25rem;
}
.trustpilot-logo strong {
  font-size: 2.5rem;
  font-weight: 500;
}
@media (min-width: 768px) {
  .trustpilot-logo strong {
    font-size: 2.75rem;
  }
}
.trustpilot-logo span {
  font-size: 1.5rem;
  font-weight: 300;
  margin-left: 1rem 0.5;
}
.trustpilot .score-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0 1rem;
}
.trustpilot .reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  direction: ltr;
}
.trustpilot .reviews .title {
  font-size: 1.125rem;
  display: flex;
  margin-bottom: 0;
}
.trustpilot .reviews .amount {
  margin-left: 0.25rem;
}
.trustpilot .reviews .trust-score figure {
  margin: 0;
  display: flex;
}
.trustpilot .reviews .trust-score figure img {
  height: 2rem;
}
.trustpilot .reviews .trust-score svg {
  height: auto;
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
.trustpilot .reviews .trust-score svg .rect {
  fill: transparent;
}
.trustpilot .reviews .quality {
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.25rem auto 0;
}
@media (min-width: 768px) {
  .trustpilot .reviews .quality {
    font-size: 1.75rem;
  }
}
.trustpilot .score {
  color: #000;
  font-size: 4rem;
  font-weight: 300;
  /*! rtl:ignore */margin-left: 1rem;
}

.why-us-section {
  margin-bottom: 7rem;
}
@media (min-width: 768px) {
  .why-us-section {
    margin-bottom: 15rem;
  }
}
.why-us-section .plus-bg-large {
  top: 96%;
  left: 8rem;
  width: 10.5rem;
  height: 10.5rem;
}
@media (min-width: 768px) {
  .why-us-section .plus-bg-large {
    width: 15.375rem;
    height: 15.375rem;
    top: 80%;
    left: -10rem;
  }
}
.why-us-section .item-title {
  color: #ffffff;
}
@media (min-width: 1024px) {
  .why-us-section .item-content {
    flex: 0 1 70%;
  }
}
.why-us-items {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1024px) {
  .why-us-items {
    padding-left: var(--bs-gutter-x, 1rem);
    padding-right: var(--bs-gutter-x, 1rem);
  }
}

.payment-section .disclaimer-zone {
  position: relative;
  color: #555555;
  text-align: center;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .payment-section .disclaimer-zone {
    font-size: 1rem;
  }
}
.payment-brands {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .payment-brands {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
.payment-brand {
  flex: 0 0 auto;
  width: auto;
}
@media (min-width: 768px) {
  .payment-brand {
    margin: 0 2%;
  }
}
.payment-brand-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-brand-img {
  position: relative;
  margin-bottom: 0;
}
.payment-brand-img img, .payment-brand-img svg {
  display: block;
  margin: 0.5rem auto;
  width: auto;
  height: 1.75rem;
}
@media (min-width: 768px) {
  .payment-brand-img img, .payment-brand-img svg {
    height: 3.5rem;
  }
}

.support-link {
  align-items: center;
  display: inline-flex;
  text-align: center;
  color: #ffffff;
}
.support-link .icon-users {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.25rem;
}

.side-support-link {
  bottom: 100%;
  right: 0.5rem;
  position: absolute;
  margin-bottom: 0.5rem;
  transition-timing-function: ease-in;
  transition-delay: 0s;
  transition-duration: 0.5s;
  height: 3.5rem;
  width: 3.5rem;
  background-color: #2e86fe;
  border-radius: 0.75rem;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  text-align: center;
  z-index: 3;
  text-decoration: none;
}
.lang-bg .side-support-link, .lang-da .side-support-link, .lang-el .side-support-link, .lang-no .side-support-link, .lang-ru .side-support-link, .lang-sk .side-support-link, .lang-sl .side-support-link, .lang-sv .side-support-link {
  height: 4rem;
  width: 4.25rem;
}
.lang-hu .side-support-link, .lang-nl .side-support-link, .lang-et .side-support-link {
  height: 4rem;
  width: 5rem;
}
@media (min-width: 768px) {
  .side-support-link {
    transition-duration: 0.75s;
  }
}
.side-support-link .text {
  font-size: 0.625rem;
  line-height: 1.2;
}

.support-section {
  background-color: #2e86fe;
  padding: 2rem 0;
}
.support-section .title {
  color: #ffffff;
  font-weight: 300;
  margin: 0 0 1rem 0;
  text-align: center;
}
@media (min-width: 768px) {
  .support-section .title {
    font-size: 2rem;
    margin: 0 1rem;
  }
}
.support-section .support-link {
  border: 1px solid #ffffff;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  justify-content: center;
  line-height: 1.3;
  padding: 0.875rem 1rem;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
}
@media (min-width: 768px) {
  .support-section .support-link {
    font-size: 1rem;
    max-width: 11rem;
  }
}
.support-section .support-link .icon-users {
  margin-left: 0.5rem;
}
.support-section .support-link:hover, .support-section .support-link:focus {
  background: #ffffff;
  color: #2e86fe;
}

.main-footer {
  background-color: #07183d;
  color: #ffffff;
  padding: 5rem 0;
}
@media (min-width: 1024px) {
  .main-footer {
    padding: 7rem 0;
  }
}
.main-footer .row {
  max-width: 90rem;
}
.main-footer p, .main-footer span {
  font-weight: 400;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .main-footer p, .main-footer span {
    font-size: 1rem;
  }
}
.main-footer .links-menu {
  margin-bottom: 3rem;
  padding: 0;
}
@media (min-width: 1024px) {
  .main-footer .links-menu {
    margin-bottom: 0;
  }
}
.main-footer .links-menu li {
  align-items: flex-start;
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1rem;
  position: relative;
  margin-bottom: 2.25rem;
  flex: 1 0 100%;
}
@media (min-width: 768px) {
  .main-footer .links-menu li {
    flex: 0 1 50%;
  }
}
@media (min-width: 1024px) {
  .main-footer .links-menu li {
    margin-bottom: 1rem;
    flex: 1 0 100%;
    padding-right: 0;
  }
}
.main-footer .links-menu a {
  color: #ffffff;
  padding: 0;
  line-height: 1.3;
}
.main-footer .main-column {
  margin-bottom: 2rem;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .main-footer .main-column {
    font-size: 1rem;
    margin-bottom: 0;
    padding: 0 1.5rem;
  }
}
@media (min-width: 1280px) {
  .main-footer .main-column {
    padding: 0 2rem;
  }
}
.main-footer .social-networks {
  text-align: center;
}
@media (min-width: 1024px) {
  .main-footer .social-networks {
    text-align: left;
  }
}
.main-footer .social-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  font-weight: 400;
  justify-content: center;
}
@media (min-width: 1024px) {
  .main-footer .social-menu {
    justify-content: flex-start;
  }
}
.main-footer .social-menu li {
  margin-right: 1.25rem;
}
@media (min-width: 1024px) {
  .main-footer .social-menu li {
    margin-right: 1rem;
  }
}
.main-footer .social-menu li:last-child {
  margin-right: 0;
}
.main-footer .social-menu li a {
  padding: 0;
  display: block;
  line-height: 1;
  font-size: 0.9375rem;
}
.country-id .main-footer .social-menu li a {
  pointer-events: none;
}
.country-id .main-footer .social-menu li a:focus-visible {
  box-shadow: none !important;
}
.main-footer .social-menu img {
  width: 1.875em;
  height: auto;
}
.main-footer .social-menu .social-icon-linkedin {
  width: 2em;
}
.main-footer .social-menu .social-icon-youtube {
  width: 2.25em;
}
.main-footer .sg-awareness-link:active, .main-footer .sg-awareness-link:hover {
  text-decoration: underline;
}

.footer-item.secured {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
  text-align: left;
}
.footer-item.secured img {
  margin-right: 0.5rem;
}
.footer-item.secured p {
  margin-bottom: 0;
  line-height: 1.2;
}
.footer-text {
  line-height: 1.3;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .footer-text {
    font-size: 1rem;
  }
}
.footer-text a {
  color: #ffffff;
}
.footer-contact {
  font-size: 1.125rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .footer-contact {
    text-align: center;
    font-size: 1.25rem;
  }
}
.footer-contact a {
  color: #ffffff;
  text-decoration: underline;
}
.footer-contact a:hover, .footer-contact a:focus {
  text-decoration: none;
}

.portuguese-risk-warning {
  margin-bottom: 1rem;
}
.portuguese-risk-warning .warning-content {
  border: 3px solid #ffffff;
  padding: 0.5rem 0.75rem;
}
@media (min-width: 1024px) {
  .portuguese-risk-warning .warning-content {
    border-width: 4px;
  }
}
.portuguese-risk-warning p, .portuguese-risk-warning span {
  color: #ffffff;
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0;
}
.portuguese-risk-warning strong {
  color: #ffffff;
  font-weight: 800;
  text-align: center;
}
.portuguese-risk-warning img {
  display: block;
  margin: 0 auto;
}

.sticky-footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1020;
}
@supports (position: sticky) {
  .sticky-footer-wrapper {
    position: sticky;
  }
  .edge .sticky-footer-wrapper {
    position: fixed;
  }
}
@media (hover: none) and (pointer: coarse) {
  .sticky-footer-wrapper {
    position: fixed;
  }
}
.sticky-footer-wrapper .main-row {
  max-width: 90rem;
}
.sticky-footer-wrapper .rw-container {
  background-color: #ececec;
  padding: 0.25rem 0;
  border-top: 1px solid #ccc;
  position: relative;
  z-index: 2;
}
.sticky-footer-wrapper .rw-container p {
  color: #555555;
  font-size: 0.75rem;
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.15;
}
@media (min-width: 768px) {
  .sticky-footer-wrapper .rw-container p {
    font-size: 0.9375rem;
  }
}
.sticky-footer-wrapper .rw-container.blocked {
  background-color: #07183d;
}
.sticky-footer-wrapper .rw-container.blocked p {
  color: #ffffff;
}
.sticky-footer-wrapper .rw-container.blocked p * {
  color: #ffffff;
}
.sticky-footer-wrapper .rw-toggle {
  display: none;
}
@media (min-width: 768px) {
  .sticky-footer-wrapper .rw-short {
    display: none;
  }
}
.sticky-footer-wrapper .rw-long {
  display: none;
}
@media (min-width: 768px) {
  .sticky-footer-wrapper .rw-long {
    display: block;
  }
}

.sticky-cookie-wrapper {
  background: #ffffff;
  box-shadow: 0 -0.5rem 2rem 0 rgba(12, 39, 128, 0.1);
  max-height: 20rem;
  position: relative;
  overflow-y: hidden;
  transition: max-height 0.75s ease-out;
  z-index: 2;
}
.sticky-cookie-wrapper.cookie-out {
  max-height: 0;
}
.sticky-cookie-wrapper .image-wrapper {
  text-align: center;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .image-wrapper {
    margin-bottom: 0;
    width: 3rem;
    flex: 0 1 auto;
  }
}
.sticky-cookie-wrapper .image-wrapper img {
  display: inline-block;
  margin: 0 auto;
  max-width: 3rem;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .cookie-banner-wrapper, .sticky-cookie-wrapper .cookie-banner-content {
    display: flex;
  }
}
.sticky-cookie-wrapper .cookie-banner-wrapper {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .cookie-banner-content {
    flex: 1 1 auto;
    align-items: center;
  }
}
.sticky-cookie-wrapper p {
  color: #3e3e3e;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1.5rem;
  font-size: 0.8125rem;
  line-height: 1.3;
  font-weight: 400;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper p {
    font-size: 0.9375rem;
  }
}
.sticky-cookie-wrapper p a {
  color: #ffffff;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper p a {
    white-space: nowrap;
  }
}
.sticky-cookie-wrapper p a:hover, .sticky-cookie-wrapper p a:focus {
  text-decoration: none;
}
.sticky-cookie-wrapper .actions-wrapper {
  display: flex;
  justify-content: center;
  flex: 0 1 auto;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .actions-wrapper {
    margin-left: auto;
  }
}
.sticky-cookie-wrapper .button {
  font-size: 1rem;
  transition: none;
}
@media (min-width: 768px) {
  .sticky-cookie-wrapper .button {
    font-size: 1.125rem;
    margin-top: 0;
    height: 3rem;
    width: auto;
    min-width: 10rem;
  }
}
.sticky-cookie-wrapper .button.accept-cookies {
  margin-left: 1rem;
  border: 1px transparent solid;
}
.sticky-cookie-wrapper .button.accept-cookies:hover, .sticky-cookie-wrapper .button.accept-cookies:focus {
  background: #ffffff;
  color: #2e86fe;
  border-color: #2e86fe;
  border-width: 2px;
  cursor: pointer;
}
.sticky-cookie-wrapper .button.accept-cookies:only-child {
  margin-left: auto;
  margin-right: auto;
}
.sticky-cookie-wrapper.dark {
  background-color: #3e3e3e;
}
.sticky-cookie-wrapper.dark p {
  color: #ffffff;
}

.card {
  --bs-card-border-color:transparent;
  --bs-border-radius:calc(1.5rem / 2);
  --bs-card-title-color:#0c2780;
  --bs-card-spacer-y:1rem;
  --bs-card-border-width:0;
  --bs-card-cap-bg:transparent;
  --bs-card-cap-color:#0c2780;
  --bs-link-color-rgb:#0c2780;
  --bs-card-cap-padding-y:1rem;
  --bs-card-box-shadow:0 1rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}
.card-item {
  margin-bottom: 2rem;
}
.card-item-inner {
  margin-bottom: 2rem;
}
.card-img {
  position: relative;
  margin: 0;
  max-height: 20rem;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.card-img-bg:after {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(12, 39, 128, 0) 40%, #0c2780);
  width: 100%;
  height: 100%;
  content: "";
}
.card-img-bg .meta {
  position: absolute;
  z-index: 2;
  top: auto;
  left: 1rem;
  right: 1rem;
  bottom: 0.5rem;
  height: auto;
  width: auto;
  color: #ffffff;
  font-weight: 600;
}
@media (min-width: 768px) {
  .card-img-bg .meta {
    font-size: 1.25rem;
  }
}
.card-img-bg .meta .date {
  font-weight: 300;
  font-size: 0.875rem;
}
.card-img-bg-color {
  background-color: #0c2780;
}
.card-img-bg-color img {
  object-fit: contain;
}
.card-img img {
  object-fit: cover;
}
.card-title {
  font-weight: 400;
}
@media (min-width: 768px) {
  .card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.card-link-text {
  display: inline-flex;
  color: #0c2780;
  font-size: 1.125rem;
}
.card-link-text .icon {
  margin-left: 0.5rem;
}
.lang-he .card-link-text .icon, .lang-ar .card-link-text .icon {
  transform: scaleX(-1);
}
.card-link:hover .card-link-text span, .card-link:focus .card-link-text span {
  text-decoration: underline;
}
.card-video:hover .card-video-img:after, .card-video:focus .card-video-img:after {
  background-color: #11afff;
  outline: 0.625rem solid #11afff;
}
.card-video:hover .card-video-img img, .card-video:focus .card-video-img img {
  opacity: 0.9;
}
.card-video-img:after {
  position: absolute;
  content: "\ed7e";
  font-family: "icomain";
  top: 50%;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #2e86fe;
  color: #ffffff;
  font-size: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  margin: -1.75rem auto 0;
  border-radius: 50%;
  outline: 0.625rem solid #2e86fe;
  outline-offset: -0.625rem;
  transition: transform 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .card-video-img:after {
    font-size: 3.75rem;
    width: 4rem;
    height: 4rem;
    margin-top: -2rem;
  }
}

.awards-section {
  margin-bottom: 4rem;
}
.awards-section .card {
  --bs-card-bg:none;
  --bs-border-radius:0;
  --bs-card-box-shadow:none;
  --bs-card-color:#0c2780;
}
.awards-section .card-body {
  --bs-card-spacer-x:0;
}
.awards-section .card strong, .awards-section .card span {
  font-weight: 700;
}
.awards-section .card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 5rem;
  font-size: 1.25rem;
  font-size: clamp(1rem, 2vw, 1.25rem);
  position: relative;
  font-weight: 400;
}
@media (min-width: 1400px) {
  .awards-section .card-title {
    min-height: 12rem;
  }
}
.awards-section .card-title:before {
  background-image: url("../Images/uae/spikelet-left.svg"), url("../Images/uae/spikelet-right.svg");
  background-repeat: no-repeat;
  /*! rtl:ignore */background-position: calc(50% - 7.5rem) 50%, calc(50% + 7.5rem) 50%;
  background-size: auto 8.125rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  content: "";
}
.awards-section .card-title strong {
  font-size: 2.75rem;
  font-size: clamp(2.5rem, 2vw, 2.75rem);
  line-height: 2.75rem;
  line-height: clamp(2.5rem, 2vw, 2.75rem);
}
.awards-section .card-text {
  max-width: 21.25rem;
  margin: 0.625rem auto 0;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.125rem;
}
.awards-section .card-text strong {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.regulation {
  background-color: #F3F4F9;
  margin-bottom: 2.5rem;
}
@media (max-width: 767.98px) {
  .regulation {
    padding: 0 4%;
  }
}
@media (min-width: 768px) {
  .regulation {
    margin-bottom: 6rem;
  }
}
.regulation-brands {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #555555;
  font-size: 0.75rem;
  text-align: center;
  gap: 1.5rem;
}
@media (min-width: 768px) {
  .regulation-brands {
    padding-top: 2.5rem;
    gap: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .regulation-brands {
    gap: 4rem;
  }
}
.regulation-brand {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 768px) {
  .regulation-brand {
    gap: 1rem;
  }
}
.regulation-brand-img {
  margin: auto 0;
}
.regulation-brand-title {
  align-items: center;
  min-height: 2rem;
}
.regulation-brand-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 65%;
  padding-top: 0.5rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .regulation-brand-content {
    font-size: 1.125rem;
  }
}

.trade-trust {
  position: relative;
  font-weight: 300;
  font-size: 2rem;
  color: #946F00;
}
@media (min-width: 768px) {
  .trade-trust {
    font-size: 4rem;
  }
}
.trade-trust .plus-bg {
  display: none;
  z-index: 0;
}
@media (min-width: 1600px) {
  .trade-trust .plus-bg-medium {
    display: block;
    z-index: -1;
    height: 22rem;
    width: 22rem;
    top: 0;
    left: -6%;
  }
}
@media (min-width: 1024px) {
  .trade-trust .plus-bg-large {
    display: block;
    height: 22rem;
    width: 22rem;
    top: 60%;
    right: -8%;
  }
}
.trade-trust-section {
  text-align: center;
}
.trade-trust-item {
  height: 100%;
  padding: 1.5rem 0.5rem;
  background-color: #ffffff;
  border: solid 0.0625rem rgba(148, 111, 0, 0.95);
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .trade-trust-item {
    padding: 2rem 1rem;
  }
}
.trade-trust-item strong {
  display: block;
  color: #0c2780;
  font-size: 1rem;
  margin-top: 0.5rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .trade-trust-item strong {
    font-size: 1.5rem;
  }
}
.trade-trust-title {
  font-size: 1rem;
  color: #0c2780;
  margin-top: 2rem;
  padding: 1.5rem 10%;
  font-weight: 400;
  background-color: #F3F4F9;
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .trade-trust-title {
    margin-top: 3.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-size: 2rem;
  }
}
@media (min-width: 1400px) {
  .trade-trust-title {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.trade-trust-foot-note {
  font-size: 1rem;
  color: #555555;
}

.confidence {
  position: relative;
}
.confidence .plus-bg {
  display: none;
  z-index: 0;
}
@media (min-width: 1024px) {
  .confidence .plus-bg-large {
    display: block;
    height: 22rem;
    width: 22rem;
    top: 80%;
    left: -6%;
  }
}

.cards-section {
  margin-bottom: 10.5rem;
}
.cards-section .gs-card-anim {
  opacity: 0;
}
@media (min-width: 768px) {
  .cards-section .cards-bg-image {
    position: absolute;
    z-index: 0;
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
    border-radius: 1rem;
    background-image: url("../images/uae/cards-bg-gr.webp");
    background-repeat: no-repeat;
    background-size: 40% auto;
    /*! rtl:ignore */background-position: right top;
  }
  .safari .cards-section .cards-bg-image, .edge .cards-section .cards-bg-image, .ff-old .cards-section .cards-bg-image {
    background-image: url("../images/uae/cards-bg-gr.jpg");
  }
}
.cards-section .cards-data {
  position: relative;
}
.cards-section .cards-header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .cards-section .cards-header {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
}
@media (min-width: 1280px) {
  .cards-section .cards-body {
    max-width: 90%;
    margin: 0 auto;
  }
}
.cards-section .cards-list {
  margin-bottom: -10.5rem;
}
.cards-section .card-icon {
  max-height: 2.8125rem;
  max-width: 2.8125rem;
  display: block;
  margin: 0 auto 1.5rem;
}
@media (min-width: 768px) {
  .cards-section .card-icon {
    max-height: 3.75rem;
    max-width: 3.75rem;
    margin-bottom: 2rem;
  }
}
.cards-section .card-title {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .cards-section .card-title {
    min-height: 3.4rem;
  }
}
.cards-section .card-text {
  margin-bottom: 2rem;
}

.payment-methods {
  position: relative;
}
.payment-methods .plus-bg {
  display: none;
  z-index: 0;
}
@media (max-width: 767.98px) {
  .payment-methods .plus-bg-medium {
    display: block;
    width: 10rem;
    height: 10rem;
    top: 1rem;
    left: -8%;
  }
}
@media (min-width: 768px) {
  .payment-methods .plus-bg-large {
    display: block;
    height: 22rem;
    width: 22rem;
    top: 50%;
    right: -6%;
  }
}
.payment-methods-title {
  color: #0c2780;
}
.payment-methods-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.payment-methods-content .images {
  margin-bottom: 1rem;
  display: flex;
}
.payment-methods-content .images img {
  margin: 0 0.5rem;
  max-width: 4.375rem;
  height: auto;
}
.payment-methods-content .title {
  background: linear-gradient(90deg, #EDB762 35%, #f4d4a1 55%, #EDB762 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.125rem;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .payment-methods-content .title {
    padding: 0 1rem;
  }
}

@media (max-width: 767.98px) {
  .payment-brands {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .payment-brands {
    margin-top: 1.5rem;
  }
}

.payment-brand {
  margin: 0 1%;
}
.payment-brand-list {
  flex: 0 0 100%;
}
@media (min-width: 1400px) {
  .payment-brand-list {
    flex: 0 0 80%;
  }
}
@media (max-width: 767.98px) {
  .payment-brand-list {
    padding: 0 1rem;
  }
}
.payment-brand-img img {
  height: 2.5rem;
}
@media (min-width: 1400px) {
  .payment-brand-img img {
    height: 2.75rem;
  }
}

.features-card-border {
  position: absolute;
  top: 8rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
@media (min-width: 1024px) {
  .features-card-border {
    bottom: 8rem;
  }
  .features-card-border.blue:before {
    background-image: linear-gradient(#0c2780, #0c2780), linear-gradient(-90deg, transparent, transparent, #EEB966, #EEB966);
  }
}
.features-slider.slick-initialized {
  width: 100%;
  opacity: 1;
  max-height: none;
}
.features-slider.slick-initialized + .features-slider-navigation {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .features-slider.slick-initialized {
    height: 100%;
  }
  .features-slider.slick-initialized.slick-slider, .features-slider.slick-initialized .slick-list, .features-slider.slick-initialized .slick-track {
    height: 100%;
  }
}
.features-slider-navigation {
  width: 100%;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 2.25rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 1024px) {
  .features-slider-navigation {
    width: 12.5rem;
    bottom: 10rem;
    left: 14%;
    right: auto;
  }
}
.features-slider-navigation button {
  background: none;
  border: none;
}
.features-slider-navigation .slick-arrow {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.features-slider-navigation .slick-arrow.slick-prev, .features-slider-navigation .slick-arrow.slick-next {
  width: 2rem;
  top: -18rem;
}
@media (min-width: 1024px) {
  .features-slider-navigation .slick-arrow.slick-prev, .features-slider-navigation .slick-arrow.slick-next {
    top: 50%;
  }
}
.features-slider-navigation .slick-arrow.slick-prev:before, .features-slider-navigation .slick-arrow.slick-next:before {
  font-size: 1rem;
  color: #ffffff;
  width: 2rem;
}
.features-slider-navigation .slick-arrow.slick-prev {
  left: 1rem;
}
@media (min-width: 1024px) {
  .features-slider-navigation .slick-arrow.slick-prev {
    left: 0;
  }
}
.features-slider-navigation .slick-arrow.slick-next {
  right: 1rem;
}
@media (min-width: 1024px) {
  .features-slider-navigation .slick-arrow.slick-next {
    right: 0;
  }
}
.features-slider-navigation .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  width: auto;
  left: 2rem;
  right: 2rem;
}
.features-slider-navigation .slick-dots li {
  height: auto;
}
.features-slider-navigation .slick-dots li button {
  background: transparent;
  border: 0 none;
  border-radius: 50%;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.features-slider-navigation .slick-dots li button:before {
  border-radius: 50%;
  opacity: 1;
  z-index: 1;
  background-color: #ffffff;
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.25rem;
}
.features-slider-navigation .slick-dots li button svg {
  opacity: 0;
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #EEB966;
  stroke-width: 0.25rem;
  stroke-dasharray: 0 100;
  transform: rotate(-90deg);
}
.features-slider-navigation .slick-dots li.slick-active button:before {
  background-color: #EEB966;
}
.features-slider-navigation .slick-dots li.slick-active button svg {
  opacity: 1;
  animation-name: slick-dots-anim;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.features-slider-container {
  position: relative;
}
.features-slider-container .plus-bg {
  display: none;
  z-index: 0;
}
@media (min-width: 1400px) {
  .features-slider-container .plus-bg-large {
    display: block;
    height: 22rem;
    width: 22rem;
    top: 40%;
    left: -14%;
  }
}
.features-slide {
  display: none;
}
.features-slide-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1024px) {
  .features-slide-data {
    min-height: 50rem;
    flex-direction: row-reverse;
  }
}
.features-slide-animation {
  position: relative;
  z-index: 0;
  margin: 0 auto;
  width: 85%;
}
@media (max-width: 1023.98px) {
  .features-slide-animation {
    aspect-ratio: 10/14;
  }
  .features-slide-animation:before {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70%;
    background: linear-gradient(to bottom, rgba(12, 39, 128, 0) 15%, #0c2780 85%);
    content: "";
  }
}
@media (min-width: 768px) {
  .features-slide-animation {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .features-slide-animation {
    margin-right: 0;
  }
}
.features-slide-content {
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
  margin-top: -15rem;
  padding: 8rem 2.5rem 4rem;
  color: #ffffff;
}
@media (min-width: 768px) {
  .features-slide-content {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (min-width: 1024px) {
  .features-slide-content {
    flex: 0 0 45%;
    margin-top: 0;
    padding: 10rem 2rem 14rem 4rem;
  }
}
.features-slide-content .slide-icon {
  display: flex;
  justify-content: center;
  height: 3rem;
}
@media (min-width: 768px) {
  .features-slide-content .slide-icon {
    height: 4.375rem;
  }
}
.features-slide-content .slide-icon img {
  margin: 0 auto;
  max-width: 4.375rem;
  max-height: 4.375rem;
}
.features-slide-content .slide-title {
  background: linear-gradient(90deg, #EDB762 35%, #f4d4a1 55%, #EDB762 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1.5rem auto;
  font-weight: 500;
}

@keyframes slick-dots-anim {
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: 100 100;
  }
}
.global-markets {
  margin-top: 4rem;
  position: relative;
  border-top: solid 0.25rem #eaa944;
}
@media (max-width: 1023.98px) {
  .global-markets {
    padding-top: 4rem;
  }
}
@media (max-width: 767.98px) {
  .global-markets.blue-section {
    background: linear-gradient(-180deg, #0c2780 0, #0c2780 60%, #ffffff 100%);
  }
}
.global-markets.lazyloaded:before, .global-markets.lazyloaded:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  content: "";
}
.lang-he .global-markets.lazyloaded:before, .lang-ar .global-markets.lazyloaded:before, .lang-he .global-markets.lazyloaded:after, .lang-ar .global-markets.lazyloaded:after {
  transform: scaleX(-1);
}
@media (min-width: 768px) {
  .global-markets.lazyloaded:before {
    background-image: url("../images/uae/global-markets-bg.webp");
    background-size: auto 100%;
    /*! rtl:ignore */background-position: left 0;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .global-markets.lazyloaded:before {
    /*! rtl:ignore */background-position: left -20vw top 0;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .global-markets.lazyloaded:before {
    opacity: 0.4;
  }
}
@media (min-width: 1600px) {
  .global-markets.lazyloaded:after {
    background-image: url("../images/uae/global-markets-person.webp");
    background-size: auto 84%;
    /*! rtl:ignore */background-position: left -20rem bottom;
  }
}
.global-markets .plus-bg {
  display: none;
}
@media (min-width: 768px) {
  .global-markets .plus-bg-medium {
    display: block;
    width: 14.375rem;
    height: 14.375rem;
    top: 100%;
    left: 2%;
  }
}
@media (min-width: 768px) {
  .global-markets .plus-bg-large {
    display: block;
    height: 22rem;
    width: 22rem;
    top: 90%;
    left: -4%;
    opacity: 0.3;
  }
}
.global-markets-image {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
}
.lang-he .global-markets-image, .lang-ar .global-markets-image {
  transform: scaleX(-1);
}
.global-markets-content {
  position: relative;
  z-index: 1;
  padding-bottom: 2rem;
}
@media (min-width: 1024px) {
  .global-markets-content {
    padding-right: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .global-markets-content {
    padding-bottom: 6rem;
  }
}
.global-markets-widget {
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .global-markets-widget:before, .global-markets-widget:after {
    position: absolute;
    z-index: 0;
    content: "";
    top: -2.5rem;
    bottom: -2.5rem;
    left: 30%;
    right: -2.5rem;
  }
  .global-markets-widget:before {
    border-radius: 1.125rem;
    border: 2px solid #EDB762;
  }
  .global-markets-widget:after {
    right: -3.125rem;
    border-image: radial-gradient(ellipse closest-side at 90%, #ffffff 0, #EDB762 30%, transparent 100%) 0 20% 0 0/0 5% 60% 0;
  }
  .lang-he .global-markets-widget:after, .lang-ar .global-markets-widget:after {
    transform: scaleX(-1);
  }
}
.global-markets .feed-table .instruments-widget-items {
  min-height: 32rem;
}
@media (min-width: 1024px) {
  .global-markets .feed-table {
    max-width: 34rem;
  }
  .global-markets .feed-table table tr td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 1279.98px) {
  .global-markets .feed-table table tr th {
    font-size: 1rem;
  }
  .global-markets .feed-table table tr td, .global-markets .feed-table table tr td .button {
    font-size: 0.9375rem;
  }
}

.card {
  --bs-card-cap-padding-y: 2rem;
  --bs-card-cap-padding-x: 2rem;
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
}
.card-item {
  margin-bottom: 1.5rem;
}
.card-title {
  font-weight: 600;
}
@media (min-width: 768px) {
  .card-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (min-width: 768px) {
  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.card-footer {
  padding-top: 0;
}
.card .btn {
  margin-top: 0;
}

.trustpilot .plus-bg {
  display: none;
  z-index: -1;
}
.trustpilot .plus-bg-large {
  height: 22rem;
  width: 22rem;
}
@media (min-width: 768px) {
  .trustpilot .plus-bg-large {
    display: block;
    top: -20%;
    left: auto;
    right: -8%;
  }
}
@media (min-width: 1280px) {
  .trustpilot .plus-bg-large {
    top: -40%;
  }
}
.trustpilot-section {
  margin-bottom: 2.5rem;
}
.trustpilot-title-card {
  margin-top: 12rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .trustpilot-title-card {
    margin-top: 6rem;
  }
}
.trustpilot-title-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trustpilot-title-data .title-section {
  position: relative;
  z-index: 1;
  padding: 2rem 1rem;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .trustpilot-title-data .title-section {
    width: 65%;
    margin-left: 0;
    text-align: left;
  }
}
.trustpilot-title-img {
  position: absolute;
  z-index: 0;
  max-width: 20rem;
  bottom: -1rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 768px) {
  .trustpilot-title-img {
    width: 35%;
    max-width: 22.5rem;
    right: 0;
    left: auto;
    bottom: -2rem;
  }
}
.trustpilot-title-img:before {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70%;
  background: linear-gradient(to bottom, rgba(12, 39, 128, 0) 15%, #0c2780 60%);
  content: "";
}
@media (min-width: 768px) {
  .trustpilot-title-img:before {
    background: linear-gradient(220deg, rgba(12, 39, 128, 0) 50%, rgba(12, 39, 128, 0.3) 80%);
  }
}
.trustpilot-widget {
  width: auto;
  flex-direction: column;
}
@media (min-width: 768px) {
  .trustpilot-widget {
    flex-direction: row;
  }
}
.trustpilot-logo {
  padding: 0 1rem;
  align-items: flex-end;
}
@media (min-width: 768px) {
  .trustpilot-logo {
    align-items: center;
  }
}
.trustpilot .reviews .quality {
  font-size: 1.5rem;
}
.trustpilot .score {
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .trustpilot .score {
    font-size: 2.75rem;
  }
}

.seo-slider-wrapper:before, .seo-slider-wrapper:after {
  display: none;
}
.seo-slider .slick-slide {
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .seo-slider .slick-slide {
    padding: 3rem 1.5rem;
  }
}
.seo-slider .slick-dots li.slick-active button:before {
  background-color: #2e86fe;
}
.seo-slider .slide-wrapper {
  background-color: #0c2780;
  border-radius: 0;
  box-shadow: none;
}
.seo-slider .slide-wrapper:before {
  display: none;
}
.seo-slider .slide-inner-wrapper, .seo-slider .slide-image-placeholder .image-background {
  border-radius: 0;
}
.seo-slider .slide-image-placeholder {
  padding: 0;
}
.seo-slider .slide-content {
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .seo-slider .slide-content {
    text-align: left;
  }
}
.seo-slider .slide-content a:not(.button) {
  color: #ffffff;
}
.seo-slider .slide-content .title {
  color: #ffffff;
}
.seo-slider .slide-content .title strong {
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .seo-slider .slide-content .cta-wrapper {
    text-align: center;
  }
}
.seo-slider .slide-content .cta-wrapper .button {
  background: #ffffff;
  color: #2e86fe;
}
.seo-slider .slide-content .cta-wrapper .button:hover {
  background: #2e86fe;
  color: #ffffff;
}

.sponsorships-section .title-section {
  color: #2e86fe;
  font-weight: 700;
}
@media (min-width: 768px) {
  .sponsorships-section .title-section {
    font-size: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .sponsorships-section .title-section {
    font-size: 3rem;
  }
}
.sponsorships-section .title-section strong {
  display: inline;
  color: #2e86fe;
  font-weight: 700;
  background: none;
  -webkit-text-fill-color: #2e86fe;
}
@media (min-width: 768px) {
  .sponsorships-section .title-section strong {
    font-size: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .sponsorships-section .title-section strong {
    font-size: 3rem;
  }
}