﻿
//Hero Section
$hero-image-url:url("../Images/uae/hero-banner.webp");
$hero-image-url-old-browsers:url("../Images/uae/hero-banner.jpg");
$hero-blue-gradient: linear-gradient(#{"/*! rtl:ignore */"}to right, $dark-blue 0, scale-color($dark-blue, $alpha:-85%) 40%);
$hero-blue-gradient-small-device: linear-gradient(to bottom, transparent 0, scale-color($dark-blue, $alpha:-85%) 40%, $dark-blue 70%);
$hero-blue-gradient-content: linear-gradient(to bottom, transparent 0, $dark-blue 20%);

.main-banner { height:calc(var(--viewh, 100vh) - var(--rwh, 52px)); box-shadow:none;
	@include media-breakpoint-up(lg){ height:auto; max-height:none; }
	&.hero-banner {
		&:before { position:relative; background-image:$hero-blue-gradient-small-device, $hero-image-url; background-repeat:no-repeat; background-position:0 0; background-size:100% auto; animation: zoomOutHeroBg 5s linear;
			#{$no-webp} { background-image:$hero-blue-gradient-small-device, $hero-image-url-old-browsers; }
			@include media-breakpoint-up(md){ background-position:0 0; background-size:100% auto; }
			@include media-breakpoint-up(lg){ position:absolute; width:50%; background-image:$hero-blue-gradient, $hero-image-url; #{"/*! rtl:ignore */"}background-position:right 0 top 0; background-size:auto 100%;
				#{$no-webp} { background-image:$hero-blue-gradient, $hero-image-url; }
			}
			@include media-breakpoint-up(xxxl){ width:60rem; background-size:cover;}
		}
		.plus-bg { z-index:0;
			&-medium { display:none;
				@include media-breakpoint-up(md){ display:block; width:15.5rem; height:15.5rem; top:0; #{"/*! rtl:ignore */"}left:4%;  }
				@include media-breakpoint-up(lg){ width:17.5rem; height:17.5rem; top:-8%; #{"/*! rtl:ignore */"}left:49%; }
				&.gold-fill { opacity:.6;
					@include media-breakpoint-up(md){ z-index:2; top:35%; #{"/*! rtl:ignore */"}left:92%; }
					@include media-breakpoint-up(lg){ z-index:0; top:70%; }
				}
			}
			&-large { display:none; @include plusDimensions();
				@include media-breakpoint-up(xxl){ display:block; top:0; #{"/*! rtl:ignore */"}left:-2%; }
				&.gold-fill { opacity:.6;
					@include media-breakpoint-up(md){ display:block; width:15.5rem; height:15.5rem; #{"/*! rtl:ignore */"}left:0; }
					@include media-breakpoint-up(lg){ top:-5%; #{"/*! rtl:ignore */"}left:46%; }
					@include media-breakpoint-up(xl){ @include plusDimensions(); top:-18%; }
				}
			}
		}
		.hero-content { padding-top:$spacer * 4.125; animation:none; }
	}
	&-wrap {
		@include media-breakpoint-up(md){ min-height:auto; }
		@include media-breakpoint-up(lg){ min-height:48rem; flex-direction:row; justify-content:flex-start; align-items:center; }
		@include media-breakpoint-up(xxl){ min-height:54rem; }
	}
	&-content { text-align:center; background:$hero-blue-gradient-content;
		@include media-breakpoint-up(lg){ padding-left:$spacer * 2; padding-right:$spacer * 2; text-align:left; background:transparent; }
	}
	&-title { font-size:1.5rem; margin-bottom:$spacer; font-weight:$font-weight-regular;
		@include media-breakpoint-up(md){ font-size:3rem; margin-bottom:$spacer * 2; font-weight:$font-weight-lighter; line-height:1;
			#{$rtl-languages} { line-height:1.1; }
		}
		@include media-breakpoint-up(xl){ font-size:4rem; }
		strong { @include titleGradient; font-weight:$font-weight-medium; }
	}
	&-text { font-size:1rem;
		@include media-breakpoint-down(md){ strong { font-weight:$font-weight-regular; } }
		@include media-breakpoint-up(md){ font-size:1.125rem; padding-right:0; }
	}
	&-buttons {
		@include media-breakpoint-up(md){ max-width:100%; flex-wrap:nowrap;
			.button { margin-left:$spacer * .3125; }
		}
		@include media-breakpoint-up(lg){ justify-content:start; }
	}
	.bonus-box { justify-content:center;
		@include media-breakpoint-up(lg){ justify-content:inherit; }
		.link-bonus { @include media-breakpoint-up(md){ margin-inline:0; } }
	}
}
.hero-banner {
	&-animation { position:absolute; z-index:1; height:100%; width:100%; max-width:28.125rem; top:5rem; right:0; left:0; margin:auto; animation: opacityHeroAnimation 1s linear;
		background:url("../Images/uae/hero-en.png") no-repeat center top/100% auto;
		#{$rtl-languages} { background-image:url("../Images/uae/hero-ar.png"); }
		@include media-breakpoint-up(lg){ height:auto; top:0; bottom:0; margin-top:$spacer * -2.25; #{"/*! rtl:ignore */"}right:0; #{"/*! rtl:ignore */"}left:auto; background-position:center; }
		@include media-breakpoint-up(xxl){ max-width:37.5rem; #{"/*! rtl:ignore */"}right:calc(50% - 37.5rem - 8rem); }
		@include media-breakpoint-down(lg){ dotlottie-player { height:auto; } }
	}
}

@keyframes zoomOutHeroBg {
	0% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes opacityHeroAnimation {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

//Countries
.countries { position:relative; z-index:2; background-color:$dark-blue; font-size:1rem; padding:$spacer * .875; border-top:$border-top-section-gold;
	@include media-breakpoint-up(md){ font-size:1.5rem; padding:$spacer * 1.5; border-width: $spacer * .375 }
	&-title { @include titleGradient; padding:0 $spacer 0 0; margin-right:$spacer; font-weight:$font-weight-bolder; text-align: center; line-height:1; border-right:1px solid $gold-light;
		@include media-breakpoint-up(md){ padding:0 ($spacer * 2.5) 0 ($spacer * 1.5); margin-right:($spacer * 2); }
	}
	&-wrapper { position:relative;
		&:after { position:absolute; right:0; top:0; height:100%; width:5rem; background:linear-gradient(to right, scale-color($dark-blue, $alpha:-100%) 0, $dark-blue 80%); content:'';
			@include media-breakpoint-up(md){ width:10rem; }
		}
	}
	&-items { display:flex; gap:$spacer * 2.5; padding-left:$spacer * 2.5; color:$white;
		@include media-breakpoint-up(md){ gap:$spacer * 4.5; padding-left:$spacer * 4.5; }
	}
}

.marquee-wrapper { display:flex; width:100%; white-space:nowrap; overflow:hidden;}
.marquee-animation { animation: marquee 30s linear infinite;
	@media (prefers-reduced-motion: reduce) { animation: none; }
}
@keyframes marquee {
	0%   { transform: translateX(0); }
	100% { transform: translateX(-100%); }
}
