﻿$gold-yellow: #EEB966;
$gold-light: #EDB762;
$gold-dark: #946F00;
$grey:#F3F4F9;
$title-gradient: linear-gradient(90deg, $gold-light 35%, scale-color($gold-light, $lightness:40%) 55%, $gold-light 65%);
$cards-spacer: $spacer * .5;
$cards-spacer-border: $spacer * .625;
$card-group-margin: $spacer * 10.5;
$border-top-section-gold: solid ($spacer * .25) scale-color($gold-light, $lightness:-10%);


.general {
	&-margin { position:relative; margin-top:$spacer * 4; margin-bottom:$spacer * 4;
		@include media-breakpoint-up(md){ margin-top:$spacer * 8.25; margin-bottom:$spacer * 8.25; }
	}
}
.blue-section { background:$dark-blue; color:$white;
	a:not(.btn) { color:$white; }
	.title-section { color:$white; }
}

@mixin titleGradient() {
	background:$title-gradient;
	-webkit-background-clip:text;
	-webkit-text-fill-color:transparent;
}
@mixin boxBorderGradient( $color, $rotate, $color-gr) {
	background-image: linear-gradient($color, $color), linear-gradient($rotate, transparent, transparent, $color-gr, $color-gr);
}

@mixin plusDimensions($height:22rem, $width:22rem){
	height: $height; width: $width;
}

.card-border { position:relative; z-index:0; padding:($spacer * 2) ($spacer * .5); border-radius:$radius-double;
	&:before { position:absolute; z-index:0; top:$cards-spacer; right:$cards-spacer; bottom:$cards-spacer; left:$cards-spacer; border-radius:$radius-double; border:solid .125rem transparent; background-origin:border-box; background-clip:content-box, border-box; content:''; }
	@include media-breakpoint-up(md){ padding:$spacer * 2; }
	&.blue { color:$white; background-color:$dark-blue;
		&:before { @include boxBorderGradient($dark-blue, 0deg, $gold-yellow) }
		&.side-border {
			&:before { @include boxBorderGradient($dark-blue, 90deg, $gold-yellow) }
		}
	}
	&.white { background-color:$white;
		&:before { @include boxBorderGradient($white, 90deg, $gold-yellow) }
	}
	> * { position:relative; }
}

.title-section { font-size:2rem; line-height:1;
	#{$rtl-languages} { line-height:1.1; }
	.lang-en & { text-transform:uppercase; }
	@include media-breakpoint-up(md){ font-size:3rem; }
	@include media-breakpoint-up(xl){ font-size:4rem; }
	strong { @include titleGradient; font-weight:$font-weight-medium;
		@include media-breakpoint-up(md){ font-size:3rem; }
		@include media-breakpoint-up(xl){ font-size:4rem; }
	}
}
.plus-bg {
	&.gold:before {background-image:url("../images/pluses/plus-gold.svg"); }
	&.gold-fill:before {background-image:url("../images/pluses/plus-gold-fill.svg"); }
}
