/// HOMEPAGE ///
@import "functions";
@import "variables";
@import "custom.scss";
@import "icons/icons";
@import "base";
@import "base-uae";
@import "header";
@import "header-uae";
@import "components/hero";
@import "components/hero-uae";
@import "home-sections/seo-carousel";
@import "../shared/sponsorship";
@import "components/feeds-widget";
@import "../shared/trustpilot";
@import "components/why-us";
@import "components/pay-method";
@import "components/footer";
@import "components/cookie-banner-sticky";
@import "components/card";
@import "components/awards";

//Regulation Logo
.regulation { background-color: $grey; margin-bottom: $spacer * 2.5;
  @include media-breakpoint-down(md){ padding: 0 4%; }
  @include media-breakpoint-up(md){ margin-bottom: $spacer * 6; }
   &-brands { display:flex; padding-top:$spacer * 1.5; padding-bottom:$spacer * 1.5; color:$text-color; font-size:.75rem; text-align:center; gap:1.5rem;
    @include media-breakpoint-up(md){ padding-top:$spacer * 2.5; gap:2.5rem; }
    @include media-breakpoint-up(lg){ gap:4rem; }
   } 
   &-brand { position:relative; display:flex; flex-direction:column; gap:.5rem; 
    @include media-breakpoint-up(md){ gap:1rem; }
    &-img { margin:auto 0; }
    &-title { align-items: center; min-height:2rem; }
    &-content { position:absolute; left:0; right:0; margin:auto; max-width:65%; padding-top:$spacer * .5; font-weight:$font-weight-bold; 
        @include media-breakpoint-up(md){ font-size:1.125rem; }
    } 
   }
}

//Trust Numbers
.trade-trust {position:relative; font-weight: $font-weight-light; font-size: 2rem; color:$gold-dark;
  @include media-breakpoint-up(md){ font-size: 4rem; }
  .plus-bg { display:none; z-index:0;
      &-medium {  
          @include media-breakpoint-up(xxxl){ display:block; z-index:-1; @include plusDimensions(); top:0; left:-6%; }
      }
      &-large { 
          @include media-breakpoint-up(lg){ display:block; @include plusDimensions(); top:60%; right:-8%; }
      }
  }
  &-section { text-align:center; }
  &-item { height:100%; padding:($spacer * 1.5) ($spacer * .5); background-color:$white; border:solid ($spacer * .0625) scale-color($gold-dark, $alpha:-5%); border-radius:$radius;
    @include media-breakpoint-up(md){ padding:($spacer * 2) $spacer; }
    strong { display:block; color:$dark-blue; font-size:1rem; margin-top:$spacer * .5; line-height:1; 
      @include media-breakpoint-up(md){ font-size:1.5rem; }
    }
  }
  &-title { font-size:1rem; color:$dark-blue; margin-top:$spacer * 2; padding:($spacer * 1.5) 10%; font-weight:$font-weight-regular; background-color:$grey; border-radius:$radius; 
    @include media-breakpoint-up(md){ margin-top:$spacer * 3.5; padding-top:$spacer * 2.5; padding-bottom:$spacer * 2.5; font-size:2rem; }
    @include media-breakpoint-up(xxl){ padding-left:$spacer * 2.5; padding-right:$spacer * 2.5; }
  }
  &-foot-note { font-size:1rem; color:$text-color; }
}

//Confidence Cards Section
$cards-bg-url:url("../images/uae/cards-bg-gr.webp");
$cards-bg-url-old-browsers:url("../images/uae/cards-bg-gr.jpg");

.confidence { position:relative;
    .plus-bg { display:none; z-index:0;
          &-large {  
              @include media-breakpoint-up(lg){ display:block; @include plusDimensions(); top:80%; left:-6%; }
          }
    }
}
.cards-section { margin-bottom:$card-group-margin;
   .gs-card-anim { opacity:0; }
  .cards {
    &-bg-image { 
      @include media-breakpoint-up(md){
        position: absolute; z-index:0; top:$cards-spacer-border; right:$cards-spacer-border; bottom:$cards-spacer-border; left:$cards-spacer-border; border-radius:$radius-double; 
      background-image:#{$cards-bg-url}; background-repeat: no-repeat; background-size: 40% auto; #{"/*! rtl:ignore */"}background-position: right top;
      #{$no-webp} { background-image:#{$cards-bg-url-old-browsers}; }
      }
    }
    &-data { position:relative; }
    &-header { margin-top:$spacer * 1; margin-bottom:$spacer * 2; 
      @include media-breakpoint-up(md){ margin-top:$spacer * 3; margin-bottom:$spacer * 4; }
    }
    &-body {  
      @include media-breakpoint-up(xl){ max-width:90%; margin:0 auto; }
    }
    &-list { margin-bottom:-$card-group-margin; }
   }
   .card {
        &-icon { max-height:2.8125rem; max-width:2.8125rem; display:block; margin:0 auto $spacer * 1.5; 
            @include media-breakpoint-up(md){ max-height:3.75rem; max-width:3.75rem; margin-bottom:$spacer * 2; }
        }
        &-title { margin-bottom:$spacer;  
            @include media-breakpoint-up(lg){ min-height:3.4rem; }
        }
        &-text { margin-bottom:$spacer * 2; }
   }
}

//Payments
.payment-methods { position:relative;
    .plus-bg { display:none; z-index:0;
        &-medium { 
              @include media-breakpoint-down(md){ display:block; width:10rem; height:10rem; top:1rem; left:-8%; }
        }
          &-large { 
              @include media-breakpoint-up(md){ display:block; @include plusDimensions(); top:50%; right:-6%; }
          }
    }
    &-title { color:$dark-blue; }
    &-content { display:flex; flex-direction:column; align-items:center; text-align:center;  
        .images{ margin-bottom: $spacer; display:flex;
            img { margin:0 $spacer * .5; max-width:4.375rem; height:auto; }
        }
        .title { @include titleGradient; font-size:1.125rem; font-weight:$font-weight-bold; 
            @include media-breakpoint-down(md){ padding:0 $spacer; }
        }
    }
}
.payment-brands {
    @include media-breakpoint-down(md){ flex-direction:column-reverse; align-items:center; }  
    @include media-breakpoint-up(md){ margin-top: $spacer * 1.5; }
}
.payment-brand { margin: 0 1%;
    &-list { flex: 0 0 100%;
        @include media-breakpoint-up(xxl){ flex: 0 0 80%; } 
        @include media-breakpoint-down(md){ padding:0 $spacer; }
    }
    &-img {
        img { height:2.5rem; 
            @include media-breakpoint-up(xxl){ height:2.75rem; }
        }
    }
}

//Features Section
.features {
    &-card-border { position:absolute; top:8rem; bottom:0; left:0; right:0; z-index:0;
        @include media-breakpoint-up(lg){ bottom:8rem;
            &.blue:before { @include boxBorderGradient($dark-blue, -90deg, $gold-yellow) }
        }
    }
    &-slider {
        &.slick-initialized { width:100%; opacity:1; max-height:none; 
                & + .features-slider-navigation { opacity:1; visibility:visible; }
                @include media-breakpoint-down(md){ height:100%;
                    &.slick-slider, .slick-list, .slick-track { height:100%; }
                 }
            }
        &-navigation { width:100%; position:absolute; bottom:2rem; left:0; right:0; margin:0 auto; height:2.25rem; visibility:hidden; opacity:0; transition:opacity .2s ease-out;
            @include media-breakpoint-up(lg){ width:12.5rem; bottom:10rem; left:14%; right:auto;  }
                button { background:none; border:none; }
                .slick-arrow { display:flex; align-items:center; color:$white;
                    &.slick-prev, &.slick-next { width:2rem; top:-18rem; 
                        @include media-breakpoint-up(lg){ top:50%; }
                        &:before { font-size:1rem; color:$white; width:2rem; }
                    }
                    &.slick-prev { left:1rem; 
                        @include media-breakpoint-up(lg){ left:0; }
                    }
                    &.slick-next { right:1rem; 
                        @include media-breakpoint-up(lg){ right:0; }
                    }
                }
                .slick-dots { display:flex; justify-content:center; align-items:center; height:2.25rem; width:auto; left:2rem; right:2rem;
                    li { height:auto;
                        button { background:transparent; border:0 none; border-radius:50%; padding:0; width:1.25rem; height:1.25rem;
                            &:before { border-radius:50%; opacity:1; z-index:1; background-color:$white; width:.75rem; height:.75rem; margin:$spacer * .25; }
                            svg { opacity:0; width:100%; height:100%; fill:none; stroke:$gold-yellow; stroke-width:.25rem; stroke-dasharray:0 100; transform:rotate(-90deg); }
                        }
                        &.slick-active {
                            button { 
                                &:before { background-color:$gold-yellow; }
                                svg { opacity:1; animation-name:slick-dots-anim; animation-iteration-count:infinite; animation-fill-mode:forwards; animation-timing-function:linear; }
                            }
                        }
                    }
                }
            }
        &-container{ position:relative; 
            .plus-bg { display:none; z-index:0;
              &-large { 
                  @include media-breakpoint-up(xxl){ display:block; @include plusDimensions(); top:40%; left:-14%; }
              }
            }
        }
    }
    &-slide { display:none;
        &-data { display:flex; flex-direction:column; justify-content:space-between; align-items:center; 
            @include media-breakpoint-up(lg){ min-height:50rem; flex-direction:row-reverse; }
        }
        &-animation{ position:relative; z-index:0; margin:0 auto; width:85%; 
            @include media-breakpoint-down(lg){ aspect-ratio: 10 / 14;
                &:before { position:absolute; z-index:1; left:0; right:0; bottom:0; height:70%; background:$banner-image-gradient-small-device; content:''; }
             }
            @include media-breakpoint-up(md){ width:50%; }
            @include media-breakpoint-up(lg){ margin-right:0; }
        }
        &-content { flex:0 0 auto; position:relative; z-index:1; margin-top:$spacer * -15; padding:($spacer * 8) ($spacer * 2.5) ($spacer * 4); color:$white;
            @include media-breakpoint-up(md){ padding-left:10%; padding-right:10%; }
            @include media-breakpoint-up(lg){ flex:0 0 45%; margin-top:0; padding:($spacer * 10) ($spacer * 2) ($spacer * 14) ($spacer * 4); }
            .slide-icon { display:flex; justify-content:center; height:3rem;
                @include media-breakpoint-up(md){ height:4.375rem; }
                img { margin:0 auto; max-width: 4.375rem; max-height: 4.375rem; }
            }
            .slide-title { @include titleGradient; margin:($spacer * 1.5) auto; font-weight:$font-weight-medium; }
        }
        
    }
}

// Global CSS
@keyframes slick-dots-anim {
	0% { stroke-dasharray:0 100; }
  100% { stroke-dasharray:100 100; } 
}

//Globar Market
.global-markets { margin-top:$spacer * 4; position:relative; border-top:$border-top-section-gold;
     @include media-breakpoint-down(lg){ padding-top:$spacer * 4; }
     @include media-breakpoint-down(md){ 
        &.blue-section { background:linear-gradient(-180deg, $dark-blue 0, $dark-blue 60%, $white 100%) }
    }
    &.lazyloaded {
        &:before, &:after { position:absolute; top:0; left:0; width:100%; height:100%; background-repeat:no-repeat; content:'';  
            #{$rtl-languages} { transform: scaleX(-1); }
        }
        @include media-breakpoint-up(md){
            &:before { background-image:url("../images/uae/global-markets-bg.webp"); background-size:auto 100%; #{"/*! rtl:ignore */"}background-position:left 0; 
                @include media-breakpoint-down(lg){ #{"/*! rtl:ignore */"}background-position:left -20vw top 0; }
                @include media-breakpoint-down(xxxl){ opacity:.4; }
            }
        }
        &:after { 
            @include media-breakpoint-up(xxxl){
                background-image:url("../images/uae/global-markets-person.webp"); background-size:auto 84%; #{"/*! rtl:ignore */"}background-position:left -20rem bottom;
            }
        }
    }
        .plus-bg { display:none;
           &-medium { 
              @include media-breakpoint-up(md){ display:block; width:14.375rem; height:14.375rem; top:100%; left:2%; }
          }
          &-large { 
              @include media-breakpoint-up(md){ display:block; @include plusDimensions(); top:90%; left:-4%; opacity:.3; }
          }
        }
    &-image { position:absolute; bottom:0; left:0; margin:0;  
        #{$rtl-languages} { transform: scaleX(-1); }
    }
    &-content { position:relative; z-index:1; padding-bottom:$spacer * 2; 
        @include media-breakpoint-up(lg){ padding-right:$spacer * 2; }
        @include media-breakpoint-between (md, lg){
            padding-bottom:$spacer * 6; 
        }
    }
    &-widget { position:relative; z-index:1;
        @include media-breakpoint-up(md){
            &:before, &:after{ position:absolute; z-index:0; content:""; top:-2.5rem; bottom:-2.5rem; left:30%; right:-2.5rem; }
            &:before { border-radius:$border-radius-medium; border:2px solid $gold-light;  }
            &:after { right:-3.125rem; border-image: radial-gradient(ellipse closest-side at 90%, $white 0, $gold-light 30%, transparent 100%) 0 20% 0 0 / 0 5% 60% 0;
                #{$rtl-languages} { transform: scaleX(-1); }
           }
        }
     }
    .feed-table {
        .instruments-widget-items { min-height:32rem; }
        @include media-breakpoint-up(lg){ max-width:34rem; 
            table tr td { padding-top:($spacer * .5); padding-bottom:($spacer * .5); }
        }
        @include media-breakpoint-between (md, xl){
            table tr th { font-size:1rem; }
            table tr td, table tr td .button { font-size:.9375rem; }
        }
    }
}

//Card
.card { 
  --bs-card-cap-padding-y: #{$spacer  * 2};
  --bs-card-cap-padding-x: #{$spacer  * 2};
  --bs-card-spacer-y: #{$spacer * 2};
  --bs-card-spacer-x: #{$spacer * 2};
  &-item { margin-bottom:$spacer * 1.5; }
  &-title { font-weight:$font-weight-bold; 
    @include media-breakpoint-up(md){ @include multiLineElippsis(3); }
  }
  &-text { @include media-breakpoint-up(md){ @include multiLineElippsis(7); } }
  &-footer { padding-top:0; }
  .btn { margin-top:0; }
}

//Trustpilot UAE only
$trustpilot-image-gradient: linear-gradient(220deg, scale-color($dark-blue, $alpha:-100%) 50%, scale-color($dark-blue, $alpha:-70%) 80%);
$trustpilot-image-gradient-small-device : linear-gradient(to bottom, scale-color($dark-blue, $alpha:-100%) 15%, $dark-blue 60%);
.trustpilot {
    .plus-bg { display:none; z-index:-1;
      &-large { @include plusDimensions();
          @include media-breakpoint-up(md){ display:block; top:-20%; left:auto; right:-8%; }
          @include media-breakpoint-up(xl){ top:-40%; }
      }
    }
    &-section { margin-bottom:$spacer * 2.5; }
    &-title {
        &-card { margin-top:$spacer * 12; margin-bottom:$spacer * 4;
            @include media-breakpoint-between(md, lg){ margin-top:$spacer * 6; }
        }
        &-data { display:flex; align-items:center; justify-content:space-between;
            .title-section {position:relative; z-index:1; padding:($spacer *2) $spacer; margin: 0 auto; text-align:center;  
                @include media-breakpoint-up(md){ width:65%; margin-left:0; text-align:left; }
            }
        }
        &-img { position:absolute; z-index:0; max-width:20rem; bottom:-1rem; right:0; left:0; margin:0 auto; overflow:hidden;
            @include media-breakpoint-up(md){ width:35%; max-width:22.5rem; right:0; left:auto; bottom:-2rem; }
            &:before { position:absolute; z-index:1; left:0; right:0; bottom:0; height:70%; background:$trustpilot-image-gradient-small-device; content:''; 
                @include media-breakpoint-up(md){ background:$trustpilot-image-gradient; }
            }
        }
    }

    &-widget { width:auto; flex-direction:column;
        @include media-breakpoint-up(md){ flex-direction:row; }
    }
    &-logo { padding:0 $spacer; align-items:flex-end; 
        @include media-breakpoint-up(md){ align-items:center; }
    }
    .reviews .quality { font-size:1.5rem; }
    .score { font-weight:$font-weight-bold; 
        @include media-breakpoint-down(md){ font-size:2.75rem; }
    }
}


//Seo slider
.seo-slider {
    &-wrapper {
        &:before, &:after { display:none; }
    }
    .slick {
        &-slide { padding-bottom:$spacer * 4;
            @include media-breakpoint-up(lg){ padding:($spacer * 3) ($spacer * 1.5); }
         }
         &-dots {
            li { &.slick-active button:before { background-color:$medium-blue; } }
         }
    }
    .slide {
        &-wrapper { background-color:$dark-blue; border-radius:0; box-shadow:none; 
            &:before { display:none; }
        }
        &-inner-wrapper,
        &-image-placeholder .image-background { border-radius:0; }
        &-image-placeholder { padding:0; }
        &-content { color:$white; 
            @include media-breakpoint-down(md){ text-align:left; } 
            a:not(.button) { color:$white; } 
            .title { color:$white; 
                strong { color:$white; }
            }
            .cta-wrapper { 
                 @include media-breakpoint-down(md){ text-align:center; } 
                .button { background:$white; color:$medium-blue; 
                    &:hover { background:$medium-blue; color:$white; }
                }
            }
        }
    }
}

//Sponsors
.sponsorships-section { 
    .title-section { color:$medium-blue; font-weight:$font-weight-bolder;
        @include media-breakpoint-up(md){ font-size:2.5rem; }
        @include media-breakpoint-up(xl){ font-size:3rem; }
        strong { display:inline; color:$medium-blue; font-weight:$font-weight-bolder; background:none; -webkit-text-fill-color:$medium-blue; 
            @include media-breakpoint-up(md){ font-size:2.5rem; }
            @include media-breakpoint-up(xl){ font-size:3rem; }
        }
    }
}