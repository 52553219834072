.awards-section { margin-bottom:$spacer * 4;
	.card { --bs-card-bg:none; --bs-border-radius:0; --bs-card-box-shadow:none; --bs-card-color:#{$dark-blue};
		&-body { --bs-card-spacer-x:0; }
		strong, span { font-weight:$font-weight-bolder; }
		&-title { display:flex; flex-direction:column; justify-content:center; padding:($spacer * 2) ($spacer * 5); font-size:1.25rem; font-size:clamp(1rem, 2vw, 1.25rem); position:relative; font-weight:$font-weight-regular; 
			@include media-breakpoint-up(xxl) { min-height:12rem; }
			&:before { background-image:url("../Images/uae/spikelet-left.svg"), url("../Images/uae/spikelet-right.svg"); background-repeat:no-repeat; #{"/*! rtl:ignore */"}background-position:calc(50% - 7.5rem) 50%, calc(50% + 7.5rem) 50%; background-size:auto 8.125rem; position:absolute; top:0; bottom:0; left:0; right:0; 
				margin:auto; display:block; content:''; 
			}
			strong { font-size:2.75rem; font-size:clamp(2.5rem, 2vw, 2.75rem); line-height:2.75rem; line-height:clamp(2.5rem, 2vw, 2.75rem); }
		}
		&-text { max-width:21.25rem; margin:($spacer * .625) auto 0; padding-left:$spacer; padding-right:$spacer; font-size:1.125rem;
			strong { display:block; margin-bottom:$spacer * .25; font-size:1.5rem; text-transform:uppercase; }
		}
	}
}