﻿.card { --bs-card-border-color:transparent; --bs-border-radius:calc(#{$border-radius-large} / 2); --bs-card-title-color:#{$dark-blue}; --bs-card-spacer-y:#{$spacer}; --bs-card-border-width:0;
--bs-card-cap-bg:transparent; --bs-card-cap-color:#{$dark-blue}; --bs-link-color-rgb:#{$dark-blue}; --bs-card-cap-padding-y:#{$spacer}; --bs-card-box-shadow:#{$card-small-shadow};
	&-item { margin-bottom:$spacer * 2;
		&-inner { margin-bottom:$spacer * 2; }
	}
    &-img { position:relative; margin:0; max-height:20rem; overflow:hidden; border-bottom-left-radius:0; border-bottom-right-radius:0;
    	&-bg {
    		&:after { position:absolute; z-index:1; left:0; bottom:0; background-image:linear-gradient(to bottom, scale-color($dark-blue, $alpha:-100%) 40%, $dark-blue); width:100%; height:100%; content:''; }
    		.meta { position:absolute; z-index:2; top:auto; left:$spacer; right:$spacer; bottom:$spacer * .5; height:auto; width:auto; color:$white; font-weight:$font-weight-bold;
				@include media-breakpoint-up(md){ font-size:1.25rem; }
				.date { font-weight: $font-light; font-size: .875rem; }
        	}
        	&-color { background-color:$dark-blue; 
        		img { object-fit: contain; }
        	}
    	}
    	img { object-fit:cover; }
    }
    &-title { font-weight:$font-weight-regular;
        @include media-breakpoint-up(md){ @include multiLineElippsis(2); font-size:1.5rem; } 
     }
 	&-text { @include media-breakpoint-up(md){ @include multiLineElippsis(4); } }
    &-link {
        &-text { display:inline-flex; color:$dark-blue; font-size:1.125rem;
            .icon { margin-left:$spacer *.5; 
                #{$rtl-languages} { transform:scaleX(-1); }
            }
        }
       &:hover, &:focus { .card-link-text span { text-decoration:underline; } }
    }
    &-video {
    	&:hover, &:focus {
    		.card-video-img {
    			&:after { background-color:$light-blue; outline:.625rem solid $light-blue;}
    			img { opacity:.9; }
    		}
		}
    	&-img {
            &:after { position:absolute; content:$icon-play3; font-family:$icomoon-font-family; top:50%; left:0; right:0; z-index:2; background-color:$medium-blue; color:$white; font-size:3.25rem; 
                display:flex; justify-content:center; align-items:center; width:3.5rem; height:3.5rem; margin:($spacer * -1.75) auto 0; border-radius:50%; outline:.625rem solid $medium-blue; outline-offset:-.625rem; transition:transform .3s ease-in-out;
                @include media-breakpoint-up(md){ font-size:3.75rem; width:4rem; height:4rem; margin-top:$spacer * -2; }
            }
         }
    }
}