﻿/// <reference path="../../../../node_modules/bootstrap/scss/bootstrap.scss" />
/// <reference path="../_variables.scss" />

$rw-background-color: #07183d;
$rw-background-color-secondary: $rw-background-color;
%prominant-colors { background-color:$rw-background-color-secondary; }
header { backdrop-filter:blur(.625rem);
	nav { background-color:scale-color($black, $alpha:-70%); border-radius:0 0 ($spacer * .75) ($spacer * .75);
		&.navbar { position:absolute; width:100%; }
		.islamic-account & { background-image:none; }
	}
	body:not(.is-sticky) & {
		nav {
			.logo {
				&-color-1, &-color-2 { fill:$white; }
			}
		}
		.offcanvas {
			.navbar-nav {
				@include media-breakpoint-up(lg) { --bs-nav-link-color:#{$white}; }
			}
		}
		.dropdown-toggle-search {
			.btn-link { background-image:$search-toggle-icon-white; }
		}
		.navbar-toggler span { background-color:$white; }
	}
}